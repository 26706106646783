var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "vuetify-wrapper overflow-visible inquired-appointments-per-day",
    },
    [
      _c("vc-calendar", {
        attrs: {
          locale: "de",
          rows: 1,
          columns: _vm.columns,
          step: 1,
          "from-page": _vm.calendarFromPage,
          attributes: _vm.calendarAttributes,
          "disable-page-swipe": "",
        },
        on: {
          "update:fromPage": function ($event) {
            _vm.calendarFromPage = $event
          },
          "update:from-page": function ($event) {
            _vm.calendarFromPage = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header-title",
            fn: function ({ monthLabel, yearLabel, year, month }) {
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(monthLabel) +
                    " " +
                    _vm._s(yearLabel) +
                    " (" +
                    _vm._s(_vm.totalInquiredAppointments(year, month)) +
                    ")\n        "
                ),
              ]
            },
          },
          {
            key: "day-content",
            fn: function ({ day, attributes }) {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-col h-full z-10 overflow-hidden" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-grow overflow-y-auto overflow-x-auto",
                      },
                      [
                        _c("p", { staticClass: "day-day" }, [
                          _vm._v(_vm._s(day.day)),
                        ]),
                        _vm._v(" "),
                        _vm._l(attributes, function (attr) {
                          return _c(
                            "p",
                            {
                              key: attr.key,
                              staticClass: "day-label",
                              style: { background: attr.customData.background },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(attr.customData.title) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        !attributes?.length
                          ? _c("p", { staticClass: "day-label" }, [
                              _vm._v(
                                "\n                        0\n                    "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }