<template>
    <div class="appointment-filter-container">
        <h2 style="text-align: left">Termine</h2>
        <v-menu
            left
            bottom
            transition="slide-y-transition"
            v-model="filterMenu"
            :close-on-content-click="false"
            min-width="445px"
            max-width="65%"
        >
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" class="toolbar-title">
                    <v-icon>mdi-filter-cog-outline</v-icon>
                </v-btn>
            </template>
            <v-card class="filter-menu">
                <v-row style="align-items: center">
                    <v-col cols="8">
                        <h4>Sortieren</h4>
                    </v-col>
                    <v-col cols="4" class="text-right">
                        <v-btn
                            @click="$emit('reset')"
                            :disabled="isResetDisabled"
                            rounded
                        >
                            <v-icon left size="20" class="mr-1">mdi-cancel</v-icon>
                            Filter zurücksetzen
                        </v-btn>
                    </v-col>
                    <SelectBox
                        @select="(value) => $emit('update:sortBy', value)"
                        :value="sortBy"
                        :options="sortOptions"
                        placeholder="Sortieren nach"
                        className="pt-0 mt-0"
                    />
                    <v-col cols="1" class="v-application">
                        <v-btn
                            @click="$emit('update:sortAsc', sortAsc * -1)"
                            :disabled="!sortBy"
                            fab
                            text
                            max-width="37"
                            max-height="37"
                            elevation="3"
                        >
                            <v-icon v-if="sortAsc == 1">mdi-arrow-up</v-icon>
                            <v-icon v-else>mdi-arrow-down</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h4>Terminfilter</h4>
                    </v-col>
                    <SelectBox
                        @select="(value) => onChange('service', value)"
                        :value="value.service"
                        :options="servicesTypes"
                        placeholder="Apotheker / PTA / PKA"
                        className="service-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('commited', value)"
                        :value="value.commited"
                        :options="committedStates"
                        placeholder="zugesagte / nicht-zugesagte"
                        className="committed-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('hidden', value)"
                        :value="value.hidden"
                        :options="isVisibleOptions"
                        placeholder="sichtbar / nicht-sichtbar"
                        className="visible-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('proposed_pharmacists', value)"
                        :value="value.proposed_pharmacists"
                        :options="isProposedOptions"
                        placeholder="wurde vorgeschlagen / nicht vorgeschlagen"
                        className="proposal-filter"
                    />
                </v-row>
                <v-row>
                    <SelectBox
                        @select="(value) => onChange('no_approval_required', value)"
                        :value="value.no_approval_required"
                        :options="isDirectPharmacyOptions"
                        placeholder="direkt Apotheke / keine direkt Apotheke"
                        className="direct-pharmacy-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('nightshift', value)"
                        :value="value.nightshift"
                        :options="isNightShiftOptions"
                        placeholder="Nachtdienst / keine Nachtdienst"
                        className="nightshift-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('hba', value)"
                        :value="value.hba"
                        :options="isHBAOptions"
                        placeholder="HBA / kein HBA"
                        className="hba-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('erp', value)"
                        :options="wwsOptions"
                        :value="value.erp"
                        placeholder="WWS"
                        className="erp-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('pharmacist_requests', value)"
                        :options="isRequestedOptions"
                        :value="value.pharmacist_requests"
                        placeholder="von FB angefragt / nicht-angefragt"
                        className="pharmacist-requests-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('can_self_assign', value)"
                        :options="isSelfAssignableOptions"
                        :value="value.can_self_assign"
                        placeholder="Selbstzuweisung an / Selbstzuweisung aus"
                        className="self-assign-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('admin_id', value)"
                        :options="adminUsersOptions"
                        :value="value.admin_id"
                        placeholder="Admin zuweisen"
                        className="self-assign-filter"
                    />
                    <SelectBox
                        @select="(value) => onChange('allow_direct_proposal', value)"
                        :options="directProposalOptions"
                        :value="value.allow_direct_proposal"
                        placeholder="Direkter Vorschlag an / aus"
                        className="direct-proposal-filter"
                    />
                </v-row>
                <v-row class="v-application--is-ltr">
                    <v-col cols="4">
                        <v-checkbox
                            @change="(value) => onChange('internal_comment', value)"
                            :value.sync="value.internal_comment"
                            class="internalcomment-filter"
                            label="mit internem Kommentar"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            @change="(value) => onChange('comment', value)"
                            :value.sync="value.comment"
                            class="fpp-comment-filter"
                            label="mit Kommentar für FPP"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            @change="(value) => onChange('comment_to_fb', value)"
                            :value.sync="value.comment_to_fb"
                            class="freelancer-comment-filter"
                            label="mit Kommentar für FB"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row class="v-application v-application--is-ltr vuetify-wrapper">
                    <v-col cols="12">
                        <h4>Entfernungssuche (entweder über den FB oder über die PLZ)</h4>
                    </v-col>
                    <v-col cols="4" class="compact">
                        <PharmacistSearchDropdown
                            v-model="selectedPharmacist"
                            placeholder="Suche Freiberufler"
                        />
                    </v-col>
                    <v-col cols="4" class="compact">
                        <v-select
                            class="distance-filter"
                            @input="value => onChange('radius', value)"
                            :value.sync="value.radius"
                            :items="distance"
                            placeholder="Distanz"
                            dense
                            outlined
                            clearable
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            @input="onZipUpdate"
                            :value.sync="value.zip"
                            placeholder="PLZ"
                            type="number"
                            dense
                            outlined
                            clearable
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import PharmacistSearchDropdown from '../pharmacist/PharmacistSearchDropdown.vue'
import SelectBox from "./SelectBox.vue";
import { filters } from "../../constants";

export default {
    props: ['value', 'sortBy', 'sortAsc'],
    components: { PharmacistSearchDropdown, SelectBox },
    data: () => ({
        filterMenu: false,
        expandedPanelIndex: -1,
        searchPharmacistResults: [],
        servicesTypes: filters.servicesTypesOptions,
        committedStates: filters.committedStatesOptions,
        distance: filters.distanceOptions,
        sortOptions: filters.sortOptions,
        wwsOptions: filters.wwsOptions,
        isVisibleOptions: filters.isVisibleOptions,
        isProposedOptions: filters.isProposedOptions,
        isDirectPharmacyOptions: filters.isDirectPharmacyOptions,
        isNightShiftOptions: filters.isNightShiftOptions,
        isHBAOptions: filters.isHBAOptions,
        isSelfAssignableOptions: filters.isSelfAssignableOptions,
        isRequestedOptions: filters.isRequestedOptions,
        directProposalOptions: filters.directProposalOptions,
        pharmacist: null
    }),
    methods: {
        onChange(key, value) {
            this.$emit('input', {
                ...this.value, [key]: value
            })
        },
        onZipUpdate: _.debounce(function(value) {
            this.onChange('zip', value || null)
        }, 300)
    },
    computed: {
        isResetDisabled() {
            return !Object.values(this.value).some(filter => filter)
        },
        selectedPharmacist: {
            get() {
                return this.value.pharmacist_id ? this.pharmacist : null
            },
            set(pharmacist) {
                this.pharmacist = pharmacist;
                this.onChange('pharmacist_id', pharmacist?._id);
            }
        },
        ...mapGetters(['adminUsersOptions']),
    },
}
</script>

<style>
.appointment-filter-container {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
}
.btn-container {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
.filter-menu {
    padding: 1rem;
    background: #fff;
}
.compact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
