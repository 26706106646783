import { store } from './vue/store';


export class GenericAppointmentFormHelpers {
    static getDay(dateString) {
        const date_items = dateString.split('.');
        const d = parseInt(date_items[0]);
        const m = parseInt(date_items[1]);
        const y = parseInt(date_items[2]);
        const date = new Date(y, m - 1, d);
        let day = date.getDay();
        return day === 0 ? 7 : day;
    }

    static getSnippets(form, day) {
        const snippets = form.data('snippets');
        return {
            // if no hours are configured for `day` then default to 0
            // to avoid null-ptr exceptions
            hours: snippets['hours' + day] ?? 0,
            snippet: snippets['snippet' + day],
            begin: snippets['begin' + day],
            end: snippets['end' + day],
        }
    }

    static getDefaultValues(form) {
        return {
            flatAmountFb: form.data('default-flat-amount-fb'),
            flatAmountApo: form.data('default-flat-amount-apo'),
        };
    }
}

export class AppointmentFormHelpers {
    static getDay() {
        return GenericAppointmentFormHelpers.getDay($('.appointment-form #date').val());
    }

    static getSnippets(day) {
        return GenericAppointmentFormHelpers.getSnippets($('.appointment-form'), day);
    }

    static getDefaultValues() {
        return GenericAppointmentFormHelpers.getDefaultValues($('.appointment-form'));
    }

    static setQuantity(quantity) {
        const fieldQuantityInput = $('.appointment-form #quantity');
        fieldQuantityInput.val(quantity.toString().replace('.', ','));
    }

    static setFlatAmountFb(quantity) {
        const fieldFlatAmountInput = $('.appointment-form #flat_amount');
        fieldFlatAmountInput.val(quantity.toString().replace('.', ','));
    }
}

export default class {
    static format_currency(num) {
        return num.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
    }

    static check_appointment_date(url, data, cb) {
        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: (data) => {
                cb(data);
            },
        });
    }

    static collect_marked_appointments() {
        var appointments = [];
        $('div .active input[name=markinvoice]').each(function() {
            if ($(this).is(':checked')) {
                var row = $(this).parents('tr');
                if ($(row).is(':visible')) {
                    appointments.push($(row).data('id'));
                }
            }
        });
        store.commit('setSelectedRowIds', appointments);
        return appointments;
    }

    static collect_marked_appointments_alt() {
        var appointments = [];
        $('div .active input[name=markgroup]').each(function() {
            if ($(this).is(':checked')) {
                var row = $(this).parents('tr');
                if ($(row).is(':visible')) {
                    appointments.push($(row).data('id'));
                }
            }
        });
        store.commit('setSelectedRowIds', appointments);
        return appointments;
    }

    static toggle_quantity_field(applyDefaultValues=false) {
        const fieldFlatAmountDiv = $('#field-flat_amount');
        const fieldQuantityDiv = $('#field-quantity');
        fieldFlatAmountDiv.hide();
        if ($('select#billing').val() == 'flatrate') {
            fieldQuantityDiv.hide();
        } else {
            fieldQuantityDiv.show();
            if (applyDefaultValues) {
                const day = AppointmentFormHelpers.getDay();
                const { hours } = AppointmentFormHelpers.getSnippets(day);
                AppointmentFormHelpers.setQuantity(hours);
            }
        }
    }

    static submit_appointment_form(form) {
        var commited = $(form)
            .find('input#commited')
            .is(':checked');
        if (
            !commited ||
            (commited &&
                confirm(
                    'Achtung: Ist die Zusage schon an den Kunden verschickt worden?  Wollen Sie wirklich speichern?'
                ))
        ) {
            $(form)
                .unbind('submit')
                .submit();
        }
    }

    static enable_infopopup() {
        $('.infopopup').popover({
            container: 'body',
            placement: 'left',
            html: true,
            trigger: 'hover',
            delay: { show: 300, hide: 50 },
            // content: function() {
            //     return $('#comment-popover-' + $(this).data('aid')).html();
            // },
        });
    }

    static enable_general_popover() {
        $('[data-toggle="popover"]').popover();
    }

    static enable_general_tooltip() {
        $('.infotooltip').tooltip();
    }

    static enable_user_search() {
        /* enable user search typeahead */
        const users = $('.tabbable').data('users');
        $('.usersearch').usersearch({ users: users });
    }

    static apply_appointment_filter_buttons() {
        /* This method filters the appointments based on the buttons that contain the glyphicon-ok icon inside */
        let filterSettings = [
            { 'name': 'service',   'dataName': 'services',  'multiData': false, 'extraValue': '',  'value': "" },
            { 'name': 'commited',  'dataName': 'commited',  'multiData': false, 'extraValue': '',  'value': "" },
            { 'name': 'available', 'dataName': 'available', 'multiData': false, 'extraValue': '',  'value': "" },
            { 'name': 'allocated', 'dataName': 'allocated', 'multiData': false, 'extraValue': '',  'value': "" },
            { 'name': 'wws3',      'dataName': 'wws',       'multiData': true,  'extraValue': '3', 'value': "" },
            { 'name': 'wws2',      'dataName': 'wws',       'multiData': true,  'extraValue': '2', 'value': "" },
            { 'name': 'wws1',      'dataName': 'wws',       'multiData': true,  'extraValue': '1', 'value': "" },
        ]

        const rowSelector = 'tbody tr[data-id]'

        function showElement(selector) {
            $(selector).each(function() {
                $(this).show()
            })
        }

        function getFilterArray(filterSettings){
            const multiFilters = filterSettings
                .filter(filter => filter.value && filter.multiData)
                .flatMap(filter => filter.value.map((value,index) => `[data-${filter.dataName}*="${filter.value[index]}"]`));

            const singleFilters = filterSettings
                .filter(filter => filter.value && !filter.multiData)
                .map(filter => `[data-${filter.dataName}*=${filter.value}]`)
                .join('');

            if (multiFilters.length === 0) {
                return [singleFilters];
            }

            return multiFilters.map(filter => filter + singleFilters);
        }

        $('button.filter-appointments .glyphicon-ok:visible').each(function() {
            $(rowSelector).hide();

            filterSettings.forEach(filter => {
                if (filter.multiData) {
                    let wwsObject;
                    if ($(this).parent().data(filter.name)) {
                        wwsObject = $(this).parent().data(filter.name);
                        var filteredArray = Object.keys(wwsObject).filter(elem => wwsObject[elem] === filter.extraValue);
                        if (filteredArray.length === 0) {
                            filter.value = ["none"];
                        } else {
                            filter.value = filteredArray;
                        }
                    }
                } else {
                    if ($(this).parent().data(filter.name)) filter.value = $(this).parent().data(filter.name);
                }
            });
        });

        getFilterArray(filterSettings).forEach(filter => showElement(`${rowSelector}${filter}`));
    }

    static toggle_group(elem, select = false, checkboxName = 'markgroup') {
        const row = $(elem).parents('tr');
        const group_id = row.data('groupid');
        const p_id = row.data('pid');
        let show_pharmacy = row.data('show_pharmacy');
        show_pharmacy = show_pharmacy === 'False' ? 0 : 1;
        let show_grouping_input = row.data('show_grouping_input');
        show_grouping_input = show_grouping_input === 'False' ? 0 : 1;
        const is_iframe = row.data('is_iframe');
        let show_pharmacist = row.data('show_pharmacist');
        show_pharmacist = show_pharmacist === 'False' ? 0 : 1;
        let show_invoice = row.data('show_invoice');
        show_invoice = show_invoice === 'False' ? 0 : 1;
        let show_userform = row.data('show_userform');
        show_userform = show_userform === 'False' ? 0 : 1;
        let show_travelinput = row.data('show_travelinput');
        show_travelinput = show_travelinput === 'False' ? 0 : 1;
        let compact = row.data('compact');
        compact = compact === 'False' ? 0 : 1;
        const show_complete_workflow_button = row.data('show_complete_workflow_button') === 'False' ? 0 : 1;
        const rows = $('div.grouped tr[data-groupid="' + group_id + '"]:gt(0)');
        if ($(elem).hasClass('glyphicon-folder-open')) {
            rows.fadeOut();
        } else {
            if (rows.length === 0) {
                $.ajax({
                    url: '/appointments/grouped/' + group_id,
                    type: 'GET',
                    data: {
                        show_pharmacy,
                        show_grouping_input,
                        is_iframe,
                        show_pharmacist,
                        show_invoice,
                        show_userform: 0,
                        show_travelinput,
                        show_complete_workflow_button: 0,
                        compact,
                    },
                    success: (data) => {
                        $(data).insertAfter('tr[data-groupid="' + group_id + '"]');
                        if (select) {
                            $('tr[data-groupid="' + group_id + '"]')
                                .slice(1)
                                .find('.checkbox[name=' + checkboxName + '], .select-row')
                                .trigger('click');
                        }
                        this.enable_general_tooltip()
                    },
                });
            } else {
                rows.fadeIn();
                if (select) {
                    rows.find('.checkbox[name=' + checkboxName + '], .select-row').trigger('click');
                }
            }
        }
        $(elem).toggleClass('glyphicon-folder-open');
        $(elem).toggleClass('glyphicon-folder-close');
    }
}
