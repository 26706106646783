<template>
    <span class="vuetify-wrapper">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    :disabled="parsedDisabled"
                    @click="toggle"
                    :loading="loading"
                    min-width="30"
                    small
                    outlined
                    :icon="isjinja2 ? true : false"
                    :outlined="isjinja2 ? true : false"
                    :class="{'bootstrap-button': isjinja2, 'disable-self-assign-btn': true}"
                    :title="title"
                >
                    <v-icon v-if="parseSelfAssignDisabled" small color="red">mdi-clipboard-account</v-icon>
                    <v-icon v-else small color="green">mdi-clipboard-account</v-icon>
                </v-btn>
            </template>
            <span>
                {{ title }}
                <div>Durch einen Klick wird die selbst-Zuweisung {{ parseSelfAssignDisabled ? 'zugelassen.' : 'unterbunden.' }}.</div>
                <div v-if="isjinja2">Änderung wird erst nach Neuladen der Seite angezeigt.</div>
            </span>
        </v-tooltip>
    </span>
</template>

<script>

import axios from 'axios';
import { parseBool } from '../../helpers.js'
import { mapMutations } from 'vuex'

export default {
    name: 'ToggleSelfAssignBtn',
    components: { },
    props: {
        appointmentid: String,
        is_selfassign_disabled: Boolean,
        disabled: {
            default: false,
            type: Boolean,
        },
        /** for jinja2 compatibility */
        isjinja2: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            loading: false,
            /* For jinja, we added this state to store the value of the prop 'allow_direct_proposal' in this component
            so that we can toggle it here and have a reactive UI, this is because emitting an event to update the prop value
            in the parent component will not work if the parent is a jinja template */
            isSelfAssignDisabled: this.is_selfassign_disabled
        };
    },
    computed: {
        title() {
            if (this.parseSelfAssignDisabled) {
                return "Dieser Termin kann von Freiberuflern NICHT selbst-zugewiesen werden."
            }
            return "Dieser Termin kann von Freiberuflern selbst-zugewiesen werden."
        },
        /** Required for jinja2 compatibility */
        parsedDisabled() {
            return parseBool(this.disabled);
        },
        /** Required for jinja2 compatibility */
        parseSelfAssignDisabled() {
            return parseBool(this.isSelfAssignDisabled);
        },
    },
    methods: {
        async toggle() {
            this.loading = true;
            try {
                const response = await axios.post(`/api/appointments/${this.appointmentid}/toggle_self_assign_status/${!this.parseSelfAssignDisabled}`);
                if (response.data){
                    this.$emit("update", response.data);
                    this.isSelfAssignDisabled = response.data[0]['disable_self_assign'];
                }
            }
            catch(error) {
                this.alertError();
                console.warn("! failed to toggle self assign flag", error)
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    }
};
</script>



<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}

.red--text {
    color: red !important;
}

.green--text {
    color: green !important;
}
</style>
