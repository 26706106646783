<template>
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        nudge-width="340"
        nudge-top="40"
        nudge-right="10"
        offset-x
        content-class="v-application"
    >
        <template v-slot:activator="{ on, attrs }">
            <a
                v-bind="attrs"
                v-on="on"
                class="d-block mt-2 assign-to-admin"
                :class="{'text-decoration-underline': isOpen}"
                role="link"
            >
            {{ menuLinkText }}
            </a>
        </template>

        <v-card>
            <v-card-title>wähle einen Admin aus</v-card-title>
            <v-card-text class="pr-15">
                <div class="pr-15">
                    <v-select
                        v-model="selectedUser"
                        :items="adminUsersOptions"
                        placeholder="wähle einen Admin aus"
                        dense
                        outlined
                        hide-details
                        return-object
                        menu-props="auto"
                    ></v-select>
                    <a @click="selectedUser = currentUser" class="assign-to-me d-block mt-3">Mir zuweisen</a>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    color="secondary"
                    class="mr-2"
                    text
                >
                    ohne Auswahl schließen
                </v-btn>
                <v-btn
                    @click="onPharmacistSelected"
                    color="primary"
                    class="select"
                    :disabled="!selectedUser"
                >
                    Auswählen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios';
const DEFAULT_LINK_TEXT = 'Admin zuweisen';

export default {
    props: {
        appointment_id: String,
        admin_id: String
    },
    data() {
        return {
            isOpen: false,
            selectedUser: null,
            menuLinkText: ''
        }
    },
    methods: {
        async onPharmacistSelected() {
            try {
                await axios.post(`/api/appointments/${this.appointment_id}/assign_to_admin`, {
                    'admin_id': this.selectedUser._id
                });
                this.menuLinkText = !this.selectedUser?._id ? DEFAULT_LINK_TEXT : this.selectedUser.text;
                this.isOpen = false;
            } catch (error) {
                this.alertError()
            }
        },
        setDefaults() {
            this.selectedUser = this.adminUsersOptions.find(user => user._id === this.admin_id);
            this.menuLinkText = !this.selectedUser?._id ? DEFAULT_LINK_TEXT : this.selectedUser.text;
        },
        ...mapActions({
            fetchAdminUsers: 'fetchAdminUsers',
        }),
        ...mapMutations({
                alertError: 'alert/error',
        })
    },
    computed: {
        currentUser() {
            return this.adminUsersOptions.find(user => user.current_user)
        },
        ...mapState({
            adminUsers: state => state.adminUsers
        }),
        ...mapGetters(['adminUsersOptions']),
    },
    created() {
        this.setDefaults()
    },
    watch: {
        adminUsersOptions() {
            this.setDefaults()
        }
    },
}
</script>

<style scoped>
    .assign-to-admin {
        width: max-content
    }
</style>