var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { staticClass: "v-application vuetify-wrapper" },
    [
      _c(
        "td",
        { staticClass: "date" },
        [
          _c("vc-date-picker", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ togglePopover }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "date",
                            attrs: { disabled: _vm.isNewAppointment },
                            on: {
                              click: function ($event) {
                                return togglePopover()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(
                                "\n                            mdi-calendar\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.value.date) +
                                "\n                    "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "time" },
        [
          _c("vc-date-picker", {
            staticClass: "mb-3 begin",
            attrs: { mode: "time", "is-required": "", is24hr: "" },
            model: {
              value: _vm.beginTime,
              callback: function ($$v) {
                _vm.beginTime = $$v
              },
              expression: "beginTime",
            },
          }),
          _vm._v(" "),
          _c("vc-date-picker", {
            staticClass: "end",
            attrs: { mode: "time", "is-required": "", is24hr: "" },
            model: {
              value: _vm.endTime,
              callback: function ($$v) {
                _vm.endTime = $$v
              },
              expression: "endTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-textarea", {
            attrs: {
              outlined: "",
              rows: "2",
              dense: "",
              "row-height": "16",
              rules: _vm.requiredRules,
            },
            model: {
              value: _vm.value.time,
              callback: function ($$v) {
                _vm.$set(_vm.value, "time", $$v)
              },
              expression: "value.time",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        _vm._l(_vm.services, function (service) {
          return _c("v-checkbox", {
            attrs: {
              value: service[0],
              label: service[1],
              "data-service": service[0],
              dense: "",
              "hide-details": "",
            },
            model: {
              value: _vm.value.available_services,
              callback: function ($$v) {
                _vm.$set(_vm.value, "available_services", $$v)
              },
              expression: "value.available_services",
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "billing-type" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.billingOptions,
              value: _vm.value.billing,
              dense: "",
              flat: "",
              outlined: "",
            },
            on: { change: _vm.changeBilling },
          }),
          _vm._v(" "),
          _c("DynamicPriceSuggestion", {
            attrs: {
              date: _vm.dateMoment,
              begin: _vm.beginMoment,
              end: _vm.endMoment,
              isNightshift: _vm.value.nightshift,
              quantity: _vm.value.quantity,
              pharmacyId: _vm.pharmacy?._id,
              services: _vm.services,
              selectedServices: _vm.value.available_services,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "quantity" },
        [
          _vm.value.billing === "hours"
            ? _c("v-text-field", {
                attrs: {
                  type: "number",
                  rules: _vm.hoursRules,
                  min: "0",
                  outlined: "",
                  dense: "",
                  "hide-spin-buttons": "",
                },
                model: {
                  value: _vm.value.quantity,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "quantity", _vm._n($$v))
                  },
                  expression: "value.quantity",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-textarea", {
            attrs: {
              placeholder:
                "Abweichung von bisherigen Arbeitszeiten? Sonderregelungen, Wünsche, Anregungen?",
              outlined: "",
              value: "",
              rows: "2",
              "row-height": "16",
              dense: "",
            },
            model: {
              value: _vm.value.comment,
              callback: function ($$v) {
                _vm.$set(_vm.value, "comment", $$v)
              },
              expression: "value.comment",
            },
          }),
          _vm._v(" "),
          _c("v-textarea", {
            attrs: {
              placeholder: "Interner Kommentar",
              outlined: "",
              value: "",
              rows: "2",
              "row-height": "16",
              dense: "",
            },
            model: {
              value: _vm.value.internal_comment,
              callback: function ($$v) {
                _vm.$set(_vm.value, "internal_comment", $$v)
              },
              expression: "value.internal_comment",
            },
          }),
          _vm._v(" "),
          _c("v-textarea", {
            attrs: {
              placeholder: "Freiberufler Kommentar",
              outlined: "",
              value: "",
              rows: "2",
              "row-height": "16",
              dense: "",
            },
            model: {
              value: _vm.value.comment_to_fb,
              callback: function ($$v) {
                _vm.$set(_vm.value, "comment_to_fb", $$v)
              },
              expression: "value.comment_to_fb",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-checkbox", {
            attrs: { dense: "", "hide-details": "" },
            model: {
              value: _vm.setHidden,
              callback: function ($$v) {
                _vm.setHidden = $$v
              },
              expression: "setHidden",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-checkbox", {
            attrs: {
              disabled: !_vm.isOnlySubstitution,
              dense: "",
              "hide-details": "",
            },
            model: {
              value: _vm.setHBA,
              callback: function ($$v) {
                _vm.setHBA = $$v
              },
              expression: "setHBA",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "nd-checkbox" },
        [
          _c("v-checkbox", {
            attrs: {
              "input-value": _vm.value.nightshift,
              dense: "",
              "hide-details": "",
            },
            on: { change: _vm.changeNightshift },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-checkbox", {
            attrs: { dense: "", "hide-details": "" },
            model: {
              value: _vm.value.commited,
              callback: function ($$v) {
                _vm.$set(_vm.value, "commited", $$v)
              },
              expression: "value.commited",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isNewAppointment && !_vm.value.nightshift
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2 clone-row-as-nightshift",
              attrs: { color: "primary", small: "" },
              on: { click: _vm.addNightshiftAppointment },
            },
            [
              _c("v-icon", { attrs: { dark: "", size: "19" } }, [
                _vm._v("\n            mdi-plus\n        "),
              ]),
              _vm._v("\n        ND\n    "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isNewAppointment
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2 delete-row",
              attrs: { color: "error", small: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("delete-row", _vm.key)
                },
              },
            },
            [
              _c("v-icon", { attrs: { dark: "", size: "19" } }, [
                _vm._v("\n            mdi-delete\n        "),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }