<template>
    <div class="contractorCalendar">
        <calendar is-expanded :columns="5" :attributes="events" locale="de" :min-date="minDisplayDate"></calendar>

        <div class="mt-3">
            <button
                type="button"
                class="btn btn-sm btn-success card-link"
                title="verfügbare Termine bearbeiten"
                data-edit="available"
                data-bs-toggle="modal"
                data-bs-target="#vcalendarmodal"
                @click="initFormData('available')"
            >
                <span class="fas fa-pen" aria-hidden="true"></span>
                verfügbare Termine bearbeiten
            </button>
            <button
                type="button"
                class="btn btn-sm btn-primary card-link"
                title="Termine für Anfragen bearbeiten"
                data-edit="available_by_request"
                data-bs-toggle="modal"
                data-bs-target="#vcalendarmodal"
                @click="initFormData('available_by_request')"
            >
                <span class="fas fa-pen" aria-hidden="true"></span>
                Termine für Anfragen bearbeiten
            </button>
            <button
                type="button"
                class="btn btn-sm btn-danger  card-link"
                title="nicht verfügbare Termine bearbeiten"
                data-edit="unavailable"
                data-bs-toggle="modal"
                data-bs-target="#vcalendarmodal"
                @click="initFormData('unavailable')"
            >
                <span class="fas fa-pen" aria-hidden="true"></span>
                nicht verfügbare Termine bearbeiten
            </button>
        </div>

        <div
            class="modal fade"
            id="vcalendarmodal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">verfügbare Termine bearbeiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body clearfix">
                        <date-picker
                            locale="de"
                            :min-date="minEditDate"
                            :columns="3"
                            :rows="2"
                            :attributes="formEvents"
                            :disabled-dates="disabledFormDates"
                            :select-attribute="selectAttribute"
                            @dayclick="onDayClicked"
                        ></date-picker>
                    </div>
                    <div class="modal-footer" style="margin-top:0px !important">
                        <button
                            type="button"
                            class="btn btn-default"
                            data-bs-dismiss="modal"
                            id="cancel_contractor_dates"
                            @click="initFormData"
                        >
                            Abbrechen
                        </button>
                        <button type="button" class="btn btn-primary" id="save_contractor_dates" @click="save">
                            Speichern
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);

const LOCAL_FORMAT = 'DD.MM.YYYY';
const today = new Date();

export default {
    name: 'ContractorDatesCalendar',
    props: {
        pharmacist_id: String,
    },
    components: {
        Calendar,
        DatePicker,
    },
    data: function() {
        return {
            available: [],
            availableByRequest: [],
            unavailable: [],
            allocated: [],
            formData: {
                available: [],
                availableByRequest: [],
                unavailable: [],
                allocated: [],
            },
            editMode: 'available',
            minDisplayDate: new Date(today.getFullYear(), today.getMonth(), 1),
            minEditDate: today,
            selectAttribute: {
                highlight: {
                    style: { 'background-color': 'transparent' },
                    contentStyle: { color: 'white' },
                },
            },
        };
    },
    methods: {
        async load() {
            const response = await this.$http.get(`/api/contractor/${this.pharmacist_id}/dates`);
            this.available = response.data.available;
            this.availableByRequest = response.data.available_by_request;
            this.unavailable = response.data.unavailable;
            this.allocated = response.data.allocated;
        },
        async save() {
            await this.$http.post(`/api/contractor/${this.pharmacist_id}/dates`, {
                available: this.formData.available,
                unavailable: this.formData.unavailable,
                available_by_request: this.formData.availableByRequest,
            });
            $('#vcalendarmodal').modal('hide');
            await this.load();
        },
        initFormData(editMode = 'available') {
            this.editMode = editMode;
            this.formData = {
                available: [...this.available],
                availableByRequest: [...this.availableByRequest],
                unavailable: [...this.unavailable],
                allocated: [...this.allocated],
            };
        },
        updateDates(dates, selectedDate) {
            if (dates.includes(selectedDate)) {
                dates.splice(dates.indexOf(selectedDate), 1);
            } else {
                dates.push(selectedDate);
            }
        },
        onDayClicked(day) {
            const formattedDate = moment(day.date).format(LOCAL_FORMAT);
            if (this.editMode == 'available') this.updateDates(this.formData.available, formattedDate);
            else if (this.editMode == 'available_by_request') this.updateDates(this.formData.availableByRequest, formattedDate);
            else this.updateDates(this.formData.unavailable, formattedDate);
        },
    },
    computed: {
        availableDates() {
            return this.available.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        availableByRequestDates() {
            return this.availableByRequest.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        unavailableDates() {
            return this.unavailable.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        allocatedDates() {
            return this.allocated.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        availableFormDates() {
            return this.formData.available.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        availableByRequestFormDates() {
            return this.formData.availableByRequest.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        unavailableFormDates() {
            return this.formData.unavailable.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        allocatedFormDates() {
            return this.formData.allocated.map((d) => moment(d, LOCAL_FORMAT).toDate());
        },
        events() {
            return [
                {
                    key: 'available',
                    highlight: {
                        class: 'date-available',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.availableDates,
                },
                {
                    key: 'available_by_request',
                    highlight: {
                        class: 'date-available-by-request',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.availableByRequestDates,
                },
                {
                    key: 'unavailable',
                    highlight: {
                        class: 'date-unavailable',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.unavailableDates,
                },
                {
                    key: 'allocated',
                    highlight: {
                        class: 'date-allocated',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.allocatedDates,
                },
            ];
        },
        formEvents() {
            return [
                {
                    key: 'available',
                    highlight: {
                        class: 'date-available',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.availableFormDates,
                },
                {
                    key: 'available-by-request',
                    highlight: {
                        class: 'date-available-by-request',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.availableByRequestFormDates,
                },
                {
                    key: 'unavailable',
                    highlight: {
                        class: 'date-unavailable',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.unavailableFormDates,
                },
                {
                    key: 'allocated',
                    highlight: {
                        class: 'date-allocated',
                        contentStyle: { color: 'white' },
                    },
                    dates: this.allocatedFormDates,
                },
            ];
        },
        disabledFormDates() {
            const dates = {
                'available': this.availableFormDates,
                'available_by_request': this.availableByRequestFormDates,
                'unavailable': this.unavailableFormDates,
                'allocated': this.allocatedFormDates
            }
            delete dates[this.editMode];
            return Object.values(dates).flat()
        },
    },
    created() {
        this.load();
        $('#vcalendarmodal').on('show.bs.modal', function(e) {
            this.initFormData();
        });
    },
};
</script>

<style scoped>
.contractorCalendar >>> .vc-highlights .date-available {
    background: rgb(171, 218, 98) !important;
}
.contractorCalendar >>> .vc-highlights .date-available-by-request {
    background: rgb(98, 152, 218) !important;
}
.contractorCalendar >>> .vc-highlights .date-unavailable {
    background: rgb(217, 83, 79) !important;
}
.contractorCalendar >>> .vc-highlights .date-allocated {
    background: rgb(240, 173, 78) !important;
}
.contractorCalendar >>> .vc-day .is-disabled {
    pointer-events: none;
    color: #e0e0e0 !important;
}
</style>
