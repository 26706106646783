<template>
    <div>
        <input type="checkbox"
               class="checkbox select-row"
               @change="onChange"
               >
    </div>
</template>

<script>
export default {
    name: 'RowSelectCheckbox',
    props: ['row_id'],
    data: function () {
        return {
        }
    },
    methods: {
        onChange: function(e) {
            this.$store.commit('addOrRemoveSelectedRowId', this.row_id)
            this.toggleRowBackground()
        },
        toggleRowBackground: function() {
            $(this.$el).parents('tr').toggleClass("active")
        }
    }
}
</script>
