<template>
    <div class="contractorsWithMailSubscription">
        <table class="table" id="subscribedDaysStatistics">
            <tbody>
                <tr>
                    <td v-for="[day, ] in subscribedDaysStatistics" :key="day" :value="day">
                        {{ day }}
                    </td>
                    <td class="bold">
                        Gesamt
                    </td>
                </tr>
                <tr>
                    <td v-for="[day, count] in subscribedDaysStatistics" :key="day" :value="day">
                        {{ count }}
                    </td>
                    <td class="bold">
                        {{ processedContractors.length }}
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table table-striped table-hover" id="contractorsWithSubscription">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>ID</th>
                    <th>Angebot E-Mail</th>
                    <th>Aktiv</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="c in processedContractors" :key="c._id" :value="c._id">
                    <td>
                        <a :href="`/pharmacists/${c.id}`">
                            {{ c.firstname }} {{ c.lastname }}
                        </a>
                    </td>
                    <td>
                        {{ c.customerId }}
                    </td>
                    <td>
                        {{ c.subscribedDays.join(', ') }}
                    </td>
                    <td>
                        {{ c.active ? 'Aktiv' : 'Inaktiv' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'ContractorsWithMailSubscription',
    props: {
        contractors: Array
    },
    data: function() {
        const weekdays = [
            "Montags",
            "Dienstags",
            "Mittwochs",
            "Donnerstags",
            "Freitags",
            "Samstags",
            "Sonntags"
        ]

        const parsedContractors = this.contractors ? JSON.parse(this.contractors) : [];
        const processedContractors = parsedContractors.map((contractor) => {
            const { 
                firstname,
                lastname,
                id,
                offers_mail_frequency,
                customer_id: customerId,
                active
            } = contractor;

            const subscribedDays = offers_mail_frequency.map(day => weekdays[day])

            return {
                firstname,
                lastname,
                id,
                customerId,
                subscribedDays,
                active,
            };
        });

        const subscribedDaysStatistics = new Array(weekdays.length).fill(0);
        parsedContractors.forEach(({ offers_mail_frequency }) => {
            offers_mail_frequency.forEach(dayId => {
                subscribedDaysStatistics[dayId] += 1
            });
        });

        return {
            processedContractors,
            subscribedDaysStatistics: weekdays.map((day, i) => [day, subscribedDaysStatistics[day, i]])
        };
    }
};
</script>

<style scoped>
    .bold {
        font-weight: bold;
    }
</style>
