<template>
        <v-col>
            <v-btn
                :disabled="disabled"
                @click="showDialog = true"
                outlined
                class="first-row-time"
            >
                {{ dialogBtnLabel }}
            </v-btn>
            <v-dialog
                v-model="showDialog"
                max-width="600px"
                :fullscreen="!$vuetify.breakpoint.mdAndUp"
            >
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <v-alert outlined type="warning">
                            {{ dialogAlert }}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            @click="confirmTimeChange"
                            class="confirm"
                            color="primary"
                        >
                            <v-icon left>mdi-check</v-icon>
                            Ja
                        </v-btn>
                        <v-btn @click="showDialog = false">
                            <v-icon left>mdi-cancel</v-icon>
                            Abbrechen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
</template>

<script>

export default {
    name: 'CopyFirstAppointmentDialog',
    props: {
        disabled: false,
        dialogBtnLabel: String,
        dialogAlert: String
    },
    data: () => ({
        showDialog: false
    }),
    methods: {
        confirmTimeChange() {
            this.$emit('copy-first-appointment-data')
            this.showDialog = false
        },
    }
};
</script>

<style scoped>
    .first-row-time {
        margin: 20px 5px;
    }
    .confirm {
        margin: 0 10px;
        background-color: #337ab7 !important;
    }
</style>
