<template>
  <tr class="vuetify-wrapper" :data-id="subscribedPharmacy?.pharmacyId">
    <td>
      <a :href="`/pharmacies/${subscribedPharmacy?.pharmacyId}`" target="_blank">
        {{ subscribedPharmacy?.pharmacy }}
      </a>
    </td>
    <td class="flex-box">
      <v-btn
        class="btn-unlink"
        small
        depressed
        color="error"
        style="margin: 0 8px"
        @click="unlinkPharmacy">
        <v-icon small>mdi-trash-can-outline</v-icon>Verknüpfung aufheben
      </v-btn>
    </td>
  </tr>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  components: {},
  props: {
    subscribedPharmacy: Object,
    discountId: String,
  },
  data() {
    return {
      isUnLinkPharmaciesDialogOpen: false,
    };
  },
  methods: {
    async unlinkPharmacy() {
      try{
        const pharmacyId = this.subscribedPharmacy.pharmacyId;
        const discountId = this.discountId;
        if (discountId && pharmacyId) {
          const response = await axios.get(`/api/discounts/${discountId}/unassign_discount_for/${pharmacyId}`);
          if (response.data.status >= 400) throw new Error()
          this.$emit('refresh');
          this.alertSuccess("Die Verknüpfung wurde erfolgreich aufgehoben");
        }
      } catch (error) {
        this.alertError();
      }

    },
    ...mapMutations({
      alertError: "alert/error",
      alertSuccess: "alert/success",
    }),
  }
};
</script>

<style>
.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
