<template>
    <tr class="vuetify-wrapper overflow-visible">
        <th>Datum</th>
        <th>von/bis</th>
        <th width="15%">Arbeitszeiten</th>
        <th width="7%">
            <span v-for="service, i in services">
                <v-btn
                    dense
                    text
                    color="info" dark
                    x-small
                    :data-service="service[0]"
                    @click="$emit('toggle-services', service[0])"
                >
                    {{ service[1] }}
                </v-btn>
            </span>
        </th>
        <th>Art der<br>Abrechnung</th>
        <th class="quantity">Stunden</th>
        <th width="20%">Kommentar</th>
        <th class="is-hidden">
            <div class="rotate">
                <div>
                    <span>Versteckt</span>
                </div>
            </div>
            <div class="text-center">
                <v-simple-checkbox
                    v-model="toggleAllHidden"
                    :indeterminate="allHidden===null"
                />
            </div>
        </th>
        <th>
            <div class="rotate">
                <div>
                    <span>HBA erforderlich</span>
                </div>
            </div>
            <div class="text-center">
                <v-simple-checkbox
                    v-model="toggleAllHBA"
                    :indeterminate="allHBATristate===null"
                    :disabled="!shouldEnableHBACheckbox"
                />
            </div>
        </th>
        <th>
            <div class="rotate">
                <div>
                    <span>Nachtdienst</span>
                </div>
            </div>
            <div class="text-center">
                <v-simple-checkbox
                    @click="$emit('toggle-nightshift')"
                    :value="allNightshiftTristate"
                    :indeterminate="allNightshiftTristate===null"
                />
            </div>
        </th>
        <th>
            <div class="rotate">
                <div>
                    <span>feste Zusage</span>
                </div>
            </div>
            <div class="text-center">
                <v-simple-checkbox
                    @click="$emit('toggle-committed')"
                    :value="allCommittedTristate"
                    :indeterminate="allCommittedTristate===null"
                />
            </div>
        </th>
    </tr>
</template>

<script>

export default {
    name: 'BatchAddAppointmentHeaderRow',
    props: {
        services: Array,
        allNightshiftTristate: false,
        allCommittedTristate: false,
        allHBATristate: false,
        allHidden: false,
        shouldEnableHBACheckbox: Boolean
    },
    data: () => {
    },
    computed: {
        toggleAllHBA: {
            get() {
                return this.allHBATristate
            },
            set() {
                this.$emit('toggle-hba')
            }
        },
        toggleAllHidden: {
            get() {
                return this.allHidden
            },
            set() {
                this.$emit('toggle-hidden')
            }
        }

    },
};
</script>

<style scoped>
    th {
        vertical-align: middle;
        padding: 0 10px !important;
        border-bottom: none !important;
        font-size: 15px !important;
        color: black !important;
    }
</style>
