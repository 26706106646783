var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v("\n            Eventuelle Terminüberschneidung\n        "),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm._v(
                "\n            Folgende Termine der Apotheke überschneiden sich eventuell:\n            "
              ),
              _c(
                "v-list",
                _vm._l(_vm.clashes, function (appointment) {
                  return _c(
                    "v-list-item",
                    { key: appointment._id, attrs: { dense: "" } },
                    [
                      _c("a", { attrs: { href: appointment.url } }, [
                        _vm._v(
                          _vm._s(_vm.formatDateString(appointment.date)) +
                            " - " +
                            _vm._s(appointment.time)
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm._v(
                "\n            Termine, die eingetragen werden sollen:\n            "
              ),
              _c(
                "v-list",
                _vm._l(_vm.appointments, function (appointment) {
                  return _c(
                    "v-list-item",
                    { key: appointment._id, attrs: { dense: "" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatDateString(appointment.date)) +
                          " - " +
                          _vm._s(appointment.time) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              "\n            Wollen Sie die Termine trotzdem eintragen?\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "confirm",
                  attrs: { color: "primary" },
                  on: { click: _vm.confirm },
                },
                [_vm._v(" Trotzdem eintragen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-cancel")]),
                  _vm._v("\n                Abbrechen\n            "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }