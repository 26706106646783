var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BatchUpdatePricesBtn", {
    attrs: { "appointment-ids": _vm.selectedRowIds },
    on: { save: _vm.reload },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }