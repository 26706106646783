<template>
  <div class="v-application vuetify-wrapper">
    <h3 style="padding: 1rem">Verlinkte Rabatte</h3>
    <div>
      <div class="new-subscription">
        <v-select
          class="discount-filter"
          :items="discounts"
          v-model="selectedDiscount"
          placeholder="Suche Rabatt"
        />
        <v-btn
          id="assign-discount-btn"
          :disabled="!selectedDiscount"
          @click="assignDiscountToPharmacy()">
            Rabatte verlinken
        </v-btn>
      </div>
      <div class="subscribed-discounts">
        <div class="no-discounts" v-if="subscribedDiscounts?.length === 0">
          Es können keine Rabatte angezeigt werden
        </div>
        <v-simple-table v-else class="discount-table">
          <template v-slot:default>
            <thead>
              <tr class="vuetify-wrapper">
                <th>Rabatt Name</th>
                <th>Rabatt Beschreibung</th>
                <th>Rabatt Typ</th>
                <th>Rabatt Wert</th>
                <th>Rabatt Startdatum</th>
                <th>Rabatt Endtermin</th>
                <th>Rabatt Ist Aktiv?</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr class="vuetify-wrapper" :data-id="item._id" v-for="(item) in subscribedDiscounts">
                <td>{{ item?.discountName }}</td>
                <td>{{ item?.description }}</td>
                <td>{{ translateDiscountType(item.discountType) }}</td>
                <td>{{ item?.discountValue }}</td>
                <td>{{ item?.startDate }}</td>
                <td>{{ item?.endDate }}</td>
                <td>{{ item?.isActive }}</td>
                <td>
                  <v-btn
                    small
                    depressed
                    class="unlink-discount-btn"
                    color="error"
                    @click="isUnlinkDiscountDialogOpen = true">
                      <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
                <unlink-discount-dialog
                  v-model="isUnlinkDiscountDialogOpen"
                  :discount="item"
                  :pharmacyId="pharmacy?._id" />
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import UnlinkDiscountDialog from "./UnlinkDiscountDialog.vue";

export default {
  components: {
    "unlink-discount-dialog": UnlinkDiscountDialog,
  },
  props: {
    pharmacy_json: String,
  },
  data: () => ({
    isLoading: false,
    isUnlinkDiscountDialogOpen: false,
    discounts: [],
    selectedDiscount: null,
  }),
  methods: {
    async fetchAllDiscounts() {
      try {
        const applied_discounts = JSON.parse(this.pharmacy_json).applied_discounts || {};
        const response = await axios.get("/api/discounts/");
        this.discounts = response.data
                          .map((discount) => ({ text: discount.discountName, value: discount._id }))
                          .filter((discount)=> !applied_discounts[discount.value]) || [];
      } catch (error) {
        this.alertError();
      }
    },
    async assignDiscountToPharmacy() {
      try{
        const discountId = this?.selectedDiscount;
        const pharmacyId = this?.pharmacy?._id;
        if (discountId && pharmacyId) {
          const response = await axios.get(`/api/discounts/${discountId}/assign_to/${pharmacyId}`);
          if (response.data.status >= 400) throw new Error();
          await this.fetchAllDiscounts();
          this.alertSuccess("Der Rabatt wurde erfolgreich verknüpft");
          window.location.reload();
          return;
        }
        this.alertError("Bitte wählen Sie eine Apotheke aus, um fortzufahren");
      } catch (error) {
        this.alertError();
      }
    },
    translateDiscountType(discountType) {
      return discountType === "percentage" ? "Prozent" : discountType === "number" ? "Nummer" : "";
    },
    ...mapMutations({
      alertError: "alert/error",
      alertSuccess: "alert/success",
    }),
  },
  computed: {
    pharmacy() {
      return JSON.parse(this.pharmacy_json);
    },
    subscribedDiscounts() {
      const pharmacy = JSON.parse(this.pharmacy_json);
      const discounts = pharmacy?.applied_discounts || {};
      return Object.keys(discounts)?.map((key) => ({ ...discounts[key], _id: key }));
    },
  },
  async mounted() {
    await this.fetchAllDiscounts();
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.new-subscription {
  display: grid;
  grid-template-columns: 8fr 4fr;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

th {
  vertical-align: middle;
  padding: 0 10px !important;
  border-bottom: none !important;
  font-size: 15px !important;
  color: black !important;
}

.discount-table {
  border: 1px solid #EEE;
}

.no-discounts {
  text-align: center;
  width: 100%;
  padding: 1rem;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
}
</style>
