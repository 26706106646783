/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Vue from 'vue';

import utils, { AppointmentFormHelpers } from './utils';
import { store } from './vue/store';
import debounce from 'lodash.debounce';
import moment from "moment";


$.fn.flyingpharmacists = function (opts) {

    if (opts == null) { opts = {}; }
    const init = function () {
        $('.ontheflyinput-container').on('change', '.ontheflyinput', on_appointment_change);
        $('.ontheflyinput-container').on('keyup', '.ontheflyinput', on_appointment_change);

        $('.ontheflyinput-container').on('click', '.save-onthefly', function (e) {
            e.preventDefault();
            const that = this;
            const row = $(this).parents('tr');
            const url = $(this).attr("href");
            const pharmacy_id = $(row).data('pid');

            const groupId = row.data('groupid');
            const rowsInGroup = (groupId !== 'None' ? $(`tr[data-groupid="${row.data('groupid')}"]`) : row);
            const input_data = {
                pharmacist_id: $(row).find('.pharmacist-select').val(),
                service: $(row).find('.pharmacist-service-select').val(),
                travelcosts: parseFloat($(row).find('.travelcosts-input').val().replace(',', '.')),
                travelcostsByAppointmentId: rowsInGroup.toArray().map((curr) => (
                    `${curr.dataset.id}=${parseFloat(curr.querySelector('.travelcosts-input').value)}`
                )).join(',')
            };
            const commited = $(row).data('commited') === 'True';
            const submit_data = function () {
                if (commited || (!commited && confirm('Achtung: Der Termin wurde noch nicht fest zugesagt! Wollen Sie ihn wirklich speichern?'))) {
                    return $.ajax({
                        url,
                        type: "POST",
                        data: input_data,
                        success(data) {
                            $(rowsInGroup).removeClass('warning');
                            if (data.status.toLowerCase() == 'fail') {
                                $(rowsInGroup).addClass('danger');
                                if(data.msg) {
                                    alert(data.msg)
                                }
                                return;
                            }
                            $(rowsInGroup).addClass('success');
                            $(rowsInGroup).find('.table-buttons').show();
                            $(rowsInGroup).find('.save-onthefly').hide();
                            $(rowsInGroup).find('.commit-and-send-mail').hide();
                            return $("#update-button").show();
                        }
                    });
                }
            };

            //check_date_url = $('#pharmacy-data').data('checkurl')
            utils.check_appointment_date("/pharmacies/" + pharmacy_id + "/check_date", {
                a_id: row.data('id'),
                pharmacist_id: input_data['pharmacist_id']
            }
                , function (data) {
                    if (data['status'] === 'wait') {
                        const modal = $('#appointments_clash_modal');
                        const div = modal.find('#appointment_clashes');
                        const links = [];
                        for (let c of Array.from(data['clashes'])) {
                            links.push('<a href="' + c['url'] + '" target="_blank">' + c['date'] + ' - ' + c['title'] + '</a>');
                        }
                        div.html(links.join('<br />'));
                        modal.modal('show');
                        return $('#submit_save_appointment_group').on('click', () => submit_data());
                    } else {
                        return submit_data();
                    }
                });
            return false;
        });
        // mark table row for creating invoice when row clicked
        $('table tr').on('click', function (e) {
            return $(this).find("input.select-row[type=checkbox]").click();
        });
        // don't mark row when clicking input element in row
        $('table tr input,select').click(e => e.stopPropagation());
        $("#create-invoice-form,#download-csv-form, #appointments-delete-form, #retract-apobilanz-form").submit(function () {
            $(this).find("input[name=aids]").val(store.getters.selectedRowIdsJson);
            return true;
        });
        $("#invoices-export-form, #invoices-download-form").submit(function () {
            $(this).find("input[name=invoice_ids]").val(store.getters.selectedRowIdsJson);
            return true;
        });
        $("#delete-appointments-form, #delete-appointments-form2, [name='reset-pricing-form']").submit(function () {
            const a_ids = utils.collect_marked_appointments_alt();
            $(this).find("input[name=aids]").val(JSON.stringify(a_ids));
            return true;
        });
        $("#accept-appointments-form, #inquire-form, #uninquire-form, #unaccept-form, #batch-edit-form").submit(function () {
            const a_ids = utils.collect_marked_appointments_alt();
            $(this).find("input[name=aids]").val(JSON.stringify(a_ids));
            if ($(this).data('is_angel') === 'True') {
                return confirm('Sind Sie sicher? Diese Apotheke hat noch keine Kundennummer!');
            } else {
                return true;
            }
        });

        $(document).on('click', '#show-offered-appointments', function (e) {
            e.preventDefault();
            const url = $(this).data('url');
            return $.ajax({
                url,
                type: "POST",
                data: {
                    a_ids: store.getters.selectedRowIdsJson
                },
                success(data) {
                    return $('#show_offers_modal .modal-body').html(data);
                }
            });
        });

        // prefill appointments add/edit form with snippets
        $('.appointment-form #date').change(function () {
            const day = AppointmentFormHelpers.getDay()
            const { hours, snippet, begin, end } = AppointmentFormHelpers.getSnippets(day);

            $('.appointment-form #time').val(snippet);
            $('.appointment-form input[name=begin]').val(begin);
            $('.appointment-form input[name=end]').val(end);
            AppointmentFormHelpers.setQuantity(hours);
        });

        $('#appointment-add-form').submit(function (e) {
            e.preventDefault();
            return utils.check_appointment_date($(this).data('checkurl'),
                { date: $(this).find('#date').val() }
                , data => {
                    if (data['status'] === 'wait') {
                        const modal = $('#appointment_add_modal');
                        modal.find('#appointment_date')
                            .html(data['date']);
                        modal.find('#appointment_link')
                            .attr('href', data['url'])
                            .find('#appointment_title')
                            .html(data['title']);
                        return modal.modal('show');
                    } else {
                        return utils.submit_appointment_form($(this));
                    }
                });

        });
        $('#submit_add_appointment').on('click', () => $('.appointment-form').unbind('submit').submit());
        $('#appointment-edit-form').submit(function (e) {
            e.preventDefault();
            return utils.submit_appointment_form($(this));
        });

        // hide and show flat amount field of appointment form
        utils.toggle_quantity_field(false);
        $('select#billing').change(() => utils.toggle_quantity_field(true));

        // uncheck confirmed or angel flag when the other is clicked in form
        $('.appointment-form input#commited').on('change', function () {
            if ($(this).is(":checked")) {
                return $('.appointment-form input#is_angel').prop('checked', false);
            }
        });
        $('.appointment-form input#is_angel').on('change', function () {
            if ($(this).is(":checked")) {
                return $('.appointment-form input#commited').prop('checked', false);
            }
        });

        $('body').on('change', 'input[name=markinvoice]', function () {
            utils.collect_marked_appointments();
            const row = $(this).parents('tr');
            const toggleIcon = row.find('.toggle-group');
            if ((row.data('groupid') !== 'None') && toggleIcon.hasClass('glyphicon-folder-close')) {
                let checkboxName, select;
                return utils.toggle_group(toggleIcon, (select = true), (checkboxName = 'markinvoice'));
            }
        });

        return $('body').on('change', 'input[name=markgroup]', function () {
            utils.collect_marked_appointments_alt();
            const row = $(this).parents('tr');
            const toggleIcon = row.find('.toggle-group');
            if ((row.data('groupid') !== 'None') && toggleIcon.hasClass('glyphicon-folder-close')) {
                let checkboxName, select;
                return utils.toggle_group(toggleIcon, (select = true), (checkboxName = 'markgroup'));
            }
        });
    };

    /**
     * Requests the travel costs for a certain pharmacist to a pharmacy and
     * puts the result into the `travelCostInput`.
     *
     * Debounced to avoid unused route cost lookups while the user is typing
     * or scrolling through the list
     */
    var getAndApplyTravelCosts = debounce(function(travelCostInput, pharmacyId, pharmacistId, appointmentId) {
        $.ajax({
            url: '/pharmacies/' + pharmacyId + '/travel_costs',
            type: "GET",
            data: {
                pharmacist_id: pharmacistId,
                appointment_id: appointmentId,
            },
            success: data => {
                // Up to this distance the travelcosts are applied to each appointment
                const maxDistanceForRepeatedCostsKm = 100;
                travelCostInput.prop('disabled', false);
                const costs = data.costs || 0;
                travelCostInput.val(costs);

                if (data.route_costs[0].is_capped){
                    let infoMessage = `Die maximalen Kosten für Einzeltermine sind auf ${data.route_costs[0].pharmacy_max_travel_cost}€ begrenzt (${data.route_costs[0].max_distance.toFixed(0)} km). Die tatsächen Kosten wären bei ${data.route_costs[0].non_capped_costs}€ (${data.route_costs[0].distance.toFixed(0)} km).`;

                    travelCostInput
                        .css("background", "orange")
                        .css("color", "white")
                        .css("font-weight", "bold")
                        .attr("data-toggle", "tooltip")
                        .attr("data-placement", "top")
                        .attr("title", infoMessage)
                } else {
                    travelCostInput
                        .css("background", "")
                        .css("color", "")
                        .css("font-weight", "")
                        .removeAttr("data-toggle")
                        .removeAttr("data-placement")
                        .removeAttr("title")
                }

                const distance = data.distance || 0;
                const costForOthers = distance > maxDistanceForRepeatedCostsKm ? 0 : costs;
                const groupId = travelCostInput.parents("tr").data('groupid');
                if (groupId && groupId !== 'None') {
                    const [firstAppointmentRow, ...groupAppointmentRows] = $(`[data-groupid='${groupId}']`);
                    groupAppointmentRows.forEach((a, i) => {
                        const lastAppointment = groupAppointmentRows[i - 1] ?? firstAppointmentRow;
                        let lastAppointmentBeginTime = moment.utc(lastAppointment.dataset['begin']);
                        let lastAppointmentEndTime = moment.utc(lastAppointment.dataset['end']);
                        let currentAppointmentBeginTime = moment.utc(a.dataset['begin']);
                        let lastAppointmentDate = moment.utc(lastAppointment.dataset["date"]);
                        let currentAppointmentDate = moment.utc(a.dataset["date"]);

                        let lastAppointmentEndDateTime = lastAppointmentDate.clone().set({
                            hours: lastAppointmentEndTime.hours(),
                            minutes: lastAppointmentEndTime.minutes()
                        });
                        let lastAppointmentBeginDateTime = lastAppointmentDate.clone().set({
                            hours: lastAppointmentBeginTime.hours(),
                            minutes: lastAppointmentBeginTime.minutes()
                        });
                        let currentAppointmentBeginDateTime = currentAppointmentDate.clone().set({
                            hours: currentAppointmentBeginTime.hours(),
                            minutes: currentAppointmentBeginTime.minutes()
                        });

                        let overnight = lastAppointmentEndDateTime.isSameOrBefore(lastAppointmentBeginDateTime)
                        if (overnight) {
                            lastAppointmentEndDateTime.add(1, 'days');
                        }
                        if (currentAppointmentBeginDateTime.isSame(lastAppointmentEndDateTime)) {
                            $(a).find('.travelcosts-input').val(0).change();
                            return;
                        }
                        $(a).find('.travelcosts-input').val(costForOthers).change();
                    })
                }
            }
        });
    }, 200);

    var on_appointment_change = function (e) {
        const row = $(this).parents('tr');
        $(row).addClass('warning');
        $(row).find('.table-buttons').hide();
        //$(row).find('.onthefly-saved').hide()
        $(row).find('.save-onthefly.btn').show();

        // Todo jan #87:
        // Only the selection of a pharmacist to the pharmacist shall show the complete-workflow button
        // not the change of e.g. travelcosts
        $(row).find('.save-onthefly.complete-workflow').show();

        // If the pharmacist has been changed:
        if (e.target.classList.contains('usersearch')) {
            const appointmentId = row.data('id');
            const pharmacyId = row.data('pid');
            const pharmacistId = row.find('.pharmacist-select').val();
            const travelCostInput = row.find('.travelcosts-input');

            // do not use row (parent('tr')) as there may be multiple dropdowns + pharmacist-links in a single tr
            const linkToPharmacist = $(this).parents('div.row').find('[name="pharmacist-link"]');

            if (!pharmacistId) return;
            else if(pharmacistId === '__unset__') {
                travelCostInput.val(0);
                linkToPharmacist.hide();
                return;
            }

            // Update hyperlink to pharmacist with the new ID
            linkToPharmacist.show();
            linkToPharmacist.prop('href', linkToPharmacist.prop('href').replace(/[^\/]+$/ig, pharmacistId)); // ID is in the last URLSegment

            travelCostInput.prop('disabled', true);
            getAndApplyTravelCosts(travelCostInput, pharmacyId, pharmacistId, appointmentId);
        }
    };

    $(this).each(init);
    return this;
};
