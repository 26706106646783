<template>
    <span class="vuetify-wrapper">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"

                    :disabled="parsedDisabled"
                    @click="toggle"
                    :loading="loading"

                    min-width="30"
                    small
                    outlined
                    :icon="isjinja2 ? true : false"
                    :outlined="isjinja2 ? true : false"
                    :class="{'bootstrap-button': isjinja2}"
                    :title="title"
                >
                    <v-icon v-if="parsedHidden" small color="red">mdi-eye-off</v-icon>
                    <v-icon v-else small color="green">mdi-eye</v-icon>
                </v-btn>
            </template>
            <span>
                {{ title }}
                <div>Durch einen Klick wird die gesamte Gruppe {{ parsedHidden ? 'wieder sichtbar' : 'versteckt' }}.</div>
                <div v-if="isjinja2">Änderung wird erst nach Neuladen der Seite angezeigt.</div>
            </span>
        </v-tooltip>
    </span>
</template>

<script>

import axios from 'axios';
import { parseBool } from '../../helpers.js'
import { mapMutations } from 'vuex'

export default {
    name: 'MarkAppointmentAsHidden',
    components: { },
    props: {
        appointmentid: String,
        is_appointment_hidden: Boolean,
        disabled: {
            default: false,
            type: Boolean,
        },
        /** for jinja2 compatibility */
        isjinja2: {
            type: Boolean,
            default: false
        },
    },
    data: function() {
        return {
            loading: false,
        };
    },
    computed: {
        title() {
            if (this.parsedHidden) {
                return "Dieser Termin kann von Freiberuflern nicht eingesehen werden."
            }
            return "Dieser Termin kann von Freiberuflern eingesehen werden."
        },
        /** Required for jinja2 compatibility */
        parsedDisabled() {
            return parseBool(this.disabled);
        },
        /** Required for jinja2 compatibility */
        parsedHidden() {
            return parseBool(this.is_appointment_hidden);
        },
    },
    methods: {
        async toggle() {
            this.loading = true;
            try {
                // This returns a list of appointments, the entire appointment group
                await axios.post(`/api/appointments/${this.appointmentid}/hidden/${!this.parsedHidden}`)
                    .then(r => this.$emit("update", r.data));
            }
            catch(error) {
                this.alertError();
                console.warn("! failed to toggle hidden flag", error)
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    async mounted() {
    },
};
</script>

<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}

.red--text {
    color: red !important;
}

.green--text {
    color: green !important;
}
</style>
