var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "v-application vuetify-wrapper" },
    [
      _vm.isBatchAdd
        ? _c("v-col", [
            _c("h3", [_vm._v("neue Termine anlegen")]),
            _vm._v(" "),
            _c("p", { staticClass: "text-info" }, [
              _vm._v(
                '\n            Bitte markieren Sie die gewünschten Tage im Kalender und klicken Sie auf "Markierte Termine übernehmen".\n        '
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isBatchAdd
        ? _c(
            "v-col",
            [
              _c("vc-date-picker", {
                attrs: {
                  columns: _vm.$screens({ default: 1, lg: 4 }),
                  attributes: _vm.attributes,
                },
                on: { dayclick: _vm.onDayClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isBatchAdd
        ? _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "add-calendar-appointments",
                  on: { click: _vm.addAppointments },
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v("\n                mdi-plus\n            "),
                  ]),
                  _vm._v(
                    "\n            Markierte Termine übernehmen\n        "
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isBatchAdd
        ? _c("v-col", [
            _c("p", { staticClass: "text-info" }, [
              _vm._v(
                "\n            Achtung: Für zusätzliche Nachtdienste zu einem Tagesdienst legen Sie bitte zwei Termine an und markieren einen als Nachtdienst. Das Datum markiert immer den Anfang des Dienstes.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "col-sm-12 col-md-5" },
            [
              _c("CopyFirstAppointmentDialog", {
                attrs: {
                  disabled: !_vm.onlyDayshifts || this.appointments.length < 2,
                  dialogBtnLabel: "Zeiten vom ersten Termin auf alle anwenden",
                  dialogAlert:
                    "Startzeit, Endzeit, Zeitbeschreibung, Stunden und Abrechnungsart des ersten Termins auf alle anderen anwenden",
                },
                on: { "copy-first-appointment-data": _vm.copyTime },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "col-sm-12 col-md-5" },
            [
              _c("CopyFirstAppointmentDialog", {
                attrs: {
                  disabled: _vm.disableCopyComments,
                  dialogBtnLabel:
                    "Kommentare des ersten Termins auf alle anwenden",
                  dialogAlert:
                    "Kommentare des ersten Termins auf alle anwenden",
                },
                on: { "copy-first-appointment-data": _vm.copyComment },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "vuetify-wrapper overflow-visible" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "thead",
                          [
                            _c("AppointmentHeaderRow", {
                              attrs: {
                                services: _vm.services,
                                allNightshiftTristate:
                                  _vm.allNightshiftTristate,
                                allCommittedTristate: _vm.allCommittedTristate,
                                allHBATristate: _vm.allHBATristate,
                                allHidden: _vm.allHidden,
                                shouldEnableHBACheckbox:
                                  _vm.shouldEnableHBACheckbox,
                              },
                              on: {
                                "toggle-services": _vm.toggleServices,
                                "toggle-nightshift": _vm.toggleNightshift,
                                "toggle-committed": _vm.toggleCommitted,
                                "toggle-hba": _vm.toggleHBA,
                                "toggle-hidden": _vm.toggleHidden,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(
                              _vm.appointmentsOrderedByDate,
                              function (appointment, index) {
                                return _c("AppointmentRow", {
                                  key: appointment._id,
                                  attrs: {
                                    services: _vm.services,
                                    pharmacy: _vm.pharmacy,
                                  },
                                  on: {
                                    "delete-row": _vm.deleteRow,
                                    "add-nightshift-appointment":
                                      _vm.addNightShiftAppointment,
                                    "change-nightshift":
                                      _vm.onNightshiftChanged,
                                    "change-billing": _vm.onBillingChanged,
                                    "change-date": _vm.onDateChanged,
                                    "change-hidden": _vm.onIsHiddenChanged,
                                    "update-appointment":
                                      _vm.onAppointmentUpdated,
                                  },
                                  model: {
                                    value: _vm.appointmentsOrderedByDate[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.appointmentsOrderedByDate,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "appointmentsOrderedByDate[index]",
                                  },
                                })
                              }
                            ),
                            _vm._v(" "),
                            _c("AppointmentsClashesDialog", {
                              attrs: {
                                clashes: _vm.clashes,
                                appointments: _vm.appointmentsOrderedByDate,
                              },
                              on: {
                                confirm: _vm.submitData,
                                abort: function ($event) {
                                  _vm.clashes = []
                                },
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isBatchAdd
        ? _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "add-row",
                  attrs: { color: "primary", small: "" },
                  on: { click: _vm.insertNewRow },
                },
                [
                  _c("v-icon", { attrs: { dark: "", size: "19" } }, [
                    _vm._v("\n                mdi-plus\n            "),
                  ]),
                  _vm._v("\n            Termin\n        "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              staticClass: "save-appointments",
              attrs: {
                disabled: !_vm.appointments.length || !_vm.valid,
                color: "primary",
              },
              on: { click: _vm.onSaveButtonClicked },
            },
            [_vm._v("\n            Speichern\n        ")]
          ),
          _vm._v(" "),
          _c("v-btn", { attrs: { href: _vm.pharmacyURL } }, [
            _vm._v("Abbrechen"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }