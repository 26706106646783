<template>
    <div class="vuetify-wrapper">
        <v-btn
            @click="toggleVerified"
            :loading="loading"
            small
            icon
            outlined
            class="bootstrap-button"
            :title="title"
        >
            <v-icon v-if="verifiedDate" small color="green">mdi-eye-check</v-icon>
            <v-icon v-else small color="red">mdi-eye-remove</v-icon>
        </v-btn>
    </div>
</template>

<script>

import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
    name: 'MarkAppointmentAsVerified',
    components: { },
    props: {
        appointmentId: String,
        verifiedDate: Date|null|undefined,
        verifiedBy: String|null|undefined,
    },
    data: function() {
        return {
            loading: false,
        };
    },
    computed: {
        title() {
            if (this.verifiedDate) {
                return `Die Raten wurden am ${this.verifiedDate} verifiziert. Anklicken um sie als nicht-verifizert zu markieren`
            }
            return `Die Raten wurden noch nicht verifiziert. Anklicken, um sie als verifiziert zu markieren.`
        }
    },
    methods: {
        async toggleVerified() {
            this.loading = true;
            try {
                await axios.get(`/api/appointments/${this.appointmentId}/verified/${!this.verifiedDate}`)
                    .then(r => this.$emit("update", r.data));
            }
            catch(error) {
                this.alertError();
                console.log("! failed to toggle verified flag")
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    async mounted() {
    },
};
</script>

<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}

.red--text {
    color: red !important;
}

.green--text {
    color: green !important;
}
</style>
