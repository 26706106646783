<template>
    <div>
        <v-card class="v-application pharmacies-overview">
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suche nach Name, Adresse, Kundennummer etc."
                    single-line
                    hide-details
                    clearable
                    autofocus
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="pharmacies"
                class="v-application--is-ltr"
                mobile-breakpoint="670"
                :page.sync="pageNumber"
                :no-data-text="search ? 'Keine Suchergebnisse' : 'Keine Apotheken in dieser Kategorie'"
                @update:options="onOptionsChange"
                :options.sync="tableOptions"
                :loading="isLoading"
                loading-text="Wird geladen..."
                :server-items-length="search ? searchResultsCount : pharmacyCount"
                :items-per-page="5"
                :footer-props="{'items-per-page-options':[5, 10, 25, 50], 'show-first-last-page': true, 'show-current-page': true, 'items-per-page-text': 'Zeilen pro Seite'}"
            >
                <template v-slot:item.name="{ item }">
                    <a :href="`/pharmacies/${item._id}`" class="name">{{ item.name }}{{ item.shortname && ` (${item.shortname})` }}</a>
                    <a :href="`/users/admin/?city=${item.zip}`" target="_blank">
                        <v-icon
                            small
                            color="blue-grey"
                        >
                            mdi-earth
                        </v-icon>
                    </a>
                    <br />
                    <small class="text--secondary">{{ item.owner_firstname }}</small>
                    <small class="text--secondary">{{ item.owner_lastname }}</small>
                </template>
                <template v-slot:item.created="{ item }">
                    {{ formatDate(item.created) }}
                </template>
                <template v-slot:item.city="{ item }">
                    <div>{{ item.city }}{{ ", "+item.zip }}</div>
                    <small class="text--secondary">{{ item.street }}</small>
                </template>
                <template v-slot:item.open_appointments_count="{ item }">
                    <div class="ml-lg-12 ml-md-7 open-appointments-count" :data-id="item._id">
                        {{ item.open_appointments_count }}
                    </div>
                </template>
                <template v-slot:item.action_buttons="{ item }">
                    <div class="text-right">
                        <v-btn
                            v-if="item.sepa_auth"
                            :href="`/pharmacies/${item._id}/sepa_download`"
                            target="_blank"
                            class="my-2"
                            small
                        >
                            <v-icon small>mdi-download</v-icon>
                            SEPA-Mandat
                        </v-btn>
                        <v-btn small :href="`/pharmacies/${item._id}/edit`" class="my-2 ml-5">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>


<script>
    import axios from 'axios';
    import moment from 'moment';
    import _ from 'lodash';
    import { mapMutations } from 'vuex';

    export default {
        props: {
            tab: String,
        },
        data: () => ({
            search: '',
            pageNumber: 1,
            headers: [
                { text: 'K.-Nr.', value: 'customer_id' },
                { text: 'Name', value: 'name' },
                { text: 'Erstellt', value: 'created' },
                { text: 'Adresse', value: 'city' },
                {
                    text: 'Termine in Bearbeitung',
                    value: 'open_appointments_count',
                    filterable: false,
                    sortable: false
                },
                {
                    text: '',
                    value: 'action_buttons',
                    filterable: false,
                    sortable: false
                },
            ],
            pharmaciesForPage: [],
            pharmacyCount: 0,
            searchResults: [],
            searchResultsCount: 0,
            tableOptions: {},
            isLoading: false,
        }),
        methods: {
            formatDate(date) {
                if (!date) return ''
                return moment(date).format('DD-MM-yyyy HH:mm')
            },
            async fetchPharmacyCount(pharmacyType) {
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/pharmacies/${pharmacyType}/count`);
                    this.pharmacyCount = response.data;
                } catch (error) {
                    this.alertError()
                    console.log("failed to fetch pharmacy counts", error);
                } finally {
                    this.isLoading = false
                }
            },
            async fetchPharmacies(pharmacyType, page=1, sortBy='city', sortAsc=1, itemsPerPage=25) {
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/pharmacies/${pharmacyType}/paginated`, {
                        params: {
                            page,
                            sort_by: sortBy,
                            sort_asc: sortAsc,
                            page_size: itemsPerPage,
                        }
                    });
                    this.pharmaciesForPage = response.data;
                } catch(error) {
                    this.alertError()
                    console.log("failed to fetch paginated pharmacies", error);
                } finally {
                    this.isLoading = false
                }
            },
            searchPharmacies: _.debounce(async function (page=1, pageSize=5) {
                if (!this.search) return

                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/pharmacies/search`, {
                        params: {
                            query: this.search,
                            query_type: this.pharmacyCategory,
                            page,
                            page_size: pageSize
                        }
                    });
                    if (response.data.status == 500) throw new Error();
                    this.searchResults = response.data.results;
                    this.searchResultsCount = response.data.total_results;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize
                } catch {
                    this.alertError()
                } finally {
                    this.isLoading = false
                }
            }, 200),
            onOptionsChange(options) {
                const page = options.page;
                const sortBy = options.sortBy[0];
                const sortAsc = options.sortDesc[0] ? -1 : 1;
                const itemsPerPage = options.itemsPerPage;
                if (!this.search) {
                    this.fetchPharmacies(this.pharmacyCategory, page, sortBy, sortAsc, itemsPerPage)
                } else {
                    this.searchPharmacies(page, itemsPerPage)
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        computed: {
            pharmacies() {
                if (this.search) return this.searchResults
                if (this.pharmaciesForPage) {
                    return this.pharmaciesForPage;
                }
                return []
            },
            pharmacyCategory() {
                return this.tab;
            }
        },
        watch: {
            search(text) {
                if (!text.trim().length) {
                    this.searchResults = [];
                    return
                }
                this.searchPharmacies()
            },
        },
        async mounted() {
            await this.fetchPharmacyCount(this.pharmacyCategory);
        }
    }
</script>

<style scoped>
    .pharmacies-overview {
        margin-top: 20px
    }
</style>