var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass: "vuetify-wrapper",
      attrs: { "data-id": _vm.subscribedPharmacy?.pharmacyId },
    },
    [
      _c("td", [
        _c(
          "a",
          {
            attrs: {
              href: `/pharmacies/${_vm.subscribedPharmacy?.pharmacyId}`,
              target: "_blank",
            },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.subscribedPharmacy?.pharmacy) + "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "flex-box" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-unlink",
              staticStyle: { margin: "0 8px" },
              attrs: { small: "", depressed: "", color: "error" },
              on: { click: _vm.unlinkPharmacy },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-trash-can-outline"),
              ]),
              _vm._v("Verknüpfung aufheben\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }