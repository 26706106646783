<template>
    <span class="vuetify-wrapper">
        <v-btn
            :disabled="parsedDisabled"
            @click="isAvailablepharmacistsDialogOpen = true"
            min-width="40"
            class="fb-button"
            outlined
            text
            small
            :icon="isjinja2 ? true : false"
            :class="{'bootstrap-button': isjinja2}"
            title="Verfügbare Freiberufler vorschlagen"
        >
            <v-icon size="20">mdi-account</v-icon>
        </v-btn>

        <AvailablePharmacistsDialog
            v-model="isAvailablepharmacistsDialogOpen"
            :pharmacy="typeof pharmacy === 'string' ? JSON.parse(pharmacy) : pharmacy"
            :appointment="typeof appointment === 'string' ? JSON.parse(appointment) : appointment"
            :services="typeof services  === 'string' ? JSON.parse(services) : services"
            :date="formatDate(date)"
            :wfState="wfState"
            @selected-user="onPharmacistSelected"
        />
    </span>


</template>


<script>
    import { parseBool } from '../../helpers.js'

    import AvailablePharmacistsDialog from './AvailablePharmacistsDialog.vue';

    export default {
        name: 'AvailablePharmacistsDialogButton',
        components: { AvailablePharmacistsDialog },
        props: {
            wfState: String,
            pharmacy: Object,
            appointment: Object,
            services: Array,
            date: String,
            isjinja2: {
                type: Boolean,
                default: false
            },
            disabled: {
                default: false,
                type: Boolean,
            },
        },

        data: () => ({
            isAvailablepharmacistsDialogOpen: false,

        }),
        computed: {
            /** Required for jinja2 compatibility */
            parsedDisabled() {
                return parseBool(this.disabled);
            },
            /** Required for jinja2 compatibility */
            parsedHidden() {
                return parseBool(this.is_appointment_hidden);
            },
        },
        methods: {
            onPharmacistSelected(pharmacist) {
                if (!this.isjinja2) {
                    this.$emit('selected-user', pharmacist);
                } else {
                    let appointmentObj = typeof this.appointment === 'string' ? JSON.parse(this.appointment) : this.appointment;

                    // copied from apps/admin/flyingpharmacists/js/available_dates.js line 276 2024-04-11
                    var row = $('tr[data-id=' + appointmentObj._id + ']');
                    console.log("row", row);
                    $(row)
                        .find('.pharmacist-select')
                        .val(pharmacist._id);
                    // disable all select fields
                    $(row)
                        .find('.usersearch')
                        .each(function() {
                            return $(this).val(null).prop('disabled', true);
                        });
                    // set name to according to service input (select) field
                    $(row)
                        .find('.usersearch[data-service=' + pharmacist.display_services_full[0].service + ']')
                        .prop('disabled', false)
                        .val(`${pharmacist.lastname.trim()}, ${pharmacist.firstname.trim()}`)
                        .focus()
                        .change();
                }
            },
            formatDate(date) {
                if (!date) return ''
                return moment(date).format('DD-MM-yyyy')
            },
        },
    }
</script>

<style scoped>
    .fb-button {
        padding: 0 !important;
        margin-left: 10px;
    }
    .bootstrap-button {
        /** Make it look the same as the bootstrap buttons */
        border-radius: 3px;
        border-color: #ccc;
        width: 34px !important;
        height: 30px !important;
        color: #333 !important;
    }
</style>