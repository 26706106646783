<template>
    <div class="panel panel-default">
        <div class="panel-heading" v-if="title">
            <h3 class="panel-title">{{ title }}</h3>
        </div>
        <div class="panel-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-7">
                        <label class="control-label">Trennzeichen: &nbsp;</label>
                        <label class="radio-inline">
                            <input type="radio" value="," v-model="listDelimiter" />
                            Komma
                        </label>
                        <label class="radio-inline">
                            <input type="radio" :value="'\n'" v-model="listDelimiter" />
                            Neue Zeile
                        </label>
                    </div>
                    <div class="col-md-5">
                        <button type="button" class="btn btn-default btn-sm pull-right" @click="onCopyToClipboard">
                            In die Zwischenablage kopieren
                        </button>
                    </div>
                </div>
            </div>
            <textarea class="form-control" rows="20" v-model="joinedList"></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClipboardList',
    props: {
        title: String,
        values: Array,
        delimiter: String,
        restrict: String,
    },
    data: function() {
        return {
            listDelimiter: this.delimiter || ',',
            list: this.values ? JSON.parse(this.values) : [],
        };
    },
    computed: {
        joinedList: {
            get: function() {
                return this.filteredList.join(this.listDelimiter);
            },
            set: function(newValue) {
                this.list = newValue.split(this.listDelimiter);
            },
        },
        filteredList() {
            switch (this.restrict) {
                case 'email':
                    return this.list.map(function(item) {
                        const v = item.trim().split(' ')[0];
                        if (v && v.includes('@')) return v;
                    });
                default:
                    return this.list;
            }
        },
    },
    methods: {
        onCopyToClipboard: function(e) {
            this.$el.querySelector('textarea').select();
            document.execCommand('copy');
        },
    },
};
</script>
