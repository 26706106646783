<template>
  <v-dialog
    v-model="isDialogOpen"
    content-class="v-application v-application--is-ltr"
    max-width="700px"
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
  >
    <v-card :loading="isLoading && 'white'" class="overflow-auto">
      <v-card-title>
        <h3 style="padding: 1rem">Verlinkte Apotheke</h3>
      </v-card-title>
      <v-card-text>
        <div class="container py-12">
          <div>
            <div class="new-subscription">
              <v-autocomplete
                chips
                label="Suche Apotheke"
                v-model="selectedPharmacy"
                :items="searchResults"
                @update:search-input="searchPharmacies"
                no-filter
                no-data-text="Geben Sie ein, um Suchergebnisse zu erhaltenKeine Suchergebnisse"
              >
              <template v-slot:item="{ item }" class="pharmacy-option">
                <div
                    class="v-application"
                    data-role="pharmacy-option"
                    :data-pharmacy-id="item.pharmacy._id"
                >
                    <div class="font-weight-medium mt-2 no-margin pharmacy-name">
                        {{item.text }} ({{item.pharmacy.city}})
                    </div>
                </div>
            </template>
            </v-autocomplete>
              <v-btn
                :disabled="!selectedPharmacy"
                id="link-pharmacy-btn"
                @click="submitForm">
                Apotheke verlinken
              </v-btn>
            </div>
            <div class="subscribed-pharmacies">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="vuetify-wrapper">
                      <th>Apotheke</th>
                      <th class="text-center">Aktionen</th>
                    </tr>
                  </thead>
                  <tbody>
                      <linked-pharmacy-table-row
                        v-for="(item) in subscribedpharmacies"
                        :subscribedPharmacy="item"
                        :discountId="value?._id"
                        @refresh="$emit('refresh')"
                      />
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" text @click="isDialogOpen=false"> ohne Auswahl schließen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import LinkedPharmacyTableRow from "./LinkedPharmacyTableRow.vue";
export default {
  components: {
    "linked-pharmacy-table-row": LinkedPharmacyTableRow,
  },
  props: {
    value: Object,
    isLinkedPharmacyDialogOpen: Boolean
  },
  data: () => ({
    isLoading: false,
    selectedPharmacy: null,
    searchResults: []
  }),
  computed: {
    isDialogOpen: {
        get() {
          return this.isLinkedPharmacyDialogOpen;
        },
        set(state) {
          this.$emit("update:isLinkedPharmacyDialogOpen", state);
        },
    },
    subscribedpharmacies() {
      const pharmacies = this.value.subscribedPharmacies || {};
      return Object.keys(pharmacies).map((key) => ({ pharmacy: pharmacies[key], pharmacyId: key }));
    },
  },
  methods: {
    searchPharmacies: _.debounce(async function (search) {
      if (!search) return;
      try {
        this.isLoading = true;
        const response = await axios.get(`/api/pharmacies/search`, {
          params: {
            query: search,
            page: 1,
            page_size: 10,
          },
        });
        if (response.data.status >= 400) throw new Error();
        const subscribed = this.value.subscribedPharmacies;
        const pharmacies = response.data.results
                            .map((pharmacy) => ({ text: pharmacy.name, value: pharmacy._id, pharmacy: pharmacy }))
                            .filter((pharmacy)=> !subscribed[pharmacy.value]);
        this.searchResults = pharmacies;
      } catch (error) {
        console.log(error);
        this.alertError();
      } finally {
        this.isLoading = false;
      }
    }, 200),
    submitForm() {
      this.$emit('submit', this?.value?._id, this.selectedPharmacy);
      this.selectedPharmacy = null;
    },
    ...mapMutations({
      alertError: "alert/error",
      alertSuccess: "alert/success",
    }),
  },
};
</script>

<style scoped>
.container {
  width: 100%;
}
.new-subscription {
  display: grid;
  grid-template-columns: 8fr 4fr;
  align-items: center;
  gap: 0.5rem;
}
.text-center {
  text-align: center;
}
</style>
