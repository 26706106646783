var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          class: { "btn-primary": true, "bootstrap-button": _vm.icon },
          attrs: {
            loading: _vm.loading,
            disabled: _vm.appointmentIds.length == 0,
            small: _vm.icon,
            icon: _vm.icon,
            outlined: _vm.icon,
            dense: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleDialog.apply(null, arguments)
            },
          },
        },
        [
          _vm.icon
            ? _c("v-icon", { attrs: { small: "" } }, [_vm._v(_vm._s(_vm.icon))])
            : _c("span", [
                _vm._v(
                  "\n            Update " +
                    _vm._s(_vm.billingString) +
                    "\n        "
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class":
              "v-application v-application--is-ltr vuetify-wrapper",
            width: "auto",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(
                  "\n                Update " +
                    _vm._s(_vm.billingString) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.loading
                ? _c("v-card-text", [
                    _vm._v("\n                Lade Preise...\n            "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.rateTypes.size > 1
                ? _c("v-card-text", [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("strong", [_vm._v("Warnung!")]),
                        _vm._v(
                          " Bitte wählen Sie nur Termine aus, die entweder nur nach Stunden\n                    bzw.\n                    nur pauschal abgerechnet werden.\n                "
                        ),
                      ]
                    ),
                  ])
                : _c("v-card-text", [
                    _c("p", { staticClass: "description" }, [
                      _vm._v(
                        "\n                    Hier kann ausgewählt werden, ob dem Freiberufler/dem Apothekeninhaber die hier angegebenen\n                    Preise angezeigt werden sollen oder die am Termin gespeicherten 2023-Pricing Preise. Sollte\n                    letzteres ausgewählt sein, diese aber noch nicht existieren, wird dem Freiberufler angezeigt,\n                    dass die Preise bei FPP angefragt werden können.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "wrapper" }, [
                      _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Apotheke")]),
                          _vm._v(" "),
                          _c("v-switch", {
                            attrs: {
                              label: "2023-Preisvorschläge verwenden",
                              disabled: _vm.disable2023Pricing,
                            },
                            model: {
                              value: _vm.apo2023Pricing,
                              callback: function ($$v) {
                                _vm.apo2023Pricing = $$v
                              },
                              expression: "apo2023Pricing",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.apo2023Pricing
                            ? _c("v-text-field", {
                                attrs: {
                                  label: _vm.billingString,
                                  hint: _vm.apoRatesHint,
                                  "persistent-hint":
                                    _vm.availableApoRates.size > 1,
                                  type: "number",
                                  rules: [_vm.validateCurrency],
                                },
                                model: {
                                  value: _vm.apoRate,
                                  callback: function ($$v) {
                                    _vm.apoRate = $$v
                                  },
                                  expression: "apoRate",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Freiberufler")]),
                          _vm._v(" "),
                          _c("v-switch", {
                            attrs: {
                              label: "2023-Preisvorschläge verwenden",
                              disabled: _vm.disable2023Pricing,
                            },
                            model: {
                              value: _vm.ma2023Pricing,
                              callback: function ($$v) {
                                _vm.ma2023Pricing = $$v
                              },
                              expression: "ma2023Pricing",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.ma2023Pricing
                            ? _c("v-text-field", {
                                attrs: {
                                  label: _vm.billingString,
                                  hint: _vm.maRatesHint,
                                  "persistent-hint":
                                    _vm.availableMaRates.size > 1,
                                  type: "number",
                                  rules: [_vm.validateCurrencyFb],
                                },
                                model: {
                                  value: _vm.maRate,
                                  callback: function ($$v) {
                                    _vm.maRate = $$v
                                  },
                                  expression: "maRate",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "abort", text: "" },
                      on: { click: _vm.cancelDialog },
                    },
                    [
                      _vm._v(
                        "\n                    Abbrechen\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "submit",
                        text: "",
                        loading: _vm.loading,
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.submitDialog },
                    },
                    [
                      _vm._v(
                        "\n                    Speichern\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }