import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { alert } from './alert'


Vue.use(Vuex)


export const store = new Vuex.Store({
    state: {
        selectedRowIds: [],
        adminUsers: []
    },
    getters: {
        selectedRowIdsJson: function(state) {
            return JSON.stringify(state.selectedRowIds)
        },
        adminUsersOptions(state) {
            const users = state.adminUsers.map(user => {
                return {
                    ...user,
                    text: `${user.firstname} ${user.lastname} ${user.current_user ? '(mich)' : ''}`,
                    value: user._id
                }
            })
            users.sort(user => user.current_user ? -1 : 0).unshift({
                text: '- nicht zugewiesen -',
                value: 'unassigned',
                _id: null
            })
            return users
        },
    },
    mutations: {
        setSelectedRowIds: function (state, value) {
            state.selectedRowIds = value
        },
        addOrRemoveSelectedRowId: function(state, value) {
            var valueIndex = state.selectedRowIds.indexOf(value)
            if (valueIndex === -1) {
                state.selectedRowIds.push(value)
            } else {
                state.selectedRowIds.splice(valueIndex, 1)
            }
        },
        setAdminUsers(state, adminUsers) {
            Vue.set(state, 'adminUsers', adminUsers)
        }
    },
    actions: {
        async fetchAdminUsers({ commit }) {
            try {
                const response = await axios.get('/api/contractors/admins');
                commit('setAdminUsers', response.data);
            } catch {
                commit('alert/error')
            }
        },
    },
    modules: {
        alert
    }
});

store.dispatch('fetchAdminUsers');
