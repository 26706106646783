
$.fn.invoicemail = function() {

    var init = function() {
        $('#send_mail_form_modal').on('show.bs.modal', get_mail_data);
    };

    var get_mail_data = function(e) {
        $.ajax({
            url: $(e.relatedTarget).data('url'),
            type: "GET",
            success: data => {
                $(this).find('form[name=crm-mail-form]').attr('action', data.action);
                $(this).find('.modal-body #modal-email').text(data.email);
                $(this).find('.modal-body input[name=email]').val(data.email);
                $(this).find('.modal-body #subject').val(data.subject);
                $(this).find('.modal-body #msg').val(data.msg);
            }
        });
    };

    $(this).each(init);
    return this;
}
