<template>
    <div class="vuetify-wrapper">
        <v-btn
            @click.stop="toggleDialog"
            :loading="loading"
            small
            icon
            outlined
            class="bootstrap-button"
        >
            <v-icon small color="black">mdi-currency-eur</v-icon>
        </v-btn>
        <v-dialog
            content-class="vuetify-wrapper"
            v-model="dialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Preisvorschlag
                </v-card-title>
                <v-card-text v-if="loading">
                    Preisvorschlag wird berechnet...
                </v-card-text>
                <v-card-text v-else-if="!pricingInformation">
                    Fehler bei der Preisberechnung
                </v-card-text>
                <v-card-text v-else-if="isError">
                    Fehler bei der Preisberechnung: {{ errorMessage }}
                </v-card-text>
                <v-card-text v-else>
                    <table class="table">
                        <thead>
                            <th>Service</th>
                            <th>Apo</th>
                            <th>FB</th>
                            <th>FPP</th>
                            <th>Erklärung</th>
                        </thead>
                        <tbody>
                            <tr v-for="config in pricingInformation.configs" :class="config.service">
                                <td>{{ $t(config.service) }}</td>
                                <td class="apo">{{ config.total.pharmacy }}€</td>
                                <td class="fb">{{ config.total.pharmacist }}€</td>
                                <td class="fpp">{{ config.margin }}€</td>
                                <td>{{ config.explanation }}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    Disagree
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    Agree
                </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
    name: 'PriceSuggestion',
    components: { },
    props: {
        pharmacyid: String,
        appointmentid: String,
    },
    data: function() {
        return {
            loading: false,
            pricingInformation: null,
            dialog: false,
        };
    },
    computed: {
        isError() {
            return Boolean(this.pricingInformation?.error);
        },
        errorMessage() {
            return this.pricingInformation?.error
        },
    },
    methods: {
        async toggleDialog() {
            this.dialog = !this.dialog;
            if (this.dialog) {
                await this.suggestPrice();
            }
        },
        async suggestPrice() {
            this.loading = true;
            this.pricingInformation = null;
            try {
                const response = await axios.get(`/api/pricing/${this.pharmacyid}/${this.appointmentid}`);
                this.pricingInformation = response.data;
            }
            catch(error) {
                this.alertError();
                console.log("! failed to retrieve pricing information")
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    async mounted() {
    },
};
</script>

<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}
.black--text {
    color: black;
}
</style>
