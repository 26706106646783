<template>
    <div :id="tab">
        <v-card class="v-application appointments-overview">
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    placeholder="Suche nach Datum, Name der Apotheke"
                    class="search-appointments"
                    single-line
                    hide-details
                    clearable
                    autofocus
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="tableRows"
                class="v-application--is-ltr"
                mobile-breakpoint="670"
                :items-per-page="25"
                :page.sync="pageNumber"
                :options.sync="tableOptions"
                no-data-text="Keine Suchergebnisse"
                @update:options="onOptionsChange"
                :server-items-length="appointmentsCount"
                :loading="isLoading"
                loading-text="Wird geladen..."
                show-expand
                :expanded.sync="expanded"
                item-key="_id"
                :footer-props="{'items-per-page-options':[5, 10, 25, 50], 'show-first-last-page': true, 'show-current-page': true, 'items-per-page-text': 'Zeilen pro Seite'}"
                disable-sort
            >
                <template v-slot:item="{ item, expand, isExpanded, }">
                    <table-row
                        :appointment="item"
                        :expand="expand"
                        :isExpanded="isExpanded"
                        :wfState="tab"
                        :groupAppointments="groups[item.group_id]"
                        @update-group-travelcosts="({appointmentId, travelcosts}) => updateGroupTravelcosts(item.group_id, appointmentId, travelcosts)"
                        @update-appointments="updateAppointments"
                    />
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="group-container">
                        <v-data-table
                            :headers="headers"
                            :items="groups[item['group_id']]"
                            hide-default-header
                            hide-default-footer
                        >
                            <template v-slot:item="{ item, expand, isExpanded, }">
                                <table-row
                                    :appointment="item"
                                    :expand="expand"
                                    :isExpanded="isExpanded"
                                    :wfState="tab"
                                />
                            </template>
                        </v-data-table>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import _ from 'lodash';
    import TableRow from './TableRow.vue';
    import { mapMutations } from 'vuex';

    export default {
        components: {
            'table-row': TableRow,
        },
        props: {
            tab: String,
            count: Number,
            sort: Object,
            filters: Object,
        },
        data: () => ({
            search: '',
            pageNumber: 1,
            expanded: [],
            tableOptions: {},
            headers: [
                { text: '', value: 'data-table-expand' },
                { text: 'Datum', value: 'date' },
                { text: 'bis Datum', value: 'group_enddate' },
                { text: 'Tage', value: 'group_size' },
                { text: 'Apotheke', value: 'pharmacy' },
                { text: 'Zeit', value: 'time' },
                { text: 'Freiberufler', value: 'pharmacist' },
                { text: 'Anfahrt', value: 'travelcosts' },
                { text: 'Betrag', value: '', width: 150 },
                { text: '', value: 'buttons', width: 150 },
            ],
            groups: {},
            appointments: [],
            appointmentsCount: 0,
            isLoading: false,
            isFilterChanged: false
        }),
        computed: {
            tableRows() {
                return this.mapAppointments();
            },
        },
        methods: {
            mapAppointments() {
                return this.appointments.map(item => {
                    if (Array.isArray(item)) { // If the item is an array, it means it's a group appointments
                        this.$set(this.groups, item[0].group_id, item.slice(1));
                        return item[0];
                    }
                    return item;
                });
            },
            async onOptionsChange({ page, itemsPerPage }) {
                if (this.isFilterChanged) this.fetchFilteredAppointments(page, itemsPerPage);
                else if (this.search) this.searchAppointments(page, itemsPerPage)
                else this.fetchAppointments(page, itemsPerPage);

                this.$nextTick(() => {
                    window.scrollTo(0, 0);
                })
            },

            async fetchAppointments(page = 1, pageSize = 25, sort_by = 'date', sort_asc = 1) {
                try {
                    this.isLoading = true;
                    const response = await axios.get('/api/appointments/overview', {
                        params: { page, page_size: pageSize, sort_by, sort_asc },
                    });
                    this.appointments = response.data.appointments[this.tab];
                    this.appointmentsCount = this.count;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            },

            searchAppointments: _.debounce(async function (page = 1, pageSize = 25) {
                if (!this.search) return;
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/appointments/searchoverview`, {
                        params: {
                            query: this.search,
                            page: page - 1, // solr pagination is zero-indexed
                            page_size: pageSize,
                            wf_state: this.tab == 'completed_no_apo_invoice' ? 'allocated' : this.tab,
                        },
                    });
                    if (response.data.status == 500) throw new Error();

                    const data = response.data.results;
                    const appointmentsSearchResults = [];

                    for (const item of data) {
                        const response = await axios.get(
                            `/api/appointments/fetch_one_appointment?appointment_id=${item._id}`
                        );
                        appointmentsSearchResults.push(response.data);
                    }
                    this.appointments = appointmentsSearchResults;
                    this.appointmentsCount = response.data.total_results;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            }, 200),

            async fetchFilteredAppointments(page = 1, pageSize = 25) {
                const params = {
                    page,
                    page_size: pageSize,
                    wf_state: this.tab,
                    ...this.filters
                };
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/appointments/filtered`, { params });
                    this.appointments = response.data.appointments;
                    this.appointmentsCount = response.data.total_count;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            },
            updateGroupTravelcosts(groupId, appointmentId, travelcosts) {
                this.groups[groupId].forEach(appointment => {
                    if (appointment._id == appointmentId) {
                        appointment.travelcosts = travelcosts;
                    }
                })
            },
            /**
             * Updates already fetched and possibly currently displayed appointments
             * with the updated values in `appointments`.
             * Intended to be used with buttons/user interactions that change an appointments state
             * and return the updated appointments, so that we can update these appointments
             * without having to fetch the entire list again, making the user experience smoother.
             */
            updateAppointments(appointments) {
                const replaceAppointment = (appointmentList, appointmentToUpdate) => {
                    const i = appointmentList.findIndex(a => a._id == appointmentToUpdate._id);
                    if (i != -1) {
                        // merge old and new appointment to keep additional fields like `pharmacy`
                        Vue.set(appointmentList, i, { ...appointmentList[i], ...appointmentToUpdate });
                    }
                }
                const replaceAppointmentConsideringGroups = (appointmentList, appointmentToUpdate) => {
                    replaceAppointment(appointmentList, appointmentToUpdate);
                    // handle appointment-groups, which are stored as arrays
                    appointmentList
                        .filter(Array.isArray)
                        .forEach((appois) => replaceAppointment(appois, appointmentToUpdate));
                }

                appointments.forEach(appointment => {
                    // appointment could be in any/all of these arrays:
                    replaceAppointmentConsideringGroups(this.appointments, appointment);
                });
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        watch: {
            search(text) {
                text?.trim().length
                ? this.searchAppointments()
                : this.fetchAppointments()
            },
            filters: {
                handler(allFilters) {
                    const isFilterChanged = Object.values(allFilters).some(filter => filter);
                    this.isFilterChanged = isFilterChanged;
                    isFilterChanged
                    ? this.fetchFilteredAppointments()
                    : this.fetchAppointments()
                },
                deep: true
            },
            sort: {
                handler({ sortBy, sortAsc }) {
                    const { page, itemsPerPage } = this.tableOptions;
                    !sortBy
                    ? this.fetchAppointments(page, itemsPerPage)
                    : this.fetchAppointments(page, itemsPerPage, sortBy, sortAsc);
                },
                deep: true
            },
        },
    };
</script>

<style>
    .appointments-overview {
        margin-top: 20px;
    }
    .group-container {
        border-left: 1px solid #6b6b6b;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    .appointments-overview .v-data-footer {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background: white;
    }
    .travelcost-warning fieldset {
        color: #fb8c00 !important;
        caret-color: #fb8c00 !important;
        border: 2px solid !important;
}
</style>
