<template>
    <div id="field-erp-exprtise" class="form-group ">
        <label for="erp-exprtise" class="control-label col-md-2">WWS</label>
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-1">
                    <small><b>nicht bekannt</b></small>
                </div>
                <div class="col-md-2">
                    <small><b>Ich hab das Programm noch nie gehabt.</b><br>Ich komme aber schnell rein.</small>
                </div>
                <div class="col-md-3">
                    <small><b>Ich habe schon mit dem Programm gearbeitet.</b><br>Ich erledige einen normalen Abverkauf und Rezeptbedienung mit Rabattartikeln. Bei anspruchsvolleren Vorgängen komme ich nach einer kurzen Zeit wieder rein.</small>
                </div>
                <div class="col-md-3">
                    <small><b>Ich habe oft mit dem Programm gearbeitet und kenne mich aus.</b><br>Ich erledige auch detailliertere Vorgänge wie Stornierung, Abholer, Schicker etc.</small>
                </div>
            </div>
            <div class="row" v-for="erp in erps_list" :key="erp">
                <div class="col-md-3">{{ erp }}</div>
                <div :class="'col-md-'+col_widths[i]+' text-center'"
                    v-for="i in ['0','1','2','3']" :key="i">
                    <input type="radio" :value="i" v-model="form[erp]">
                </div>
            </div>
        </div>
        <input type="hidden" name="erp_expertise" v-model="formJSON">
    </div>
</template>

<script>
export default {
    name: 'ErpExpertiseInput',
    props: ['erps', 'value'],
    data: function () {
        return {
            erps_list: JSON.parse(this.erps),
            form: JSON.parse(this.value),
            col_widths: [1, 2, 3, 3]
        }
    },
    computed: {
        formJSON: function () {
            return JSON.stringify(this.form)
        }
    }
}
</script>
