<template>
    <div class="vuetify-wrapper v-application">
        <span class="v-application">
            <h2 class="my-5">Apotheken</h2>
        </span>
        <v-tabs v-model="tabNumber" v-if="!loading" grow slider-color="primary">
            <v-tab
                v-for="tab in tabs"
                :key="tab.value"
            >
                {{ tab.text }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabNumber">
            <v-tab-item
                v-for="tab in tabs"
                :key="tab.value"
            >
                <pharmacies-overview-table :tab="tab.value" />
             </v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
    export default {
        props: {},
        data: () => ({
            tabNumber: 0,
            tabs: [{
                text: 'Aktive Apotheken',
                value: 'active'
            },
            {
                text: 'Inaktive Apotheken',
                value: 'inactive'
            },
            {
                text: 'Angefragte Apotheken',
                value: 'inquired'
            }],
        }),
    }
</script>

<style>
    .vuetify-wrapper th {
        vertical-align: middle;
    }
</style>
