var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "v-application pharmacies-overview" },
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Suche nach Name, Adresse, Kundennummer etc.",
                  "single-line": "",
                  "hide-details": "",
                  clearable: "",
                  autofocus: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "v-application--is-ltr",
            attrs: {
              headers: _vm.headers,
              items: _vm.pharmacies,
              "mobile-breakpoint": "670",
              page: _vm.pageNumber,
              "no-data-text": _vm.search
                ? "Keine Suchergebnisse"
                : "Keine Apotheken in dieser Kategorie",
              options: _vm.tableOptions,
              loading: _vm.isLoading,
              "loading-text": "Wird geladen...",
              "server-items-length": _vm.search
                ? _vm.searchResultsCount
                : _vm.pharmacyCount,
              "items-per-page": 5,
              "footer-props": {
                "items-per-page-options": [5, 10, 25, 50],
                "show-first-last-page": true,
                "show-current-page": true,
                "items-per-page-text": "Zeilen pro Seite",
              },
            },
            on: {
              "update:page": function ($event) {
                _vm.pageNumber = $event
              },
              "update:options": [
                _vm.onOptionsChange,
                function ($event) {
                  _vm.tableOptions = $event
                },
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "item.name",
                fn: function ({ item }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "name",
                        attrs: { href: `/pharmacies/${item._id}` },
                      },
                      [
                        _vm._v(
                          _vm._s(item.name) +
                            _vm._s(item.shortname && ` (${item.shortname})`)
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/users/admin/?city=${item.zip}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "blue-grey" } },
                          [
                            _vm._v(
                              "\n                        mdi-earth\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", { staticClass: "text--secondary" }, [
                      _vm._v(_vm._s(item.owner_firstname)),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text--secondary" }, [
                      _vm._v(_vm._s(item.owner_lastname)),
                    ]),
                  ]
                },
              },
              {
                key: "item.created",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatDate(item.created)) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "item.city",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(item.city) + _vm._s(", " + item.zip)),
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text--secondary" }, [
                      _vm._v(_vm._s(item.street)),
                    ]),
                  ]
                },
              },
              {
                key: "item.open_appointments_count",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "ml-lg-12 ml-md-7 open-appointments-count",
                        attrs: { "data-id": item._id },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.open_appointments_count) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.action_buttons",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        item.sepa_auth
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "my-2",
                                attrs: {
                                  href: `/pharmacies/${item._id}/sepa_download`,
                                  target: "_blank",
                                  small: "",
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-download"),
                                ]),
                                _vm._v(
                                  "\n                        SEPA-Mandat\n                    "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "my-2 ml-5",
                            attrs: {
                              small: "",
                              href: `/pharmacies/${item._id}/edit`,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-pencil"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }