var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper v-application" },
    [
      _c(
        "v-row",
        [
          _vm.locationSearchParam
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-alert",
                    { attrs: { outlined: "", color: "warning", text: "" } },
                    [
                      _c("span", [
                        _vm._v("\n                    Suchergebnisse von "),
                        _c("b", [_vm._v(_vm._s(_vm.locationSearchParam))]),
                        _vm._v(" mit dem Radius\n                "),
                      ]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        staticClass: "radius-input",
                        attrs: {
                          type: "number",
                          min: "0",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.radius,
                          callback: function ($$v) {
                            _vm.radius = $$v
                          },
                          expression: "radius",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("km für aktive Freiberufler.")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.locationSearchParam
            ? _c(
                "v-col",
                {
                  staticClass: "v-application v-application--is-ltr",
                  attrs: { cols: "3" },
                },
                [
                  _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      items: _vm.userTypes,
                      label: "Freiberufler Status",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.selectedUserType,
                      callback: function ($$v) {
                        _vm.selectedUserType = $$v
                      },
                      expression: "selectedUserType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "v-application pharmacists-overview" },
        [
          !_vm.locationSearchParam
            ? _c(
                "v-card-title",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Suche nach Name, Adresse, Kundennummer etc.",
                      "single-line": "",
                      "hide-details": "",
                      clearable: "",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "v-application--is-ltr",
            attrs: {
              headers: _vm.tableHeader,
              items: _vm.pharmacists,
              options: _vm.tableOptions,
              "mobile-breakpoint": "992px",
              "no-data-text": "Keine Suchergebnisse",
              loading: _vm.isLoading,
              "loading-text": "Wird geladen...",
              "server-items-length": _vm.count,
              "items-per-page": 5,
              "footer-props": {
                "items-per-page-options": [5, 10, 25, 50],
                "show-first-last-page": true,
                "show-current-page": true,
                "items-per-page-text": "Zeilen pro Seite",
              },
            },
            on: {
              "update:options": [
                function ($event) {
                  _vm.tableOptions = $event
                },
                _vm.onOptionsChange,
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "item.avatar",
                fn: function ({ item }) {
                  return [
                    _c("v-avatar", { attrs: { size: "40" } }, [
                      _c(
                        "object",
                        {
                          attrs: {
                            data: `/media/avatar/${item._id}/small`,
                            type: "image/jpg",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "40" } }, [
                            _vm._v("mdi-account-circle"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.lastname",
                fn: function ({ item }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "name",
                        attrs: {
                          href: `/pharmacists/${item._id}`,
                          "data-itemid": item._id,
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.lastname) +
                            ", " +
                            _vm._s(item.firstname) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/pharmacies/?city=${item.billing_zip}`,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "blue-grey" } },
                          [_vm._v("mdi-earth")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/appointment_offer?userid=${item._id}&perimeter=100`,
                          target: "_blank",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "blue-grey" } },
                          [_vm._v("mdi-magnify")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.customer_id))]),
                    _vm._v(" "),
                    _vm._l(item.services, function (service) {
                      return _c(
                        "v-chip",
                        {
                          staticClass: "mr-1 px-2",
                          attrs: {
                            color: _vm.renderServiceColor(service),
                            dark: "",
                            "x-small": "",
                            label: "",
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                service == "substitution"
                                  ? "Apotheker/in"
                                  : service.toUpperCase()
                              )
                            ),
                          ]),
                        ]
                      )
                    }),
                  ]
                },
              },
              {
                key: "item.created",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatDate(item.created)) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "item.billing_city",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.billing_city) +
                        _vm._s(", " + item.billing_zip) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "item.readable_permissions",
                fn: function ({ item }) {
                  return _vm._l(
                    _vm.readablePermissions(item.readable_permissions),
                    function (permission) {
                      return _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(permission) +
                            "\n                "
                        ),
                      ])
                    }
                  )
                },
              },
              {
                key: "item.action_buttons",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "action-buttons",
                        attrs: { "data-itemid": item._id },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              href: `/users/admin/${item._id}`,
                              color: "cyan lighten-3",
                              dark: "",
                              small: "",
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-pencil"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !item.active
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "green lighten-1",
                                  small: "",
                                  disabled: _vm.activatedUserIds.includes(
                                    item._id
                                  ),
                                  dark: !_vm.activatedUserIds.includes(
                                    item._id
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.activatePharmacist(item._id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Aktivieren\n                    "
                                ),
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass: "reset-password",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendPassword(item._id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Sende passwort\n                    "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.distance",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "distance",
                        attrs: { "data-id": item._id },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(Math.round(item.distance)) +
                            " km\n                "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }