import debounce from 'lodash.debounce';

/**
 * Escapes " and '
 * Useful for inserting strings with quotes in xpath
 */
function escapeQuotes(str) {
    return str.replace(/"/g, '\\"').replace(/'/g, "\\'");
}

/**
 * Escapes unsafe strings to safe HTML.
 * @author https://stackoverflow.com/a/48054293/5124673
 */
function escapeHTML(unsafeText) {
    let div = document.createElement('div');
    div.innerText = unsafeText;
    return div.innerHTML;
}

(function () {
    $.fn.filtersearch = function (opts) {
        var init, items, that;
        if (opts == null) {
            opts = {};
        }
        that = this;
        items = [];

        function updateSearchResults() {
            const query = $(that).val();
            const filter = query.trim().split(/\s+/).map(q => '[data-item*="' + q + '"i]').join('');
            $('.table tbody tr:not(' + filter + ')').css({ display: 'none' });
            $('.table tbody tr' + filter).css({ display: '' });
            $('.hidden-block').show();
            $('.table').removeClass('is-loading');
        }

        init = function () {
            $(this).on(
                'keyup',
                function () {
                    $('.table').addClass('is-loading');
                }
            );
            $(this).on(
                'keyup',
                debounce(updateSearchResults, 200)
            );
            return $('#inputreset').click(function () {
                $(that).val('');
                $('.hidden-block').hide();
                return $(that).trigger('keyup');
            });
        };
        $(this).each(init);
        return this;
    };

    $.fn.filtersearchadvanced = function (initial_state) {
        var filter_state, init, input, items, select, state, value;
        if (initial_state == null) {
            initial_state = null;
        }
        items = [];
        input = null;
        select = null;
        state = null;
        value = '';
        init = function () {
            state = initial_state;
            value = '';
            input = $('input#filter-search');
            select = $('select#filter-wfstate');

            function updateSearchResults() {
                value = $(that).val();
                const valueFilter = value.trim().split(/\s+/).map(q => '[data-item*="' + q + '"i]').join('');
                const wfStateFilter = state !== 'all' ? '[data-wfstate="' + state + '"i]' : '';
                $('.table tbody tr:not(' + valueFilter + wfStateFilter + ')').css({ display: 'none' });
                $('.table tbody tr' + valueFilter + wfStateFilter).css({ display: '' });
                $('.hidden-block').show();
                $('.table').removeClass('is-loading');
            }

            $(input).on(
                'keyup',
                function () {
                    $('.table').addClass('is-loading');
                }
            );
            $(input).on(
                'keyup',
                debounce(updateSearchResults, 200)
            );
            $('#inputreset').click(function () {
                value = '';
                $(input).val('');
                return $(input).trigger('keyup');
            });
            return $(select).on('change', function () {
                state = $(this).val();
                updateSearchResults();
            });
        };
        $(this).each(init);
        return this;
    };

    $.fn.geosearch = function (opts) {
        var init;
        if (opts == null) {
            opts = {};
        }
        init = function () {
            return $(this).typeahead({
                name: 'geosearch',
                source: $(this).data('cities'),
                limit: 9999999,
            });
        };
        $(this).each(init);
        return this;
    };

    $.fn.usersearch = function (opts) {
        var init;
        if (opts == null) {
            opts = {};
        }

        init = function () {
            var service, th, users;
            service = $(this).data('service');
            users = $.extend(true, [], opts.users[service]);
            users = users.map(u => {
                let hba = '';
                if (u.hba === "True"){
                    hba = ' (HBA)';
                }
                return { ...u, name: escapeHTML(u.name) + hba }
            });
            users.unshift({
                _id: '__unset__',
                name: '- offen -'
            });
            th = null;
            if (users.length > 0) {
                th = $(this).typeahead({
                    name: 'usersearch',
                    showHintOnFocus: 'all',
                    items: 'all',
                    minLength: 0,
                    source: users,
                    selectOnBlur: false,
                    updater: (function (_this) {
                        return function (item) {
                            $(_this)
                                .parents('td')
                                .find('.pharmacist-select')
                                .val(item._id);
                            $(_this)
                                .parents('td')
                                .find('.pharmacist-service-select')
                                .val($(_this).data('service'));
                            $(_this)
                                .parents('td')
                                .find('.usersearch')
                                .not(_this)
                                .each(function () {
                                    return $(this).prop('disabled', item._id !== null);
                                });
                            return item;
                        };
                    })(this),
                });
            } else {
                $(this).prop('disabled', true);
            }
            return $(this).on(
                'click',
                (function (_this) {
                    return function () {
                        var v;
                        v = th.val();
                        return th
                            .val(null)
                            .typeahead('lookup')
                            .val(v)
                            .focus();
                    };
                })(this)
            );
        };
        $(this).each(init);
        return this;
    };
}.call(this));
