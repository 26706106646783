<template>
    <div>
        <div>
            Zahl der Nutzer, die sich durch die App eingeloggt haben.
        </div>
        <table class="table" id="appUsageStatistics">
            <thead>
                <tr>
                    <th>
                        -
                    </th>
                    <th>
                        Freiberufler
                    </th>
                    <th>
                        Apotheker
                    </th>
                    <th>
                        Gesamt
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="stat in parsedStatistics" :key="stat" :value="stat">
                    <td class="bold">
                        {{ stat.description }}
                    </td>
                    <td >
                        {{ stat.contractors }}
                    </td>
                    <td >
                        {{ stat.pharmacies }}
                    </td>
                    <td >
                        {{ stat.pharmacies + stat.contractors }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'AppUsageStatistics',
    props: {
        statistics: Array
    },
    data: function() {
        const parsedStatistics = this.statistics ? JSON.parse(this.statistics) : [];
        return {
            parsedStatistics,
        };
    }
};
</script>

<style scoped>
    .bold {
        font-weight: bold;
    }
</style>
