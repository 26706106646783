var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "vuetify-wrapper", attrs: { cols: "3" } },
    [
      _c("v-select", {
        class: _vm.className,
        attrs: {
          items: _vm.options,
          value: _vm.value,
          placeholder: _vm.placeholder,
          dense: "",
          outlined: "",
          clearable: "",
          "hide-details": "",
        },
        on: {
          change: (value) => _vm.$emit("select", value),
          "update:value": function ($event) {
            _vm.value = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }