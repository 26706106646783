var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          class: { "bootstrap-button": _vm.usebootstrapstyle },
          attrs: {
            disabled: _vm.parsedDisabled,
            loading: _vm.loading,
            "min-width": "30",
            small: "",
            icon: _vm.usebootstrapstyle ? true : false,
            outlined: _vm.usebootstrapstyle ? true : false,
            title: "Freiberufler vorschlagen",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleDialog.apply(null, arguments)
            },
          },
        },
        [
          _c("v-icon", { attrs: { small: "", color: "black" } }, [
            _vm._v("mdi-human-greeting-variant"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "vuetify-wrapper" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(
                  "\n                Vorgeschlagene Freiberufler\n            "
                ),
              ]),
              _vm._v(" "),
              !_vm.parsedPharmacy.no_approval_required
                ? _c("NoApprovalAlert")
                : _vm._e(),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n                Durch das Vorgeschlagen erhalten Freiberufler eine E-Mail mit einem Direktlink zum Termin.\n                In ihrer App sehen sie ein Liste mit allen ihnen vorgeschlagenen Terminen, die noch offen sind.\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.loading
                ? _c("v-card-text", [
                    _vm._v(
                      "\n                Lade vorgeschlagene Freiberufler...\n            "
                    ),
                  ])
                : _vm.isError
                ? _c("v-card-text", [
                    _vm._v(
                      "\n                Ein Fehler beim Laden des Termines ist aufgetaucht.\n            "
                    ),
                  ])
                : _c(
                    "v-card-text",
                    [
                      _vm._l(
                        _vm.proposedPharmacistsList,
                        function ({ pharmacistId, pharmacist, proposalDate }) {
                          return _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  pharmacist
                                    ? _c(
                                        "v-list-item-title",
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(pharmacist.lastname) +
                                              ", " +
                                              _vm._s(pharmacist.firstname) +
                                              "\n                            "
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                dense: "",
                                                small: "",
                                                icon: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.unproposePharmacist(
                                                    pharmacistId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "red",
                                                  },
                                                },
                                                [_vm._v("mdi-account-minus")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                dense: "",
                                                small: "",
                                                icon: "",
                                                title:
                                                  "E-Mail erneut versenden",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.proposePharmacist(
                                                    pharmacistId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "blue",
                                                  },
                                                },
                                                [_vm._v("mdi-email-fast")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                dense: "",
                                                small: "",
                                                icon: "",
                                                title: "E-Mail Text Kopieren",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.copyEmailText(
                                                    pharmacist.firstname
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "blue",
                                                  },
                                                },
                                                [_vm._v("mdi-content-copy")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                pharmacist.proposal_preference
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(pharmacistId) +
                                            "\n                        "
                                        ),
                                      ]),
                                  _vm._v(
                                    "\n                        Vorgeschlagen: " +
                                      _vm._s(_vm.formatDate(proposalDate)) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("PharmacistSearchDropdown", {
                                attrs: {
                                  pharmacy: _vm.parsedPharmacy,
                                  services: _vm.appointment.available_services,
                                },
                                model: {
                                  value: _vm.selectedPharmacist,
                                  callback: function ($$v) {
                                    _vm.selectedPharmacist = $$v
                                  },
                                  expression: "selectedPharmacist",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.selectedPharmacist,
                                    dense: "",
                                    title:
                                      "Schlage dem Freiberufler diesen Termin / diese Termingruppe per E-Mail vor",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.proposePharmacist(
                                        _vm.selectedPharmacist._id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "black" } },
                                    [_vm._v("mdi-account-plus")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }