<template>
    <v-autocomplete
        v-model="selectedPharmacist"
        :placeholder="placeholder"
        @update:search-input="searchActivePharmacists"
        :items="dropdownOptions"
        :loading="isLoadingPharmacists"
        no-filter
        no-data-text="Keine Suchergebnisse"
        return-object
        hide-details
        outlined
        dense
        auto-select-first
    >
        <template v-slot:item="{ item }">
            <div
                class="v-application"
                style="display: block"
                data-role="pharmacist-option"
                :data-pharmacist-id="item.pharmacist._id"
            >
                <p class="font-weight-medium mt-2 no-margin">
                    <v-tooltip
                        v-if="isBlacklisted(item.pharmacist._id)"
                        right
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red">mdi-account-off</v-icon>
                        </template>
                        Dieser Nutzer ist auf der Negativ-Liste der Apotheke.
                    </v-tooltip>
                    {{ item.pharmacist.firstname }} {{ item.pharmacist.lastname }}
                    <a
                        :href="`/pharmacists/${item.pharmacist._id}`"
                        target="_blank"
                        class="ml-1"
                    >
                        <u>{{ item.pharmacist.customer_id }}</u>
                    </a>
                    <v-tooltip
                        v-if="item.pharmacist.proposal_preference === 'no_auto_proposals'"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                v-on="on"
                                color="red"
                            >
                                mdi-alert-octagon
                            </v-icon>
                        </template>
                        Der Freiberufler möchte keine Terminvorschläge erhalten!
                    </v-tooltip>
                    <v-tooltip
                        v-if="item.pharmacist.proposal_preference === 'available_dates_only'"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon
                                v-on="on"
                                color="orange"
                            >
                                mdi-calendar-alert
                            </v-icon>
                        </template>
                        Der Freiberufler möchte nur Terminvorschläge an verfügbaren erhalten!
                    </v-tooltip>
                </p>
                <v-chip
                    v-for="service in item.pharmacist.services"
                    :color="getServiceColor(service)"
                    class="mb-2 px-2"
                    dark
                    x-small
                    label
                >
                    <b>{{ service == 'substitution' ? 'Apotheker/in' : service.toUpperCase() }}</b>
                </v-chip>
                <v-chip
                    v-if="item.pharmacist.has_hba"
                    color="purple"
                    class="mb-2 px-2"
                    tag="b"
                    dark
                    x-small
                    label
                    outlined
                >
                    HBA
                </v-chip>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import { mapMutations } from 'vuex';

    export default {
        props: {
            /** Used to warn about blacklisted pharmacists */
            pharmacy: Object,
            /** @type { String[] | null } Service types to filter by. Use null to disable. */
            services: {
                default: null,
                type: Array,
            },
            /** The selected Pharmacist */
            value: {
                default: null,
                type: Object,
            },
            placeholder: {
                default: null,
                type: String,
            }
        },
        data() {
            return {
                pharmacists: [],
                isLoadingPharmacists: false,
            }
        },
        computed: {
            dropdownOptions() {
                if (this.pharmacists.length) return this.pharmacists;
                // make sure that the selected value is always an option
                if (this.selectedPharmacist) return [this.selectedPharmacist];
                return [];
            },
            selectedPharmacist: {
                get() {
                    if (this.value) {
                        return this.getPharmacistOptionForDropdown(this.value);
                    }
                    return undefined;
                },
                set(item) {
                    if (item) {
                        const { pharmacist, text, value } = item;
                        this.$emit('input', pharmacist);
                    } else {
                        this.$emit('input', null);
                    }
                }
            },
        },
        methods: {
            isBlacklisted(pharmacistId) {
                return this.pharmacy?.contractor_black_list?.includes(pharmacistId) ?? false;
            },
            searchActivePharmacists: _.debounce(async function (text) {
                if (!text?.trim().length) {
                    this.pharmacists = [];
                    return
                }
                const params = {
                    query: text,
                    pharmacy_id: this.pharmacy?._id,
                    page: 1,
                    page_size: 10
                }
                if (this.services) {
                    params["services"] = this.services.join(",")
                }

                params["permissions"] = "appointment_offers";


                try {
                    this.isLoadingPharmacists = true
                    const response = await axios.get(`/api/pharmacists/active/search`, { params });
                    if (response.data.status == 500) throw "Failed to search for pharmacists";
                    this.pharmacists = response.data.results.map(this.getPharmacistOptionForDropdown);
                } catch (error) {
                    this.alertError()
                }
                this.isLoadingPharmacists = false;
            }, 200),
            getPharmacistOptionForDropdown(pharmacist) {
                if (!pharmacist) throw new TypeError("Parameter 'pharmacist' is required but was falsy")
                return { // The autocomplete element requires an object with 'text' and 'value' properties
                    text: `${pharmacist.firstname} ${pharmacist.lastname}`,
                    value: pharmacist._id,
                    pharmacist,
                }
            },
            getServiceColor(service = 'substitution') {
                const servicesColors = {
                    substitution: 'cyan',
                    pta: 'warning',
                    pka: 'success',
                    qms: 'red',
                    storno: 'red'
                }
                return servicesColors[service]
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
    }
</script>

<style scoped>
    .no-margin {
        margin: 0 !important;
    }
</style>