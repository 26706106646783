var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          staticClass: "bootstrap-button",
          attrs: {
            loading: _vm.loading,
            small: "",
            icon: "",
            outlined: "",
            title: _vm.title,
          },
          on: { click: _vm.toggleVerified },
        },
        [
          _vm.verifiedDate
            ? _c("v-icon", { attrs: { small: "", color: "green" } }, [
                _vm._v("mdi-eye-check"),
              ])
            : _c("v-icon", { attrs: { small: "", color: "red" } }, [
                _vm._v("mdi-eye-remove"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }