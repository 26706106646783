<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-alert
                    dense
                    outlined
                    color="error"
                    icon="mdi-alert-circle"
                >
                    Termine Löschen?
                </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn outlined color="secondary" @click="isOpen = false">Abbrechen</v-btn>
                <v-btn depressed color="error" @click="deleteAppointment" class="confirm-delete-btn">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    import axios from 'axios';
    import { mapMutations } from 'vuex';

    export default {
        props: {
            value: Boolean,
            appointmentId: String,
            pharmacyId: String,
        },
        data: () => ({}),
        methods: {
            async deleteAppointment() {
                try {
                    await axios.post(`/pharmacies/${this.pharmacyId}/${this.appointmentId}/remove`);
                    this.isOpen = false;
                    window.location.reload()
                } catch (error) {
                    this.alertError()
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
        }
    }
</script>

<style scoped>

</style>