<template>
    <span class="vuetify-wrapper">
        <v-btn
            :disabled="parsedDisabled"
            @click.stop="toggleDialog"
            :loading="loading"
            min-width="30"
            small
            :icon="usebootstrapstyle ? true : false"
            :outlined="usebootstrapstyle ? true : false"
            :class="{'bootstrap-button': usebootstrapstyle}"
            title="Freiberufler vorschlagen"
        >
            <v-icon small color="black">mdi-human-greeting-variant</v-icon>
        </v-btn>
        <v-dialog
            content-class="vuetify-wrapper"
            v-model="dialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Vorgeschlagene Freiberufler
                </v-card-title>
                <NoApprovalAlert v-if="!parsedPharmacy.no_approval_required" />
                <v-card-text>
                    Durch das Vorgeschlagen erhalten Freiberufler eine E-Mail mit einem Direktlink zum Termin.
                    In ihrer App sehen sie ein Liste mit allen ihnen vorgeschlagenen Terminen, die noch offen sind.
                </v-card-text>
                <v-card-text v-if="loading">
                    Lade vorgeschlagene Freiberufler...
                </v-card-text>
                <v-card-text v-else-if="isError">
                    Ein Fehler beim Laden des Termines ist aufgetaucht.
                </v-card-text>
                <v-card-text v-else>
                    <v-list-item v-for="{ pharmacistId, pharmacist, proposalDate} in proposedPharmacistsList">
                        <v-list-item-content>
                            <v-list-item-title v-if="pharmacist">
                                {{ pharmacist.lastname }}, {{ pharmacist.firstname }}
                                <v-btn
                                    @click="unproposePharmacist(pharmacistId)"
                                    dense
                                    small
                                    icon
                                >
                                    <v-icon small color="red">mdi-account-minus</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="proposePharmacist(pharmacistId)"
                                    dense
                                    small
                                    icon
                                    title="E-Mail erneut versenden"
                                >
                                    <v-icon small color="blue">mdi-email-fast</v-icon>
                                </v-btn>
                                <v-btn
                                    @click="copyEmailText(pharmacist.firstname)"
                                    dense
                                    small
                                    icon
                                    title="E-Mail Text Kopieren"
                                >
                                    <v-icon small color="blue">mdi-content-copy</v-icon>
                                </v-btn>
                                {{  pharmacist.proposal_preference  }}
                            </v-list-item-title>
                            <v-list-item-title v-else>
                                {{ pharmacistId }}
                            </v-list-item-title>
                            Vorgeschlagen: {{ formatDate(proposalDate) }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-row>
                        <v-col cols="8">
                            <PharmacistSearchDropdown
                                v-model="selectedPharmacist"
                                :pharmacy="parsedPharmacy"
                                :services="appointment.available_services"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-btn
                                @click="proposePharmacist(selectedPharmacist._id)"
                                :disabled="!selectedPharmacist"
                                dense
                                title="Schlage dem Freiberufler diesen Termin / diese Termingruppe per E-Mail vor"
                            >
                                <v-icon small color="black">mdi-account-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

import axios from 'axios';
import moment from 'moment';

import  PharmacistSearchDropdown from '../pharmacist/PharmacistSearchDropdown.vue'
import { parseBool } from '../../helpers.js'
import NoApprovalAlert from './NoApprovalAlert.vue';

export default {
    name: 'ProposePharmacistDialog',
    components: { PharmacistSearchDropdown, NoApprovalAlert },
    props: {
        pharmacy: [String, Object],
        appointmentid: String,
        usebootstrapstyle: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data: function() {
        return {
            loading: false,
            /** @type: { { proposed_pharmacists: { [pharmacistId: string]: moment } } } */
            appointment: null,
            /** @type: { { [pharmacistId: string]: pharmacist } } */
            loadedPharmacists: {},
            dialog: false,
            selectedPharmacist: null,
        };
    },
    computed: {
        /** required for jinja2 compatibility */
        parsedDisabled() {
            return parseBool(this.disabled)
        },
        isError() {
            return !this.appointment;
        },
        proposedPharmacists() {
            return this.appointment.proposed_pharmacists;
        },
        proposedPharmacistsList() {
            return Object.entries(this.proposedPharmacists).map(([pharmacistId, proposalDateStr]) =>
                ({
                    pharmacistId,
                    pharmacist: this.loadedPharmacists[pharmacistId],
                    proposalDate: proposalDateStr,
                })
            )
        },
        parsedPharmacy() {
            if (typeof this.pharmacy === 'string') {
                try {
                    return JSON.parse(this.pharmacy);
                } catch (e) {
                    console.error('Invalid JSON string:', this.pharmacy);
                    return null;
                }
            }
            return this.pharmacy;
        },
        /** @type { [serviceType] } */
        availableservices() {
            return this.appointment.available_services;
        },
    },
    methods: {
        async proposePharmacist(pharmacistId) {
            if (!pharmacistId) throw "PharmacistId required";

            await axios.post(`/api/appointments/${this.appointmentid}/propose/${pharmacistId}`);
            this.selectedPharmacist = null;
            await this.loadAppointment();
        },
        async unproposePharmacist(pharmacistId) {
            if (!pharmacistId) throw "PharmacistId required";
            await axios.post(`/api/appointments/${this.appointmentid}/unpropose/${pharmacistId}`);
            await this.loadAppointment();
        },
        copyEmailText(pharmacistFirstName) {
            let date_range = this.appointment.date;
            if (this.appointment.group_size == 2) {
                date_range += ` + ${this.appointment.group_enddate} (${this.appointment.group_size} Termine)`
            } else if (this.appointment.group_size >= 3 ) {
                date_range += ` - ${this.appointment.group_enddate} (${this.appointment.group_size} Termine)`
            }

            let link = `${window.location.origin.replace("admin", "contractor")}/appointment/${this.appointment._id}`

            let emailText = `Hallo ${pharmacistFirstName},\n\n`
            + `Hier ist ein Terminvorschlag, den du direkt zusagen kannst:\n\n`
            + `${this.parsedPharmacy.shortname}\n`
            + `Termin: ${date_range}\n`
            + `Link: ${link} (Hier kannst Du alle Details zu dem Termin einsehen)\n\n`
            + `Hinweis: Du kannst Deine Einstellung zur Art und Weise, wie Du Terminvorschläge erhalten möchtest, anpassen. Hierzu hast Du die folgenden Optionen:\n`
            + `Du möchtest keine Terminvorschläge mehr erhalten.\n`
            + `Du möchtest nur Terminvorschläge an den Tagen erhalten, an denen Du Dich als verfügbar eingetragen hast.\n`
            + `Du möchtest alle Terminvorschläge in Deiner Nähe erhalten.\n`
            + `Gehe dazu einfach in deine Profileinstellung unter\n`
            + `https://contractor.flying-pharmacist.de/profile\n\n`
            + `Liebe Grüße,\n`
            + `Flying Pharmacist`

            navigator.clipboard.writeText(emailText)
                .then(() => {
                    console.log(`Der Email Text wurde in die Zwischenablage kopiert`);
                })
                .catch(err => {
                    console.log("Fehler beim Kopieren in die Zwischenablage. Versuchen Sie es bitte noch mal.");
                })

        },
        formatDate(isoString) {
            if(!isoString.endsWith("Z")) {  // Python 3.9's date serializing doesn't add the Z for UTC
                isoString += "Z";
            }
            return moment(isoString).format("YYYY-MM-DD HH:mm:SS")
        },
        async toggleDialog() {
            this.dialog = !this.dialog;
            if (this.dialog) {
                await this.loadAppointment();
            }
        },
        async loadAppointment() {
            this.loading = true;
            this.appointment = null;
            try {
                const response = await axios.get(`/api/appointments/${this.appointmentid}`);
                const appointment = response.data;
                this.appointment = appointment;
                // load corresponding user data
                await Promise.all(Object.keys(this.proposedPharmacists).map(this.loadPharmacist));
            }
            catch(error) {
                console.log("Failed to load appointment or pharmacists", error);
            }
            finally {
                this.loading = false;
            }
        },
        async loadPharmacist(pharmacistId) {
            if (pharmacistId in this.loadedPharmacists) {
                return;
            }
            const response = await axios.get(`/api/contractors/${pharmacistId}`);
            this.loadedPharmacists[pharmacistId] = response.data;
        },
    },
    async mounted() {
    },
};
</script>

<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}
.black--text {
    color: black;
}
</style>
