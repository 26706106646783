var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vuetify-wrapper" }, [
    _c(
      "div",
      [
        _c(
          "v-btn-toggle",
          {
            attrs: { multiple: "", rounded: "", mandatory: false },
            model: {
              value: _vm.visible_appointments_toggles,
              callback: function ($$v) {
                _vm.visible_appointments_toggles = $$v
              },
              expression: "visible_appointments_toggles",
            },
          },
          [
            _c("v-btn", { attrs: { title: "Vergeben Termine anzeigen" } }, [
              _vm._v("\n                Vergebene Termine\n            "),
            ]),
            _vm._v(" "),
            _c("v-btn", { attrs: { title: "Offene Termine anzeigen" } }, [
              _vm._v("\n                Offene Termine\n            "),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-btn-toggle",
          {
            attrs: { multiple: "", rounded: "", mandatory: false },
            model: {
              value: _vm.visible_additional_appointments_toggles,
              callback: function ($$v) {
                _vm.visible_additional_appointments_toggles = $$v
              },
              expression: "visible_additional_appointments_toggles",
            },
          },
          [
            _c(
              "v-btn",
              {
                attrs: { title: "Termine mit verifizierten Preisen anzeigen" },
              },
              [
                _c("v-icon", { attrs: { color: "green" } }, [
                  _vm._v("mdi-eye-check"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  title: "Termine mit nicht-verifizierten Preisen anzeigen",
                },
              },
              [
                _c("v-icon", { attrs: { color: "red" } }, [
                  _vm._v("mdi-eye-remove"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn-update-selected" },
      [
        _c("BatchUpdatePricesBtn", {
          attrs: { appointmentIds: _vm.selectedAppointmentIds },
          on: { save: _vm.onBatchUpdateSave },
        }),
        _vm._v(" "),
        _vm.selectedAppointmentIds.length
          ? _c(
              "span",
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.selectedAppointmentIds.length) +
                    " Termine in\n            " +
                    _vm._s(_vm.selectedUniquePharmacies) +
                    " Apotheken mit\n            " +
                    _vm._s(_vm.selectedUniquePharmacists) +
                    " Freiberuflern ausgewählt.\n\n            "
                ),
                _c(
                  "v-btn",
                  { attrs: { plain: "" }, on: { click: _vm.deselectAll } },
                  [_vm._v("Alle abwählen")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass: "table table-condensed table-bordered table-striped",
        attrs: { id: "appointments-wrong-margin" },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.visibleAppointments, function (a, index) {
            return _c(
              "tr",
              { key: a._id, class: { success: a.priceWasUpdated } },
              [
                _c(
                  "td",
                  [
                    _c("v-checkbox", {
                      attrs: { dense: "" },
                      model: {
                        value: a.uiSelected,
                        callback: function ($$v) {
                          _vm.$set(a, "uiSelected", $$v)
                        },
                        expression: "a.uiSelected",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `/pharmacies/${a.pharmacy_id}/${a._id}/edit`,
                        title: "Termin bearbeiten",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(index) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "nobr" }, [
                  _vm._v(_vm._s(_vm.formatDate(a.date))),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "nobr" }, [
                  _c(
                    "a",
                    {
                      class: {
                        "references-user": _vm.loggedinuserid == a.created_by,
                      },
                      attrs: {
                        href: `/pharmacists/${a.updated_by ?? a.created_by}`,
                        target: "_blank",
                        title: "Erstellt",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.formatDateTime(a.created)) +
                          "\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: {
                        "references-user": _vm.loggedinuserid == a.updated_by,
                      },
                      attrs: {
                        href: `/pharmacists/${a.updated_by ?? a.created_by}`,
                        target: "_blank",
                        title: "Zuletzt bearbeitet",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.formatDateTime(a.updated)) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: `/pharmacies/${a.pharmacy_id}`,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(a.pharmacy.shortname) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.isFirstAppointmentOfPharmacy(index)
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              plain: "",
                              "x-small": "",
                              title:
                                "Alle Termine in dieser Apotheke auswählen",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectByPharmacyAndPharmacist(
                                  a.pharmacy_id,
                                  null
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        alle auswählen\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(a.time) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  a.pharmacist
                    ? _c(
                        "div",
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/pharmacists/${a.pharmacist_id}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(a.pharmacist.lastname) +
                                  ", " +
                                  _vm._s(a.pharmacist.firstname) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isFirstAppointmentOfPharmacist(index)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    plain: "",
                                    "x-small": "",
                                    title:
                                      "Alle Termine dieses Freiberuflers in dieser Apotheke auswählen",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectByPharmacyAndPharmacist(
                                        a.pharmacy_id,
                                        a.pharmacist_id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            alle auswählen\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", [_vm._v("-")]),
                ]),
                _vm._v(" "),
                a.service
                  ? _c("td", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t(a.service)) +
                          "\n                "
                      ),
                    ])
                  : _c(
                      "td",
                      {
                        staticClass: "service-unconfirmed",
                        attrs: { "data-service": _vm.$t(a.suggested_service) },
                      },
                      _vm._l(a.available_services, function (service) {
                        return _c("p", { key: service }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t(service)) +
                              "\n                    "
                          ),
                        ])
                      }),
                      0
                    ),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(a.quantity) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: {
                      "text-right": true,
                      error: _vm.isApoPriceWrong(a),
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(a.hourly_apo_rate) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: { "text-right": true, error: _vm.isFbPriceWrong(a) },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(a.hourly_ma_rate) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    class: { "text-right": true, error: _vm.isMarginWrong(a) },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(a.hourly_apo_rate - a.hourly_ma_rate) +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        a.applicable_pricing_overrides?.margin ?? "default"
                      ) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "flex" },
                  [
                    _c("price-suggestion", {
                      attrs: {
                        pharmacyid: a.pharmacy_id,
                        appointmentid: a._id,
                      },
                    }),
                    _vm._v(" "),
                    _c("BatchUpdatePricesBtn", {
                      attrs: { appointmentIds: [a._id], icon: "mdi-pencil" },
                      on: { save: _vm.onBatchUpdateSave },
                    }),
                    _vm._v(" "),
                    _c("MarkAsVerifiedBtn", {
                      attrs: {
                        appointmentId: a._id,
                        verifiedDate: a.verified_rates,
                        verifiedBy: a.verified_rates_by,
                      },
                      on: { update: (x) => _vm.onBatchUpdateSave([x]) },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Datum")]),
        _vm._v(" "),
        _c("th", [_vm._v("Erstellt / Geändert")]),
        _vm._v(" "),
        _c("th", [_vm._v("Apotheke")]),
        _vm._v(" "),
        _c("th", [_vm._v("Zeitbeschreibung")]),
        _vm._v(" "),
        _c("th", [_vm._v("Freiberufler")]),
        _vm._v(" "),
        _c("th", [_vm._v("Service")]),
        _vm._v(" "),
        _c("th", [_vm._v("Stunden")]),
        _vm._v(" "),
        _c("th", [_vm._v("Apo €/h")]),
        _vm._v(" "),
        _c("th", [_vm._v("FB €/h")]),
        _vm._v(" "),
        _c("th", [_vm._v("Marge")]),
        _vm._v(" "),
        _c("th", [_vm._v("Apo Marge")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }