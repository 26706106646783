const state = {
    type: null,
    msg: null,
    active: false
}

const mutations = {
    success(state, msg) {
        state.type = 'success'
        state.msg = msg
        state.active = true
    },
    info(state, msg) {
        state.type = 'info'
        state.msg = msg
        state.active = true
    },
    error(state, msg='Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.') {
        state.type = 'error'
        state.msg = msg
        state.active = true
    },
    clear(state) {
        state.type = null
        state.msg = null
        state.active = false
    }
}

export const alert = {
    namespaced: true,
    state,
    mutations
}
