var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-application v-application--is-ltr",
        "max-width": "700px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "overflow-auto",
          attrs: { loading: _vm.isLoading && "white" },
        },
        [
          _c("v-card-title", [
            _c("h3", { staticStyle: { padding: "1rem" } }, [
              _vm._v("Verlinkte Apotheke"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _c("div", { staticClass: "container py-12" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "new-subscription" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        chips: "",
                        label: "Suche Apotheke",
                        items: _vm.searchResults,
                        "no-filter": "",
                        "no-data-text":
                          "Geben Sie ein, um Suchergebnisse zu erhaltenKeine Suchergebnisse",
                      },
                      on: { "update:search-input": _vm.searchPharmacies },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "v-application",
                                  attrs: {
                                    "data-role": "pharmacy-option",
                                    "data-pharmacy-id": item.pharmacy._id,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-medium mt-2 no-margin pharmacy-name",
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.text) +
                                          " (" +
                                          _vm._s(item.pharmacy.city) +
                                          ")\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedPharmacy,
                        callback: function ($$v) {
                          _vm.selectedPharmacy = $$v
                        },
                        expression: "selectedPharmacy",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: !_vm.selectedPharmacy,
                          id: "link-pharmacy-btn",
                        },
                        on: { click: _vm.submitForm },
                      },
                      [
                        _vm._v(
                          "\n              Apotheke verlinken\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "subscribed-pharmacies" },
                  [
                    _c("v-simple-table", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", { staticClass: "vuetify-wrapper" }, [
                                  _c("th", [_vm._v("Apotheke")]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("Aktionen"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.subscribedpharmacies,
                                  function (item) {
                                    return _c("linked-pharmacy-table-row", {
                                      attrs: {
                                        subscribedPharmacy: item,
                                        discountId: _vm.value?._id,
                                      },
                                      on: {
                                        refresh: function ($event) {
                                          return _vm.$emit("refresh")
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.isDialogOpen = false
                    },
                  },
                },
                [_vm._v(" ohne Auswahl schließen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }