<template>
    <BatchUpdatePricesBtn :appointment-ids="selectedRowIds" @save="reload"></BatchUpdatePricesBtn>
</template>

<script>

import { mapState} from 'vuex'
import BatchUpdatePricesBtn from './BatchUpdatePricesBtn.vue';

export default {
    name: 'BatchUpdatePrices',
    components: { BatchUpdatePricesBtn },
    props: {

    },
    data: () => ({
    }),
    computed: {
        ...mapState(['selectedRowIds']),
    },
    watch: {
    },
    methods: {
        reload() {
            document.location.reload();
        }
    },
};
</script>
