var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        transition: "slide-y-transition",
        "close-on-content-click": false,
        "content-class": "v-application v-application--is-ltr",
        "nudge-top": "80",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ staticClass: "ml-2", attrs: { "x-small": "" } }, on),
                [_vm._v("\n            Weitere Infos\n        ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "justify-end py-1 px-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", fab: "", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isOpen = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("h4", { staticClass: "d-inline-block" }, [
                    _vm._v(
                      _vm._s(_vm.user.firstname) +
                        " " +
                        _vm._s(_vm.user.lastname)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: `/pharmacists/${_vm.user._id}`,
                        target: "_blank",
                      },
                    },
                    [_vm._v("\n                    anzeigen\n            ")]
                  ),
                  _vm._v("\n            |\n            "),
                  _c(
                    "a",
                    { attrs: { href: _vm.navigationURL, target: "_blank" } },
                    [_vm._v("\n                    Maps\n            ")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-1" }, [
                    _vm._v(
                      _vm._s(_vm.pharmacyErp) +
                        " Kenntnisse: " +
                        _vm._s(_vm.erpExpertise) +
                        "/3"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.user.proposed
                    ? _c(
                        "div",
                        [
                          _c("p", { staticClass: "mb-0 p-0" }, [
                            _vm._v(
                              "Vorgeschlagen: " +
                                _vm._s(_vm.formatDate(_vm.user.proposed_date))
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1 mb-2",
                              attrs: {
                                small: "",
                                rounded: "",
                                text: "",
                                outlined: "",
                                disabled: _vm.isLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("unpropose")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Vorschlag zurücknehmen\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("vc-calendar", {
                    attrs: {
                      columns: _vm.$screens({ default: 1, lg: 1 }),
                      attributes: _vm.attributes,
                      "from-page": _vm.calendarDate,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }