<template>
    <div class="row gx-1">
        <div class="col-auto">
            <form name="invalid-appointments-form"
                id="invalid-appointments-form"
                :action="markAppointmentsInvalidAction"
                method="POST"
                role="form"
                v-if="!hide_invalid_button"
            >
                <input type="hidden" name="aids" v-model="appointmentIds">
                <button type="submit" class="btn btn-warning">als ungültig markieren</button>
            </form>
        </div>
        <div class="col-auto">
            <batch-update-prices />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppointmentsControlButtons',
    props: ['pharmacist_id', 'hide_invalid_button'],
    computed: {
        createInvoiceAction: function () {
            return '/pharmacists/' + this.pharmacist_id + '/invoice/add'
        },
        markAppointmentsInvalidAction: function () {
            return '/pharmacists/' + this.pharmacist_id + '/mark_appointments_invalid'
        },
        appointmentIds: function () {
            return JSON.stringify(this.$store.state.selectedRowIds)
        }
    }
}
</script>
