<template>
    <div class="vuetify-wrapper">
        <div>
            <v-btn-toggle
                v-model="visible_appointments_toggles"
                multiple
                rounded
                :mandatory="false"
            >
                <v-btn title="Vergeben Termine anzeigen">
                    Vergebene Termine
                </v-btn>
                <v-btn title="Offene Termine anzeigen">
                    Offene Termine
                </v-btn>
            </v-btn-toggle>

            <v-btn-toggle
                v-model="visible_additional_appointments_toggles"
                multiple
                rounded
                :mandatory="false"
            >
                <v-btn title="Termine mit verifizierten Preisen anzeigen">
                    <v-icon color="green">mdi-eye-check</v-icon>
                </v-btn>
                <v-btn title="Termine mit nicht-verifizierten Preisen anzeigen">
                    <v-icon color="red">mdi-eye-remove</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
        <div class="btn-update-selected">
            <BatchUpdatePricesBtn
                :appointmentIds="selectedAppointmentIds"
                @save="onBatchUpdateSave"
            />
            <span v-if="selectedAppointmentIds.length">
                {{ selectedAppointmentIds.length }} Termine in
                {{ selectedUniquePharmacies }} Apotheken mit
                {{ selectedUniquePharmacists }} Freiberuflern ausgewählt.

                <v-btn plain @click="deselectAll">Alle abwählen</v-btn>
            </span>
        </div>
        <table
            class="table table-condensed table-bordered table-striped"
            id="appointments-wrong-margin"
        >
            <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Datum</th>
                    <th>Erstellt / Geändert</th>
                    <th>Apotheke</th>
                    <th>Zeitbeschreibung</th>
                    <th>Freiberufler</th>
                    <th>Service</th>
                    <th>Stunden</th>
                    <th>Apo €/h</th>
                    <th>FB €/h</th>
                    <th>Marge</th>
                    <th>Apo Marge</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(a, index) in visibleAppointments" :key="a._id"
                    :class="{ 'success': a.priceWasUpdated }"
                >
                    <td>
                        <v-checkbox v-model="a.uiSelected" dense/>
                    </td>
                    <td  class="text-right">
                        <a :href="`/pharmacies/${a.pharmacy_id}/${a._id}/edit`" title="Termin bearbeiten" target="_blank">
                            {{ index }}
                        </a>
                    </td>
                    <td class="nobr">{{ formatDate(a.date) }}</td>
                    <td class="nobr">
                        <a
                            :href="`/pharmacists/${a.updated_by ?? a.created_by}`"
                            target="_blank"
                            title="Erstellt"
                            :class="{ 'references-user': loggedinuserid == a.created_by}"
                        >
                            {{ formatDateTime(a.created) }}
                        </a>
                        <br/>
                        <a
                            :href="`/pharmacists/${a.updated_by ?? a.created_by}`"
                            target="_blank"
                            title="Zuletzt bearbeitet"
                            :class="{ 'references-user': loggedinuserid == a.updated_by}"
                        >
                            {{ formatDateTime(a.updated) }}
                        </a>
                    </td>
                    <td>
                        <a :href="`/pharmacies/${a.pharmacy_id}`" target="_blank">
                            {{ a.pharmacy.shortname }}
                        </a>
                        <br/>
                        <v-btn
                            plain
                            x-small
                            v-if="isFirstAppointmentOfPharmacy(index)"
                            @click="selectByPharmacyAndPharmacist(a.pharmacy_id, null)"
                            title="Alle Termine in dieser Apotheke auswählen"
                        >
                            alle auswählen
                        </v-btn>
                    </td>
                    <td>
                        {{ a.time }}
                    </td>
                    <td>
                        <div v-if="a.pharmacist">
                            <a :href="`/pharmacists/${a.pharmacist_id}`" target="_blank">
                                {{ a.pharmacist.lastname }}, {{ a.pharmacist.firstname }}
                            </a>
                            <br/>
                            <v-btn
                                plain
                                x-small
                                v-if="isFirstAppointmentOfPharmacist(index)"
                                @click="selectByPharmacyAndPharmacist(a.pharmacy_id, a.pharmacist_id)"
                                title="Alle Termine dieses Freiberuflers in dieser Apotheke auswählen"
                            >
                                alle auswählen
                            </v-btn>
                        </div>
                        <div v-else>-</div>
                    </td>
                    <td v-if="a.service">
                        {{ $t(a.service) }}
                    </td>
                    <td v-else class="service-unconfirmed" :data-service="$t(a.suggested_service)">
                        <p v-for="service in a.available_services" :key="service">
                            {{ $t(service) }}
                        </p>
                    </td>
                    <td  class="text-right">
                        {{ a.quantity }}
                    </td>
                    <td :class="{ 'text-right': true, 'error':  isApoPriceWrong(a)}">
                        {{ a.hourly_apo_rate }}
                    </td>
                    <td :class="{ 'text-right': true, 'error':  isFbPriceWrong(a)}">
                        {{ a.hourly_ma_rate }}
                    </td>
                    <td :class="{ 'text-right': true, 'error':  isMarginWrong(a)}">
                        {{ a.hourly_apo_rate - a.hourly_ma_rate }}
                    </td>
                    <td class="text-right">
                        {{ a.applicable_pricing_overrides?.margin ?? "default" }}
                    </td>
                    <td class="flex">
                        <price-suggestion :pharmacyid="a.pharmacy_id" :appointmentid="a._id"/>
                        <BatchUpdatePricesBtn
                            :appointmentIds="[a._id]"
                            @save="onBatchUpdateSave"
                            icon="mdi-pencil"
                        />
                        <MarkAsVerifiedBtn
                            :appointmentId="a._id"
                            :verifiedDate="a.verified_rates"
                            :verifiedBy="a.verified_rates_by"
                            @update="x => onBatchUpdateSave([x])"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import axios from 'axios';
import moment from 'moment';
import PriceSuggestion from '../appointments/PriceSuggestion.vue';
import BatchUpdatePricesBtn from '../appointments/BatchUpdatePricesBtn.vue';
import MarkAsVerifiedBtn from '../appointments/MarkAsVerifiedBtn.vue';
import { mapMutations } from 'vuex';

const BROKERED_APPOINTMENTS_BUTTON = 0;
const OPEN_APPOINTMENTS_BUTTON = 1;
const VERIFIED_APPOINTMENTS_BUTTON = 0;
const UNVERIFIED_APPOINTMENTS_BUTTON = 1;

export default {
    name: "WrongMarginAppointments",
    components: { PriceSuggestion, BatchUpdatePricesBtn, MarkAsVerifiedBtn },
    props: {
        /** ID of the active user. jinja only supports lowercase attributes */
        loggedinuserid: String,
    },
    data: () => ({
        appointments: [],
        visible_appointments_toggles: [OPEN_APPOINTMENTS_BUTTON],
        visible_additional_appointments_toggles: [UNVERIFIED_APPOINTMENTS_BUTTON],
    }),
    computed: {
        visibleAppointments() {
            const showOpenAppointments = this.visible_appointments_toggles.includes(OPEN_APPOINTMENTS_BUTTON);
            const showBrokeredAppointments = this.visible_appointments_toggles.includes(BROKERED_APPOINTMENTS_BUTTON);
            const showVerifiedAppointments = this.visible_additional_appointments_toggles.includes(VERIFIED_APPOINTMENTS_BUTTON);
            const showUnverifiedAppointments = this.visible_additional_appointments_toggles.includes(UNVERIFIED_APPOINTMENTS_BUTTON);

            const verifiedFilter = (a) => !!a.verified_rates;
            const openFilter = (a) => a.wf_state == 'open';
            const brokeredFilter = (a) => a.wf_state == 'allocated';

            const filteredAppointments = this.appointments.filter(a =>
                (showOpenAppointments && openFilter(a))
                || (showBrokeredAppointments && brokeredFilter(a))
            );
            if (showVerifiedAppointments && showUnverifiedAppointments)
                return filteredAppointments
            if (showVerifiedAppointments)
                return filteredAppointments.filter(verifiedFilter)
            if (showUnverifiedAppointments)
                return filteredAppointments.filter(a => !verifiedFilter(a))
            return [];
        },
        selectedAppointments() {
            return this.appointments
                .filter(a => a.uiSelected);
        },
        selectedAppointmentIds() {
            return this.selectedAppointments
                .map(a => a._id);
        },
        selectedUniquePharmacies() {
            return new Set(this.selectedAppointments.map(a => a.pharmacy_id)).size
        },
        selectedUniquePharmacists() {
            return new Set(this.selectedAppointments.map(a => a.pharmacist_id)).size
        },
    },
    methods: {
        isMarginWrong(appointment) {
            const { hourly_ma_rate, hourly_apo_rate, applicable_pricing_overrides} = appointment;
            const margin = hourly_apo_rate - hourly_ma_rate;
            return margin != (applicable_pricing_overrides?.margin ?? 12);
        },
        isFbPriceWrong({ suggested_service, hourly_ma_rate }) {
            return !hourly_ma_rate || hourly_ma_rate < { substitution: 47, pta: 28, pka: 23}[suggested_service];
        },
        isApoPriceWrong({ suggested_service, hourly_apo_rate }) {
            return !hourly_apo_rate || hourly_apo_rate < { substitution: 57.01, pta: 40, pka: 35}[suggested_service];
        },
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD")
        },
        formatDateTime(date) {
            return moment(date).format("YYYY-MM-DD hh:mm")
        },
        async loadAppointments() {
            try {
                const response = await axios.get("/api/appointments/wrong_margin");
                const appointments = response.data; // .slice(0, 50);


                appointments.forEach(a => {
                    // add fields so vue tracks their values for re-renders
                    a.uiSelected = false;
                    a.priceWasUpdated = false;
                });
                return appointments;
            } catch (error) {
                this.alertError()
            }
        },
        onBatchUpdateSave(updatedAppointments) {
            const appointmentIdToAppointment = new Map(updatedAppointments.map(a => [a._id, a]));

            this.appointments.forEach(a => {
                if (appointmentIdToAppointment.has(a._id)) {
                    Object.assign(a, appointmentIdToAppointment.get(a._id))
                    a.priceWasUpdated = true;
                }
            });
        },
        deselectAll() {
            this.appointments.forEach(a => a.uiSelected = false);
        },
        isFirstAppointmentOfPharmacy(index) {
            return index == 0 || this.visibleAppointments[index].pharmacy_id != this.visibleAppointments[index - 1].pharmacy_id;
        },
        isFirstAppointmentOfPharmacist(index) {
            return index == 0 || this.visibleAppointments[index].pharmacist_id != this.visibleAppointments[index - 1].pharmacist_id;
        },
        selectByPharmacyAndPharmacist(pharmacyId = null, pharmacistId = null) {
            const appointments = this.visibleAppointments.filter(
                a => (!pharmacyId || a.pharmacy_id == pharmacyId) && (!pharmacistId || a.pharmacist_id == pharmacistId)
            );
            appointments.forEach(a => a.uiSelected = true);
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    async mounted() {
        const appois = await this.loadAppointments();
        this.appointments = appois;
    },
    watch: {
        visible_appointments_toggles(next, prev) {
            if (prev.some(e => !next.includes(e))) { // some appointments were hidden
                // remove selection from all appointment that are no longer visible
                this.selectedAppointments.forEach(a => {
                    if (!this.visibleAppointments.includes(a)) {
                        a.uiSelected = false;
                    }
                });
            }
        },
    }
};
</script>

<style scoped>
    .flex {
        white-space: nowrap;
    }
    .flex > div {
        display: inline-block;
    }
    .btn-update-selected {
        position: sticky;
        top: 0px;
        z-index: 1; /** to render above checkboxes */
        background-color: #FFFCED;
        padding: 5px 0px;
    }
    .btn-update-selected > span {
        vertical-align: middle;
    }
    .next-pharmacy {
        font-weight: bold;
    }
    .nobr, .nobr * {
        white-space: nowrap;
    }

    .references-user {
        text-decoration: underline red;
    }
    /* Tooltip for .references user */
    .references-user::after {
        content: "Das warst du";
        display: none;
        position: relative;
        top: 0px;
        right: 0px;
        width: 100px;
        text-align: center;
        background-color: #FFFCED;
        border: 1px solid #ddd;
        border-radius: 0px;
        font-size: small;
    }
    .references-user:hover::after {
        display: block;
    }

    .error {
        font-weight: bold;
        color: red
    }

    .v-btn-toggle {
        vertical-align: bottom;
    }

    .v-btn-toggle, .v-btn-toggle > .v-btn {
        background: transparent !important;
    }
    .v-btn-toggle .v-btn:first-child {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
    .v-btn-toggle .v-btn:last-child {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    .service-unconfirmed {
        font-style: italic;
    }
    /* Tooltip for .service-unconfirmed */
    .service-unconfirmed::after {
        content: "Service noch nicht festgelegt. Apo Marge ist für " attr(data-service) " und möglicherweise falsch";
        display: none;
        position: relative;
        top: 0px;
        right: 0px;
        width: 100px;
        text-align: center;
        background-color: #FFFCED;
        border: 1px solid #ddd;
        border-radius: 0px;
        font-size: small;
    }
    .service-unconfirmed:hover::after {
        display: block;
    }


    .red--text {
        color: red !important;
    }

    .green--text {
        color: green !important;
    }
</style>
