import Spinner from 'spin';

import utils from './utils';


/* previous paginator button */
const prev_item = `
<li class="prev">
    <a href="#" aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
    </a>
</li>
`
/* next paginator button */
const next_item = `
<li class="next">
    <a href="#" aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
    </a>
</li>
`
/* offset for determining range of page buttons to be shown in paginator */
const offset = 10;


class Pagination {

    constructor(elem) {
        this.elem = elem;
        this.content_elem = this.elem.find('.paginated-content');
        this.id = elem.attr('id');
        this.current_page = 1;
        this.paginator = elem.find('ul.pagination');
        this.total_pages = elem.data('total_pages');
        this.page_url = elem.data('page_url');
        this.spinner = new Spinner();

        /* load first page of paginator on page load */
        if (elem.data('loadonactive') == 1) {
            // we are on the initially active tab
            if (elem.hasClass('active')) this.load_page(this.current_page);
            // only load on click of tab when content is empty
            $('ul.nav-tabs li a[href="#'+this.id+'"]').on('click', () => {
                if (!$.trim(this.content_elem.html())) this.load_page(this.current_page);
            });
        }

        /* don't show paginator when we have inly one page */
        if (this.total_pages <= 1) this.paginator.hide();

        /* click events for paginator buttons */
        this.paginator.on('click', 'li', (e) => {
            e.preventDefault();
            if ($(e.currentTarget).hasClass('prev')) {
                this.load_page(this.current_page - 1);
            } else if ($(e.currentTarget).hasClass('next')) {
                this.load_page(this.current_page + 1);
            } else {
                this.load_page($(e.currentTarget).data('page'));
            }
        });
    }

    /* construct paginator */
    update_paginator() {
        this.paginator.children().remove();
        if (this.current_page > 1) this.paginator.append(prev_item);
        for (let i = 1; i <= this.total_pages; i++) {
            var item_html = `<li data-page="${i}"><a href="#">${i}</a></li>`;
            if (this.within_range(i)) this.paginator.append(item_html);
        }
        if (this.current_page < this.total_pages) this.paginator.append(next_item);
        this.paginator.find('li[data-page='+this.current_page+']').addClass('active');
    }

    /* load page and show spinner while it is loading */
    load_page(page) {
        this.content_elem.ready(() => {
            this.content_elem.empty();
            this.spinner.spin(this.content_elem.get(0));
        });
        $.ajax({
            url: this.page_url + '/page/' + page,
            type: "GET",
            success: data => {
                window.scrollTo(0, 0);
                this.spinner.stop()
                this.content_elem.html(data);
                this.current_page = page;
                this.update_paginator();
                utils.enable_infopopup();
                utils.enable_user_search();
                utils.enable_general_popover();
                utils.enable_general_tooltip();
                utils.apply_appointment_filter_buttons();
            }
        });
    }

    /* helper function to determine if a page button is shown in paginator */
    within_range(i) {
        if (i == 1 || i == this.total_pages) {
            return true;
        } else if (this.current_page <= offset - (offset / 2) && i <= offset) {
            return true;
        } else if (i >= this.current_page - (offset / 2) && i <= this.current_page + (offset / 2)) {
            return true;
        } else if (this.current_page >= this.total_pages - (offset / 2) && i >= this.total_pages - offset) {
            return true;
        } else {
            return false;
        }
    }

}


// module.exports = Pagination;
export default Pagination
