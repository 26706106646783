<template>
    <div class="vuetify-wrapper">
        <v-btn
            @click.stop="toggleDialog"
            :loading="loading"
            primary
            dense
        >
            <v-icon left>
                mdi-plus
            </v-icon>
            Tag hinzufügen
        </v-btn>
        <v-dialog
            content-class="vuetify-wrapper overflow-visible"
            v-model="dialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Tag hinzufügen
                </v-card-title>
                <v-card-text>
                    <v-simple-table class="overflow-y-visible">
                        <template v-slot:default>
                            <thead>
                                <th>Datum</th>
                                <th>Öffentlich</th>
                                <th>Spezial</th>
                                <th>Name</th>
                                <th>Bundesländer</th>
                            </thead>
                            <tbody>
                                <holiday-row
                                    :holiday="holiday"
                                    :states="states"
                                    :selectableYear="year"
                                    editableName
                                    editableType
                                    :highlightModified="false"
                                ></holiday-row>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        text
                        @click="dialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click.stop="save"
                        class="save"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import HolidayRow from './Row.vue'

export default {
    name: 'AddHolidayDialog',
    components: { HolidayRow },
    props: {
        loading: Boolean,
        states: Array,
        year: Number,
    },
    data: function() {
        const now = moment();
        return {
            dialog: false,
            holiday: {
                date: now,
                rawDate: now.format('YYYY-MM-DD'),
                name: '',
                type: 'public',
                special: false,
                states: [],
            }
        };
    },
    computed: {
    },
    methods: {
        toggleDialog() {
            this.dialog = !this.dialog;
        },
        save() {
            this.$emit('save', this.holiday);

            this.dialog = false;
        }
    },
    async mounted() {
    },
    watch: {
        year() {
            // make sure the holiday is always in the currently selected year
            this.holiday.date = this.holiday.date.year(this.year).clone(); // clone->new reference->rerender
            this.holiday.rawDate = this.holiday.date.format('YYYY-MM-DD');
        }
    }
};
</script>
