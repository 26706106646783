var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-width": "340",
        "nudge-top": "40",
        "nudge-right": "10",
        "offset-x": "",
        "content-class": "v-application",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "a",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "d-block mt-2 assign-to-admin",
                      class: { "text-decoration-underline": _vm.isOpen },
                      attrs: { role: "link" },
                    },
                    "a",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v("\n        " + _vm._s(_vm.menuLinkText) + "\n        ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("wähle einen Admin aus")]),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pr-15" }, [
            _c(
              "div",
              { staticClass: "pr-15" },
              [
                _c("v-select", {
                  attrs: {
                    items: _vm.adminUsersOptions,
                    placeholder: "wähle einen Admin aus",
                    dense: "",
                    outlined: "",
                    "hide-details": "",
                    "return-object": "",
                    "menu-props": "auto",
                  },
                  model: {
                    value: _vm.selectedUser,
                    callback: function ($$v) {
                      _vm.selectedUser = $$v
                    },
                    expression: "selectedUser",
                  },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "assign-to-me d-block mt-3",
                    on: {
                      click: function ($event) {
                        _vm.selectedUser = _vm.currentUser
                      },
                    },
                  },
                  [_vm._v("Mir zuweisen")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                ohne Auswahl schließen\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "select",
                  attrs: { color: "primary", disabled: !_vm.selectedUser },
                  on: { click: _vm.onPharmacistSelected },
                },
                [_vm._v("\n                Auswählen\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }