<template>
    <div class="vuetify-wrapper overflow-visible inquired-appointments-per-day">
        <vc-calendar
            locale="de"
            :rows="1"
            :columns="columns"
            :step="1"
            :from-page.sync="calendarFromPage"
            :attributes="calendarAttributes"
            disable-page-swipe
        >
            <template v-slot:header-title="{ monthLabel, yearLabel, year, month }">
                {{ monthLabel }} {{ yearLabel }} ({{ totalInquiredAppointments(year, month) }})
            </template>
            <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden">
                    <div class="flex-grow overflow-y-auto overflow-x-auto">
                        <p class="day-day">{{ day.day }}</p>
                        <p v-for="attr in attributes"
                            :key="attr.key"
                            class="day-label"
                            :style="{ background: attr.customData.background }"
                        >
                            {{ attr.customData.title }}
                        </p>
                        <p v-if="!attributes?.length"
                            class="day-label"
                        >
                            0
                        </p>
                    </div>
                </div>
            </template>
        </vc-calendar>
    </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import { mapMutations } from 'vuex';

export default {
    name: 'InquiredAppointmentsPerDaySingle',
    components: { },
    props: {
        /** how many calendar-months to display */
        columns: {
            default: 1,
            type: Number,
        },
        initialYear: {
            default: moment().year(),
            type: Number,
        },
        /** 1-based index */
        initialMonth: {
            default: moment().month() + 1,
            type: Number,
        },
        selectedSearchParam: String,

    },
    data: function() {
        return {
            loading: false,
            /**
             * @type { { [searchParam: str]: { [year: int]: { [month: int]: {date: Date, count: int}[] } }}}
             */
            data: {},
            /** Which month is the top-left in the calendar */
            calendarFromPage: {
                year: this.initialYear || moment().year(),
                month: this.initialMonth || moment().month() + 1,
            },
        };
    },
    computed: {
        /** Returns the data for the viewed columns */
        relevantData: function() {
            const { year, month } = this.calendarFromPage;

            // momentjs' month is 0-based, but we need 1-based
            const date = moment.utc({ year, month: month - 1 });
            const data = [];
            for(let i = 0; i < this.columns; i++) {
                const year = date.year();
                const month = date.month() + 1;
                data.push(...this.data[this.selectedSearchParam]?.[year]?.[month]??[]);

                date.add({ months: 1});
            }
            return data;
        },
        calendarAttributes: function() {
            function getColor(count) {
                return `rgba(50, 200, ${255 - Math.min(255, count)}, ${Math.min(1, count/350) })`
            }

            const days = this.relevantData.map(({ date, count }) => ({
                key: date,
                dates: date,
                customData: {
                    title: count,
                    background: getColor(count),
                }
            }));

            return days;
        },
    },
    methods: {
        totalInquiredAppointments(year, month) {
            const dataForMonth = this.data?.[this.selectedSearchParam]?.[year]?.[month] ?? [];
            const sum = dataForMonth.reduce((total, { count }) => total + count, 0);
            return sum;
        },
        async loadDataForAllColumns() {
            for(let i = 0; i < this.columns; i++) {
                this.loadData(this.calendarFromPage.year, this.calendarFromPage.month + i);
            }
        },
        async loadData(year, month) {
            this.loading = true;

            // Wrap around as necessary
            // momentjs' month is 0-based, but we need 1-based
            const date = moment.utc({ year, month: month - 1 });
            year = date.year();
            month = date.month() + 1;
            if (!this.data[this.selectedSearchParam]) {
                Vue.set(this.data, this.selectedSearchParam, {});
            }
            if (!this.data[this.selectedSearchParam][year]) {
                Vue.set(this.data[this.selectedSearchParam], year, {})
            }
            if (this.data[this.selectedSearchParam][year][month] != undefined) {
            }
            else {
                try {
                    const response = await axios.get(`/api/appointments/count/${this.selectedSearchParam}/${year}/${month}`);
                    if (response.status == 200) {
                        Vue.set(this.data[this.selectedSearchParam][year], month, response.data)
                    } else {
                        console.error("Failed to load data for", {year, month}, response.status, response.statusText, response);
                        this.alertError(`Failed to load data for: ${year, month}`);
                    }
                } catch (error) {
                    this.alertError()
                }
            }


            this.loading = false;
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    async mounted() {
        await this.loadDataForAllColumns();
    },
    watch: {
        /** Loads the data for the selected year when the calendar UI is navigated */
        "calendarFromPage.month":  async function(current, prev) {
            await this.loadDataForAllColumns();
        },
        /** Loads the data for the selected searchParam when the dropdown is changed */
        async selectedSearchParam(newValue){
            await this.loadDataForAllColumns();
        }
    },
};
</script>


<style>
    .overflow-visible, /** To allow the vc-date-picker of holiday-row to be completely visible. Must not be scoped! */
    .overflow-visible .v-data-table__wrapper  /** To allow the state-multi-select dropdown to be visible. */
    {
        overflow: visible !important;
    }

    .flex-col {
        flex-direction: column;
    }
    .h-full {
        height: 100%
    }
    .z-10 {
        z-index: 10;
    }
    .flex-grow {
        flex-grow: 1;
    }
    .day-day {
        color: white;
        background-color: gray;
    }
    .day-label {
        color: black;
    }
    .day-day, .day-label {
        text-align: center;
    }
</style>