var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel panel-default" }, [
    _vm.title
      ? _c("div", { staticClass: "panel-heading" }, [
          _c("h3", { staticClass: "panel-title" }, [_vm._v(_vm._s(_vm.title))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-7" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("Trennzeichen:  "),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.listDelimiter,
                    expression: "listDelimiter",
                  },
                ],
                attrs: { type: "radio", value: "," },
                domProps: { checked: _vm._q(_vm.listDelimiter, ",") },
                on: {
                  change: function ($event) {
                    _vm.listDelimiter = ","
                  },
                },
              }),
              _vm._v("\n                        Komma\n                    "),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio-inline" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.listDelimiter,
                    expression: "listDelimiter",
                  },
                ],
                attrs: { type: "radio" },
                domProps: {
                  value: "\n",
                  checked: _vm._q(_vm.listDelimiter, "\n"),
                },
                on: {
                  change: function ($event) {
                    _vm.listDelimiter = "\n"
                  },
                },
              }),
              _vm._v(
                "\n                        Neue Zeile\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-5" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-sm pull-right",
                attrs: { type: "button" },
                on: { click: _vm.onCopyToClipboard },
              },
              [
                _vm._v(
                  "\n                        In die Zwischenablage kopieren\n                    "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.joinedList,
            expression: "joinedList",
          },
        ],
        staticClass: "form-control",
        attrs: { rows: "20" },
        domProps: { value: _vm.joinedList },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.joinedList = $event.target.value
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }