var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          attrs: { loading: _vm.loading, primary: "", dense: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleDialog.apply(null, arguments)
            },
          },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("\n            mdi-plus\n        "),
          ]),
          _vm._v("\n        Tag hinzufügen\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "vuetify-wrapper overflow-visible" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("\n                Tag hinzufügen\n            "),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-simple-table", {
                    staticClass: "overflow-y-visible",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("th", [_vm._v("Datum")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Öffentlich")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Spezial")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Name")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Bundesländer")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _c("holiday-row", {
                                  attrs: {
                                    holiday: _vm.holiday,
                                    states: _vm.states,
                                    selectableYear: _vm.year,
                                    editableName: "",
                                    editableType: "",
                                    highlightModified: false,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Abbrechen\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "save",
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Speichern\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }