var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.tab } },
    [
      _c(
        "v-card",
        { staticClass: "v-application appointments-overview" },
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "search-appointments",
                attrs: {
                  "append-icon": "mdi-magnify",
                  placeholder: "Suche nach Datum, Name der Apotheke",
                  "single-line": "",
                  "hide-details": "",
                  clearable: "",
                  autofocus: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "v-application--is-ltr",
            attrs: {
              headers: _vm.headers,
              items: _vm.tableRows,
              "mobile-breakpoint": "670",
              "items-per-page": 25,
              page: _vm.pageNumber,
              options: _vm.tableOptions,
              "no-data-text": "Keine Suchergebnisse",
              "server-items-length": _vm.appointmentsCount,
              loading: _vm.isLoading,
              "loading-text": "Wird geladen...",
              "show-expand": "",
              expanded: _vm.expanded,
              "item-key": "_id",
              "footer-props": {
                "items-per-page-options": [5, 10, 25, 50],
                "show-first-last-page": true,
                "show-current-page": true,
                "items-per-page-text": "Zeilen pro Seite",
              },
              "disable-sort": "",
            },
            on: {
              "update:page": function ($event) {
                _vm.pageNumber = $event
              },
              "update:options": [
                function ($event) {
                  _vm.tableOptions = $event
                },
                _vm.onOptionsChange,
              ],
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item, expand, isExpanded }) {
                  return [
                    _c("table-row", {
                      attrs: {
                        appointment: item,
                        expand: expand,
                        isExpanded: isExpanded,
                        wfState: _vm.tab,
                        groupAppointments: _vm.groups[item.group_id],
                      },
                      on: {
                        "update-group-travelcosts": ({
                          appointmentId,
                          travelcosts,
                        }) =>
                          _vm.updateGroupTravelcosts(
                            item.group_id,
                            appointmentId,
                            travelcosts
                          ),
                        "update-appointments": _vm.updateAppointments,
                      },
                    }),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "group-container",
                        attrs: { colspan: headers.length },
                      },
                      [
                        _c("v-data-table", {
                          attrs: {
                            headers: headers,
                            items: _vm.groups[item["group_id"]],
                            "hide-default-header": "",
                            "hide-default-footer": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function ({ item, expand, isExpanded }) {
                                  return [
                                    _c("table-row", {
                                      attrs: {
                                        appointment: item,
                                        expand: expand,
                                        isExpanded: isExpanded,
                                        wfState: _vm.tab,
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }