var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    [
      _c(
        "v-btn",
        {
          staticClass: "first-row-time",
          attrs: { disabled: _vm.disabled, outlined: "" },
          on: {
            click: function ($event) {
              _vm.showDialog = true
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.dialogBtnLabel) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-alert", { attrs: { outlined: "", type: "warning" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.dialogAlert) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "confirm",
                      attrs: { color: "primary" },
                      on: { click: _vm.confirmTimeChange },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-check"),
                      ]),
                      _vm._v("\n                    Ja\n                "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cancel"),
                      ]),
                      _vm._v(
                        "\n                    Abbrechen\n                "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }