var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row gx-1" }, [
    _c("div", { staticClass: "col-auto" }, [
      !_vm.hide_invalid_button
        ? _c(
            "form",
            {
              attrs: {
                name: "invalid-appointments-form",
                id: "invalid-appointments-form",
                action: _vm.markAppointmentsInvalidAction,
                method: "POST",
                role: "form",
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.appointmentIds,
                    expression: "appointmentIds",
                  },
                ],
                attrs: { type: "hidden", name: "aids" },
                domProps: { value: _vm.appointmentIds },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.appointmentIds = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-warning", attrs: { type: "submit" } },
                [_vm._v("als ungültig markieren")]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-auto" }, [_c("batch-update-prices")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }