var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "no-approval-alert",
      attrs: {
        dense: "",
        outlined: "",
        type: "warning",
        icon: "mdi-alert-circle",
      },
    },
    [
      _c("div", { staticClass: "alert-text" }, [
        _vm._v(
          "Die Apotheke erlaubt keine direkt Zusage. Wird der Termin einem Freiberufler vorgeschlagen, kann dieser diesen nicht direkt zusagen."
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }