<template>
    <div>
        <label for="whatsapp-offers">
            E-Mail Format
            <a href="javascript:navigator.clipboard.writeText(document.getElementById('email-offers').value);">
                <i class="fas fa-copy"></i>
                in die Zwischenablage kopieren
            </a>
        </label>
        <textarea 
            id="email-offers"
            autofocus
            onfocus="this.select()"
            class="form-control"
            rows="10"
            v-model="emailFormatting"
        >
        </textarea>
        <br/>
        <label for="whatsapp-offers">
            WhatsApp Format
            <a href="javascript:navigator.clipboard.writeText(document.getElementById('whatsapp-offers').value);">
                <i class="fas fa-copy"></i>
                in die Zwischenablage kopieren
            </a>
        </label>
        <textarea 
            id="whatsapp-offers"
            onfocus="this.select()"
            class="form-control"
            rows="10"
            v-model="whatsAppFormatting"
        >
        </textarea>
    </div>
</template>

<script>
export default {
    name: 'AppointmentClipboardList',
    props: {
        values: Array,
    },
    data: function() {
        return {
            list: this.values ? JSON.parse(this.values) : [],
        };
    },
    computed: {
        emailFormatting() {
            return this.list.map(({ start, end, pharmacy, nightshift }) => { 
                return `${start}${end ? ` - ${end}` : ''}${nightshift && !end ? ' (ND) ' : ' '}${pharmacy}`
            }).join('\n')
        },
        whatsAppFormatting() {
            return this.list.map(({ start, end, pharmacy, nightshift }) => {
                return `${start}${end ? ` - ${end}` : ''}${nightshift && !end ? ' (ND)' : ''}\n${pharmacy}`
            }).join('\n\n')
        },
    },
    methods: {
        onCopyToClipboard: function(e) {
            navigator.clipboard.writeText(this.joinedList);
        },
    },
};
</script>

<style scoped lang="css">

textarea.form-control {
    height: 32vh !important;
}
</style>
