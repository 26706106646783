<template>
    <v-dialog
        v-model="showDialog"
        max-width="600px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title>
                Eventuelle Terminüberschneidung
            </v-card-title>
            <v-card-text>
                Folgende Termine der Apotheke überschneiden sich eventuell:
                <v-list>
                    <v-list-item
                        v-for="appointment in clashes" :key="appointment._id"
                        dense
                    >
                        <a :href="appointment.url">{{ formatDateString(appointment.date) }} - {{ appointment.time }}</a>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-text>
                Termine, die eingetragen werden sollen:
                <v-list>
                    <v-list-item
                        v-for="appointment in appointments" :key="appointment._id"
                        dense
                    >
                        {{ formatDateString(appointment.date) }} - {{ appointment.time }}
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-text>
                Wollen Sie die Termine trotzdem eintragen?
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="confirm"
                    class="confirm"
                    color="primary"
                    > Trotzdem eintragen
                </v-btn>
                <v-btn @click="showDialog = false">
                    <v-icon left>mdi-cancel</v-icon>
                    Abbrechen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';


export default {
    name: 'AppointmentsClashesDialog',
    components: { },
    props: {
        clashes: Array,
        appointments: Array,
    },
    data: () => ({}),
    computed: {
        showDialog: {
            get() {
                return !!this.clashes?.length;
            },
            set(value) {
                this.$emit("abort");
            }
        }
    },
    watch: {
    },
    methods: {
        confirm() {
            this.$emit("confirm");
        },
        formatDateString(isoformat) {
            return moment(isoformat).format("DD.MM.YYYY");
        },
    }
};
</script>

<style scoped>
.confirm {
        margin: 0 10px;
        background-color: #337ab7 !important;
    }
</style>
