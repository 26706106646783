import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'de',
    messages: {
        de: {
            errors: {
                common: 'Ein Fehler ist aufgetreten.',
            },
            // services
            substitution: 'Apotheker/in',
            pta: 'PTA',
            pka: 'PKA',
        }
    },
    dateTimeFormats: {
        de: {
            short: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'short'
            },
            long: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'short',
                hour: '2-digit',
                minute: '2-digit'
            },
            time: {
                hour: '2-digit',
                minute: '2-digit'
            },
            monthYear: {
                month: 'long',
                year: 'numeric'
            }
        }
    },
    numberFormats: {
        'de-DE': {
            currency: {
                style: 'currency',
                currency: 'EUR'
            }
        },
    },
    silentFallbackWarn: true,
    silentTranslationWarn: false,
})

export default i18n
