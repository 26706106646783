var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper v-application" },
    [
      _vm._m(0),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-tabs",
            {
              attrs: { grow: "", "slider-color": "primary" },
              model: {
                value: _vm.tabNumber,
                callback: function ($$v) {
                  _vm.tabNumber = $$v
                },
                expression: "tabNumber",
              },
            },
            _vm._l(_vm.tabs, function (tab) {
              return _c("v-tab", { key: tab.value }, [
                _vm._v("\n            " + _vm._s(tab.text) + "\n        "),
              ])
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tabNumber,
            callback: function ($$v) {
              _vm.tabNumber = $$v
            },
            expression: "tabNumber",
          },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "v-tab-item",
            { key: tab.value },
            [_c("pharmacies-overview-table", { attrs: { tab: tab.value } })],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "v-application" }, [
      _c("h2", { staticClass: "my-5" }, [_vm._v("Apotheken")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }