var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.emailFormatting,
          expression: "emailFormatting",
        },
      ],
      staticClass: "form-control",
      attrs: {
        id: "email-offers",
        autofocus: "",
        onfocus: "this.select()",
        rows: "10",
      },
      domProps: { value: _vm.emailFormatting },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.emailFormatting = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.whatsAppFormatting,
          expression: "whatsAppFormatting",
        },
      ],
      staticClass: "form-control",
      attrs: { id: "whatsapp-offers", onfocus: "this.select()", rows: "10" },
      domProps: { value: _vm.whatsAppFormatting },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.whatsAppFormatting = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "whatsapp-offers" } }, [
      _vm._v("\n        E-Mail Format\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "javascript:navigator.clipboard.writeText(document.getElementById('email-offers').value);",
          },
        },
        [
          _c("i", { staticClass: "fas fa-copy" }),
          _vm._v("\n            in die Zwischenablage kopieren\n        "),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "whatsapp-offers" } }, [
      _vm._v("\n        WhatsApp Format\n        "),
      _c(
        "a",
        {
          attrs: {
            href: "javascript:navigator.clipboard.writeText(document.getElementById('whatsapp-offers').value);",
          },
        },
        [
          _c("i", { staticClass: "fas fa-copy" }),
          _vm._v("\n            in die Zwischenablage kopieren\n        "),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }