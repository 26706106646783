<template>
    <div class="vuetify-wrapper v-application">
        <v-row>
            <v-col v-if="locationSearchParam" cols="12">
                <v-alert
                    outlined
                    color="warning"
                    text
                >
                    <span>
                        Suchergebnisse von <b>{{ locationSearchParam }}</b> mit dem Radius
                    </span>
                    <v-text-field
                        v-model="radius"
                        type="number"
                        min="0"
                        class="radius-input"
                        hide-details
                        outlined
                        dense
                    />
                    <span>km für aktive Freiberufler.</span>
                </v-alert>
            </v-col>
            <v-col v-if="!locationSearchParam" cols="3" class="v-application v-application--is-ltr">
                <!-- todo this should be removed at a later point in time. Instead we should likely use tabs like the PharmaciesOverview -->
                <v-select
                    class="mt-4"
                    v-model="selectedUserType"
                    :items="userTypes"
                    label="Freiberufler Status"
                    outlined
                    dense
                ></v-select>
            </v-col>
        </v-row>
        <v-card class="v-application pharmacists-overview">
            <v-card-title v-if="!locationSearchParam">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suche nach Name, Adresse, Kundennummer etc."
                    single-line
                    hide-details
                    clearable
                    autofocus
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="tableHeader"
                :items="pharmacists"
                :options.sync="tableOptions"
                class="v-application--is-ltr"
                mobile-breakpoint="992px"
                no-data-text="Keine Suchergebnisse"
                @update:options="onOptionsChange"
                :loading="isLoading"
                loading-text="Wird geladen..."
                :server-items-length="count"
                :items-per-page="5"
                :footer-props="{'items-per-page-options':[5, 10, 25, 50], 'show-first-last-page': true, 'show-current-page': true, 'items-per-page-text': 'Zeilen pro Seite'}"
            >
                <template v-slot:item.avatar="{ item }">
                    <v-avatar size="40">
                        <object :data="`/media/avatar/${item._id}/small`" type="image/jpg">
                            <v-icon size="40">mdi-account-circle</v-icon>
                        </object>
                    </v-avatar>
                </template>
                <template v-slot:item.lastname="{ item }">
                    <a :href="`/pharmacists/${item._id}`" :data-itemid="item._id" class="name">
                        {{item.lastname}}, {{item.firstname}}
                    </a>
                    <a
                        :href="`/pharmacies/?city=${item.billing_zip}`"
                        target="_blank"
                    >
                        <v-icon small color="blue-grey">mdi-earth</v-icon>
                    </a>
                    <a
                        :href="`/appointment_offer?userid=${item._id}&perimeter=100`"
                        target="_blank"
                    >
                        <v-icon small color="blue-grey">mdi-magnify</v-icon>
                    </a>
                    <br />
                    <span>{{ item.customer_id }}</span>
                    <v-chip
                        v-for="service in item.services"
                        :color='renderServiceColor(service)'
                        class="mr-1 px-2"
                        dark
                        x-small
                        label
                    >
                        <b>{{ service == 'substitution' ? 'Apotheker/in' : service.toUpperCase() }}</b>
                    </v-chip>
                </template>
                <template v-slot:item.created="{ item }">
                    {{ formatDate(item.created) }}
                </template>
                <template v-slot:item.billing_city="{ item }">
                    {{ item.billing_city }}{{ ", " + item.billing_zip }}
                </template>
                <template v-slot:item.readable_permissions="{ item }">
                    <div v-for="permission in readablePermissions(item.readable_permissions)">
                        {{ permission }}
                    </div>
                </template>
                <template v-slot:item.action_buttons="{ item }">
                    <div class="action-buttons" :data-itemid="item._id">
                        <v-btn
                            :href="`/users/admin/${item._id}`"
                            color="cyan lighten-3"
                            class="mr-2"
                            dark
                            small
                        >
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="!item.active"
                            @click="activatePharmacist(item._id)"
                            color="green lighten-1"
                            small
                            :disabled="activatedUserIds.includes(item._id)"
                            :dark="!activatedUserIds.includes(item._id)"
                        >
                            Aktivieren
                        </v-btn>
                        <v-btn
                            v-else
                            @click="sendPassword(item._id)"
                            small
                            class="reset-password"
                        >
                            Sende passwort
                        </v-btn>
                    </div>
                </template>
                <template v-slot:item.distance="{ item }">
                    <div :data-id="item._id" class="distance">
                        {{ Math.round(item.distance) }} km
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>


<script>
    import axios from 'axios';
    import moment from 'moment';
    import _ from 'lodash';
    import { mapMutations } from 'vuex';

    const permissionsTranslations = {
        'admin': 'Benutzeradministrator',
        'contractor': 'Freiberufler',
        'appointment_offers': 'Terminangebote für FA',
        'developer': 'Software-Entwickler',
        'invoices': 'Steuerbüro',
        'pharmacy': 'Apothekeninhaber',
        'userbase:admin': 'Administrator',
    }

    export default {
        props: {},
        data: () => ({
            search: '',
            tableHeader: [
                { text: '', value: 'avatar', filterable: false, sortable: false, width: '40' },
                { text: 'Name', value: 'lastname' },
                { text: 'Angelegt', value: 'created' },
                { text: 'Kommentar', value: 'comment' },
                { text: 'ORT', value: 'billing_city' },
                { text: 'Berechtigungen', value: 'readable_permissions' },
                { text: '', value: 'action_buttons', filterable: false, sortable: false },
            ],
            users: [],
            userCount: 0,
            searchResults: [],
            searchResultsCount: 0,
            userTypes: [
                { text: 'Aktive Freiberufler', value: 'active' },
                { text: 'Inaktive Freiberufler', value: 'inactive' }
            ],
            selectedUserType: 'active',
            isLoading: false,
            tableOptions: {},
            activatedUserIds: [],
            locationSearchParam: '',
            radius: 100
        }),
        methods: {
            async fetchPharmacistCount(pharmacistType) {
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/pharmacists/${pharmacistType}/count`);
                    this.userCount = response.data;
                } catch {
                    this.alertError()
                    console.warn("error while fetching pharmacist-count", error);
                } finally {
                    this.isLoading = false
                }
            },
            async fetchPharmacists(pharmacistType) {
                try {
                    this.isLoading = true;

                    const sortBy = this.tableOptions.sortBy[0] ?? 'lastname';
                    const sortAsc = this.tableOptions.sortDesc[0] ? -1 : 1;
                    const response = await axios.get(`/api/pharmacists/${pharmacistType}/paginated`, {
                        params: {
                            page: this.tableOptions.page,
                            page_size: this.tableOptions.itemsPerPage,
                            sort_by: sortBy,
                            sort_asc: sortAsc
                        }
                    });
                    this.users = response.data;
                } catch(error) {
                    this.alertError()
                    console.warn("error while fetching pharmacists", error);
                } finally {
                    this.isLoading = false
                }
            },
            formatDate(date) {
                if (!date) return ''
                return moment(date).format('DD-MM-yyyy HH:mm')
            },
            async sendPassword(id) {
                try {
                    const response = await axios.post(`/send_pw/${id}`);
                    this.alertSuccess(response.data.message);
                } catch (e) {
                    this.alertError()
                }
            },
            async activatePharmacist(id) {
                try {
                    const response = await axios.post(`/api/pharmacists/${id}/activate/true`);
                    this.activatedUserIds.push(id);
                    this.alertSuccess(response.data.message);
                } catch (e) {
                    this.alertError()
                }
            },
            searchPharmacists: _.debounce(async function (page=1, pageSize=5) {
                if (!this.search && !this.locationSearchParam) return

                const params = this.locationSearchParam ? {
                    query: this.locationSearchParam,
                    location_search: true,
                    radius: this.radius
                } : {
                    query: this.search,
                    page,
                    page_size: pageSize
                }

                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/pharmacists/${this.selectedUserType}/search`, { params });
                    if (response.data.status == 500) throw new Error();
                    if (this.locationSearchParam) {
                        this.searchResults = response.data;
                    } else {
                        this.searchResults = response.data.results;
                        this.searchResultsCount = response.data.total_results;
                        this.tableOptions.page = page;
                        this.tableOptions.itemsPerPage = pageSize
                    }
                } catch (e) {
                    this.alertError()
                } finally {
                    this.isLoading = false
                }
            }, 200),
            renderServiceColor(service) {
                switch (service) {
                    case 'substitution':
                        return 'cyan lighten-2'
                    case 'pta':
                        return 'orange'
                    case 'pka':
                        return 'green'
                    default:
                        return 'cyan lighten-2'
                }
            },
            readablePermissions(permissions) {
                return permissions.map(permission => permissionsTranslations[permission])
            },
            async onOptionsChange({ page, itemsPerPage }) {
                if (this.locationSearchParam) return
                if (this.search) {
                    this.searchPharmacists(page, itemsPerPage)
                } else {
                    this.fetchPharmacists(this.selectedUserType)
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
                alertSuccess: 'alert/success'
            })
        },
        watch: {
            async search(text) {
                if (!text.trim().length) {
                    this.searchResults = [];
                    return
                }
                await this.searchPharmacists()
            },
            async selectedUserType() {
                await this.fetchPharmacistCount(this.selectedUserType);
                if (this.tableOptions.page != 1) {
                    // this triggers fetchPharmacists through onOptionsChange
                    this.tableOptions.page = 1;
                } else {
                    this.fetchPharmacists(this.selectedUserType);
                }

                if (this.search) {
                    this.searchPharmacists();
                }
            },
            radius() {
                this.searchPharmacists()
            }
        },
        computed: {
            pharmacists() {
                if (this.search || this.locationSearchParam) return this.searchResults;
                return this.users;
            },
            count() {
                if (this.locationSearchParam) return -1
                return this.search ? this.searchResultsCount : this.userCount;
            }
        },
        async mounted() {
            const cityParam = new URLSearchParams(window.location.search).get('city');
            if (cityParam) {
                const distanceColumn = { text: 'Entfernung', value: 'distance' };
                this.tableHeader.splice(5, 0, distanceColumn);
                this.locationSearchParam = cityParam;
                this.searchPharmacists();
                this.tableOptions.sortBy = ['distance']
                return
            }
            await this.fetchPharmacistCount(this.selectedUserType);
        }
    }
</script>

<style scoped>
    .action-buttons {
        min-width: 240px;
    }
    .radius-input {
        display: inline-block !important;
        width: 80px;
    }
</style>