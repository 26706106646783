(function($) {
    $(document).ready(function() {

        /* statistics filter all button functionality */
        $('.states-filter-all').on('change', function() {
            if ($(this).prop('checked')) {
                $('#states-filter-form :checkbox').prop('checked', false);
            }
        });
        $('#states-filter-form :checkbox').on('change', function() {
            if (!$(this).closest('form').serialize()) {
                $('.states-filter-all').prop('checked', true);
            } else {
                $('.states-filter-all').prop('checked', false);
            }
        });

    });

})(jQuery); // Fully reference jQuery after this point.

