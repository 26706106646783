<template>
    <div id="stickyinfo" v-if="sum > 0">
        <h3 class="popover-title">{{ title }}</h3>
        <div class="popover-content">
            <div class="row">
                <div class="col-md-6"><strong class="pull-right">Betrag</strong></div>
                <div class="col-md-6">{{ formatted(total) }}</div>
            </div>
            <div class="row">
                <div class="col-md-6"><strong class="pull-right">Fahrtkosten</strong></div>
                <div class="col-md-6">{{ formatted(travelcosts) }}</div>
            </div>
            <div class="row" v-if="mediation_charge > 0">
                <div class="col-md-6"><strong class="pull-right">Vermittlung (Apotheke)</strong></div>
                <div class="col-md-6">{{ formatted(mediation_charge) }}</div>
            </div>
            <div class="row" v-if="other_costs > 0">
                <div class="col-md-6"><strong class="pull-right">Sonstiges (Freiberufler)</strong></div>
                <div class="col-md-6">{{ formatted(other_costs) }}</div>
            </div>
            <hr class="mini" />
            <div class="row">
                <div class="col-md-6"><strong class="pull-right">Summe</strong></div>
                <div class="col-md-6 sum"><strong>{{ formatted(sum) }}</strong></div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from './../../utils'

export default {
    name: 'StickyNote',
    props: ['type'],
    data: function () {
        return {
            total: 0,
            travelcosts: 0,
            mediation_charge: 0,
            other_costs: 0,
            title: ''
        }
    },
    computed: {
        sum: function () {
            return this.total + this.travelcosts + this.mediation_charge + this.other_costs
        },
        appointmentIds: function () {
            return this.$store.state.selectedRowIds
        }
    },
    watch: {
        appointmentIds: function () {
            var that = this;
            if (this.appointmentIds.length > 0) {
                this.$http
                    .get('/api/appointments', {
                        params: {
                            a_ids: JSON.stringify(this.appointmentIds)
                        }
                    })
                    .then(function(response) {
                        that.calculateSum(response.data.appointments)
                        that.title = response.data.appointments.length + ' Termin(e) ausgewählt'
                    })
            } else {
                that.calculateSum([])
                that.title = ''
            }
        }
    },
    methods: {
        formatted: function(number) {
            return utils.format_currency(number)
        },
        calculateSum: function (appointments) {
            this.total = 0
            this.travelcosts = 0
            this.mediation_charge = 0
            this.other_costs = 0
            for (var i=0; i<appointments.length; i++ ) {
                var a = appointments[i];
                if (a.billing == 'hours') {
                    if (this.type == 'ma') {
                        this.total += a.quantity * a.hourly_ma_rate
                    } else {
                        this.total += a.quantity * a.hourly_apo_rate
                    }
                } else {
                    if (this.type == 'ma') {
                        this.total += a.flat_amount
                    } else {
                        this.total += a.quantity
                    }
                }
                this.travelcosts += a.travelcosts
                if (this.type != 'ma') {
                    this.mediation_charge += a.mediation_charge
                }
                this.other_costs += a.other_costs
            }
        }
    }
}
</script>
