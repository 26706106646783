var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          staticClass: "add-source",
          attrs: { "x-small": "", outlined: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              _vm.isDialogOpen = true
            },
          },
        },
        [
          _c("v-icon", { attrs: { small: "", color: "black" } }, [
            _vm._v("mdi-plus"),
          ]),
          _vm._v("\n        Quelle hinzufügen\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "vuetify-wrapper v-application",
            width: "350",
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("\n                Quelle hinzufügen\n            "),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-select", {
                    staticClass: "select-source",
                    attrs: {
                      items: _vm.sourceNamesOptions,
                      placeholder: "Quelle hinzufügen",
                      dense: "",
                      outlined: "",
                      clearable: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.sourceName,
                      callback: function ($$v) {
                        _vm.sourceName = $$v
                      },
                      expression: "sourceName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.isDialogOpen = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cancel"),
                      ]),
                      _vm._v(
                        "\n                    Abbrechen\n                "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "submit ml-3",
                      attrs: {
                        color: "primary",
                        small: "",
                        disabled: !_vm.sourceName?.trim().length,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Speichern\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }