var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group", attrs: { id: "field-erp-exprtise" } },
    [
      _c(
        "label",
        {
          staticClass: "control-label col-md-2",
          attrs: { for: "erp-exprtise" },
        },
        [_vm._v("WWS")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.erps_list, function (erp) {
            return _c(
              "div",
              { key: erp, staticClass: "row" },
              [
                _c("div", { staticClass: "col-md-3" }, [_vm._v(_vm._s(erp))]),
                _vm._v(" "),
                _vm._l(["0", "1", "2", "3"], function (i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      class: "col-md-" + _vm.col_widths[i] + " text-center",
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form[erp],
                            expression: "form[erp]",
                          },
                        ],
                        attrs: { type: "radio" },
                        domProps: {
                          value: i,
                          checked: _vm._q(_vm.form[erp], i),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, erp, i)
                          },
                        },
                      }),
                    ]
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formJSON,
            expression: "formJSON",
          },
        ],
        attrs: { type: "hidden", name: "erp_expertise" },
        domProps: { value: _vm.formJSON },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.formJSON = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-3" }),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-1" }, [
        _c("small", [_c("b", [_vm._v("nicht bekannt")])]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-2" }, [
        _c("small", [
          _c("b", [_vm._v("Ich hab das Programm noch nie gehabt.")]),
          _c("br"),
          _vm._v("Ich komme aber schnell rein."),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [
        _c("small", [
          _c("b", [_vm._v("Ich habe schon mit dem Programm gearbeitet.")]),
          _c("br"),
          _vm._v(
            "Ich erledige einen normalen Abverkauf und Rezeptbedienung mit Rabattartikeln. Bei anspruchsvolleren Vorgängen komme ich nach einer kurzen Zeit wieder rein."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3" }, [
        _c("small", [
          _c("b", [
            _vm._v(
              "Ich habe oft mit dem Programm gearbeitet und kenne mich aus."
            ),
          ]),
          _c("br"),
          _vm._v(
            "Ich erledige auch detailliertere Vorgänge wie Stornierung, Abholer, Schicker etc."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }