<template>
    <v-alert
    dense
    outlined
    type="warning"
    icon="mdi-alert-circle"
    class="no-approval-alert"
    >
        <div class="alert-text">Die Apotheke erlaubt keine direkt Zusage. Wird der Termin einem Freiberufler vorgeschlagen, kann dieser diesen nicht direkt zusagen.</div>
    </v-alert>
</template>
<script>
export default {}
</script>
<style scoped>
    .no-approval-alert {
        margin: 1rem;
        width: initial;
    }
    .alert-text {
        padding: 0 0.5rem !important;
    }
</style>