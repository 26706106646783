var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          staticClass: "fb-button",
          class: { "bootstrap-button": _vm.isjinja2 },
          attrs: {
            disabled: _vm.parsedDisabled,
            "min-width": "40",
            outlined: "",
            text: "",
            small: "",
            icon: _vm.isjinja2 ? true : false,
            title: "Verfügbare Freiberufler vorschlagen",
          },
          on: {
            click: function ($event) {
              _vm.isAvailablepharmacistsDialogOpen = true
            },
          },
        },
        [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("mdi-account")])],
        1
      ),
      _vm._v(" "),
      _c("AvailablePharmacistsDialog", {
        attrs: {
          pharmacy:
            typeof _vm.pharmacy === "string"
              ? JSON.parse(_vm.pharmacy)
              : _vm.pharmacy,
          appointment:
            typeof _vm.appointment === "string"
              ? JSON.parse(_vm.appointment)
              : _vm.appointment,
          services:
            typeof _vm.services === "string"
              ? JSON.parse(_vm.services)
              : _vm.services,
          date: _vm.formatDate(_vm.date),
          wfState: _vm.wfState,
        },
        on: { "selected-user": _vm.onPharmacistSelected },
        model: {
          value: _vm.isAvailablepharmacistsDialogOpen,
          callback: function ($$v) {
            _vm.isAvailablepharmacistsDialogOpen = $$v
          },
          expression: "isAvailablepharmacistsDialogOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }