<template>
<div class="v-application vuetify-wrapper">
    <div class="filter-container">
        <p class="border-b spacing-y-1 title">Filter </p>
        <div class="inline-flex border-b spacing-y-4 spacing-x-12">
            <v-text-field
                v-model="filter.title"
                placeholder="Titel"
                dense
                outlined
                hide-details
                clearable
                @click:clear="filter.title=''"
                >
                <template slot="prepend-inner">
                    <v-icon color="info"  @click="onFilterChanged" v-if="filter.filter_type==='contains'">mdi-equal</v-icon>
                    <v-icon color="info"  @click="onFilterChanged" v-else>mdi-not-equal</v-icon>
                </template>
            </v-text-field>
            <v-select
                placeholder="Zustand"
                v-model="filter.wf_state"
                :items="statusOptions"
                hide-details
                outlined
                dense
            ></v-select>
        </div>
        <div class="flex-center">
            <v-btn @click="filterTickets()" outlined dense hide-details>
                <v-icon small start>
                    {{ isFiltered ? 'mdi-filter-check' : 'mdi-filter'}}
                </v-icon>
                Filter
            </v-btn>
            <v-btn @click="clearFilter" :disabled="!isFiltered" hide-details plain>
                <v-icon small start>mdi-close</v-icon>
                Filter zurücksetzen
            </v-btn>
        </div>
    </div>
    <div class="filter-container">
        <v-data-table
            :headers="headers"
            :items="ticketsList"
            :items-per-page="10"
            :page.sync="pageNumber"
            :options.sync="tableOptions"
            :server-items-length="ticketsCount"
            item-key="_id"
            :loading="isLoading"
            :expanded.sync="expanded"
            @update:options="onOptionsChange"
            class="v-application--is-ltr"
            no-data-text="Keine Suchergebnisse"
            loading-text="Wird geladen..."
            disable-sort
            :footer-props="{'items-per-page-options':[5, 10, 25, 50], 'show-first-last-page': true, 'show-current-page': true, 'items-per-page-text': 'Zeilen pro Seite'}"
        >
            <template v-slot:item="{ item, expand, isExpanded }">
                <tr :data-id="item?._id" class="spacing-y-4">
                    <td>
                        {{item?._id}}
                    </td>
                    <td>
                        {{workflow_states[item.wf_state]}}
                    </td>
                    <td class="col-md-3">
                        <div class="flex-col">
                            <a :href="`/pharmacies/${item?.ref_pharmacy?._id}`" target="_blank">
                                {{item?.ref_pharmacy?.name || ""}}
                            </a>
                            <a :href="`/pharmacists/${item?.ref_pharmacist?._id}`" target="_blank">
                                {{item?.ref_pharmacist?.firstname || ""}} {{item?.ref_pharmacist?.lastname || ""}}
                            </a>
                            <a :href="`/pharmacies/${item.ref_pharmacy?._id}/${item?.ref_appointment?._id}/tickets`" target="_blank">
                                {{item?.ref_appointment?.date || ""}}
                            </a>
                        </div>
                    </td>
                    <td class="col-md-5">
                        {{item.title}}
                    </td>
                    <td class="col-md-1">
                        <v-btn icon @click="expand(!isExpanded)">
                            <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                }}</v-icon>
                        </v-btn>
                    </td>
                    <td class="col-md-1">
                        {{categories[item.category]}}
                    </td>
                    <td class="col-md-3">
                        {{ getMomentDate(item.created) }}
                    </td>
                    <td class="col-md-2">
                        <a :href="`/pharmacists/${item?.ref_pharmacist?._id}`" target="_blank">
                            {{item?.creator?.firstname}}
                        </a>
                    </td>
                    <td class="col-md-2">
                        {{item?.resubmit}}
                    </td>
                    <td class="col-md-2">
                        <a :href="`/crm/${item?._id}/edit`" target="_blank">
                            <v-icon dark color="primary">
                                mdi-pencil
                            </v-icon>
                        </a>
                    </td>
                </tr>
            </template>

            <template v-slot:expanded-item="{ item }">
                <tr :data-id="item?._id">
                    <td colspan="3"></td>
                    <td colspan="2">
                        <div class="spacing-4">
                            <strong>Beschreibung:</strong>
                            <div>{{ item.description }}</div>
                        </div>
                    </td>
                    <td colspan="5"></td>
                </tr>
            </template>
        </v-data-table>
    </div>

</div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapMutations } from "vuex";
import { ticketFilters } from "../../constants";

export default {
    props: {
        tickets_json: String,
        workflow_states_json: String,
        categories_json: String,
        pharmacy_id: String,
        appointment_id: String
    },
    data: () => ({
        filter: {
            title: "",
            wf_state: "",
            filter_type: "contains"
        },
        expanded: [],
        ticketsList: [],
        ticketsCount: 0,
        pageType: "",
        pageNumber: 1,
        tableOptions: {},
        isFiltered: false,
        isLoading: false,
        statusOptions: ticketFilters.statusOptions,
        headers: ticketFilters.headers
    }),
    methods: {
        onFilterChanged() {
            this.filter.filter_type = this.filter.filter_type === "contains" ? "not" : "contains";
        },
        async filterTickets(page = 1, pageSize = 10) {
            try {
                this.isLoading = true;
                let response
                let payload = {
                    filter: this.filter,
                    page: page,
                    page_size: pageSize,
                    page_type: this.pageType
                }
                response = await axios.post(`/api/tickets`, payload);

                this.ticketsList = response.data.tickets;
                this.ticketsCount = response.data.count;
                this.tableOptions.page = page;
                this.tableOptions.itemsPerPage = pageSize;
                this.isLoading = false;
                this.isFiltered = true;
            } catch (error) {
                this.alertError();
            } finally {
                this.isLoading = false;
            }
        },
        async onOptionsChange({
            page,
            itemsPerPage
        }) {
            await this.filterTickets(page, itemsPerPage);
        },
        async clearFilter() {
            this.filter = {
                ...this.filter,
                title: "",
                wf_state: "",
                filter_type: "contains",
            }
            await this.filterTickets();
            this.isFiltered = false;
        },
        getMomentDate(created) {
            return moment.utc(created).local().format('DD-MM-yyyy HH:mm')
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    computed: {
        tickets() {
            return JSON.parse(this.tickets_json);
        },
        workflow_states() {
            return JSON.parse(this.workflow_states_json);
        },
        categories() {
            return JSON.parse(this.categories_json);
        }
    },
    async created() {
        const type = new URLSearchParams(window.location.search).get('type');
        this.pageType = type || "";
        this.filter.appointment_id =  this.appointment_id;
        this.filter.pharmacy_id = this.pharmacy_id;
        await this.filterTickets();
    }
};
</script>

<style scoped>
.container {
    width: 100%;
}

.filter-container {
    border: 1px solid #EEE;
    background: #FFFFFF;
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-radius: 8px;
    margin-bottom: 8px;
}

.inline-flex {
    display: inline-flex;
    gap: 4px;
    align-items: center;
    width: 100%;
}

.title {
    font-size: 24px;
    font-weight: 600;
}

.spacing-x-12 {
    padding-left: 48px;
    padding-right: 48px;
}

.spacing-y-4 {
    padding: 16px 0;
}

.spacing-y-1 {
    padding: 4px 0;
}

.spacing-t-2 {
    padding-top: 10px;
}

.spacing-4 {
    padding: 16px !important;
}
.w-200 {
    width: 200px;
    max-width: 200px;
}

.w-400 {
    width: 400px;
    max-width: 400px;
}

.flex-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.border-b {
    border-bottom: 1px solid #E0E0E0;
}

th {
    vertical-align: middle;
    padding: 0 10px !important;
    border-bottom: none !important;
    font-size: 15px !important;
    color: black !important;
}

</style>
