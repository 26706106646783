<template>
    <div id="holidays" class="vuetify-wrapper overflow-visible">
        <v-card outlined>
            Hier können alle Ferien und Feiertage für das Jahr {{ selectedYear }} eingesehen und bearbeitet werden.
            <br />
            Spezielle Feiertage sind etwas teurer.
            <div>
                Erst durch "Alle Änderungen für {{selectedYear}} speichern" werden die Änderungen ins System übernommen.
            </div>
            <div class="bold red">
                Durch das Laden von Vorschlägen werden alle vorherigen manuellen Änderungen für das Jahr verworfen (wenn gespeichert wird).
            </div>
        </v-card>
        <v-btn @click="selectedYear -= 1">
            <v-icon left>mdi-arrow-left</v-icon>
            {{selectedYear - 1}}
        </v-btn>
        <v-btn
            @click="loadHolidaySuggestions(selectedYear)"
            primary
            :loading="loading"
        >
            Vorschläge für {{ selectedYear }} laden
        </v-btn>

        <v-btn @click="selectedYear += 1">
            {{selectedYear + 1}}
            <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <v-btn
            @click="saveCurrentYear"
            :disabled="!wasModified"
            :loading="loading"
            primary
            dense
        >
            <v-icon left>
                mdi-content-save-all
            </v-icon>
            Alle Änderungen für {{ selectedYear }} speichern
        </v-btn>

        <add-holiday-dialog
            :loading="loading"
            :states="states"
            :year="selectedYear"
            @save="onHolidayAdded"
        />

        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <th>Datum</th>
                    <th>Öffentlich</th>
                    <th>Spezial</th>
                    <th>Name</th>
                    <th>Bundesländer</th>
                </thead>
                <tbody>
                    <holiday-row
                        v-for="holiday in selectedYearHolidays"
                        v-bind:key="holiday.date"
                        @modified="onHolidayModified"
                        :holiday="holiday"
                        :states="states"
                        highlightModified
                    />
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="99">
                            <v-btn
                                @click="saveCurrentYear"
                                :disabled="!wasModified"
                                :loading="loading"
                                primary
                                dense
                            >
                                <v-icon left>
                                    mdi-content-save-all
                                </v-icon>
                                Alle Änderungen für {{ selectedYear }} speichern
                            </v-btn>
                        </th>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<script>

import axios, { AxiosResponse } from 'axios';
import HolidayRow from './Row.vue';
import moment from 'moment';
import AddHolidayDialog from './AddHolidayDialog.vue';

function holidayByDateComparer(self, other) {
    const dateDelta = self.date - other.date;
    if (dateDelta == 0) {
        return self.type > other.type ? 1 : self.type < other.type ? -1 : 0;
    }
    return dateDelta;
}

function cloneHoliday(holiday) {
    return {
        ...holiday,
        date: moment(holiday.date),
        states: [...holiday.states],
    }
}

export default {
    name: 'HolidaysView',
    components: { HolidayRow, AddHolidayDialog },
    props: {
    },
    data: function() {
        return {
            loading: false,
            /** List of all holidays retrieved through the API */
            holidays: [],
            /** Array of [2-char abbreviation, full name] for all German states*/
            states: [],
            /** Which month is the top-left in the calendar */
            selectedYear: new Date().getFullYear(), // start with current year
            modifiedHolidays: new Set(),  // set of holiday dates that were modified
            wasModified: false,
        };
    },
    computed: {
        selectedYearHolidays: function() {
            const year = this.selectedYear;
            const holidays = this.holidays.filter(({ date }) => date.year() == year );
            return holidays.sort(holidayByDateComparer);
        },
    },
    methods: {
        sortHolidays() {
            this.holidays.sort(holidayByDateComparer);
        },
        onHolidayAdded(originalHoliday) {
            const holiday = cloneHoliday(originalHoliday);
            holiday.original = null;
            this.holidays.push(holiday);
            this.sortHolidays();

            this.onHolidayModified(holiday, true);
        },
        /**
         * @param {{ rawDate: string }} holiday Holiday Object
         * @param {Boolean} modified True if it was modified, false if not
        */
        onHolidayModified(holiday, modified) {
            if (modified) {
                this.modifiedHolidays.add(holiday.rawDate);
            } else {
                this.modifiedHolidays.delete(holiday.rawDate);
            }
            this.wasModified = this.modifiedHolidays.size > 0
        },
        /**
         * Processes the given holidays and replaces the `year`'s holidays.
         * @param {[]} rawHolidays
         */
        processHolidays(year, rawHolidays, clone=true) {
            let newHolidays = rawHolidays.map((holiday) => ({
                ...holiday,
                rawDate: moment(holiday.date).format('YYYY-MM-DD'),
                date: moment(holiday.date),
                states: holiday.states.sort(),
                // add them so they exist once the row component is created
                type: holiday.type || (holiday.name ? 'public' : 'school'),
                special: holiday.special,
                name: holiday.name,
            }));
            if (clone) {
                newHolidays = newHolidays.map(holiday => ({ ...holiday, original: cloneHoliday(holiday) }));
            }

            // Remove holidays for the requested year, as they may be outdated.
            const holidays_to_keep = this.holidays.filter(({ date }) => date.year() != year );
            this.holidays = [...holidays_to_keep, ...newHolidays];
            this.sortHolidays();
        },
        /**
         * Fetches the holidays for a specific year and applies them
         * @param {number} year
         * @param {(yeary: number) => Promise<AxiosResponse<{ states: [], holidays: [] }>>} fetchMethod
         * @param {bool} clone If true, a field 'original' with the cloned holiday is added
         */
        async loadHolidays(year, fetchMethod, clone=true) {
            if (!year || year < 2014) {
                throw new Error(`Year must be >=2014 but was '${year}'`)
            }
            try {
                this.loading = true;

                console.log("loading holidays for year", year);

                const response = await fetchMethod(year)
                const { holidays, states } = response.data;

                this.states = states;
                this.processHolidays(year, holidays, clone=clone);

                this.modifiedHolidays.clear();
            }
            catch(exc) {
                window.alert("Fehler beim Laden. Bitte einen Entwickler informieren.")
                throw exc;
            }
            finally {
                this.loading = false;
            }
        },
        async loadHolidaysFromDatabase(year) {
            await this.loadHolidays(
                year,
                async year => axios.get(`/api/holidays/${year}`),
                true
            )
        },
        async loadHolidaySuggestions(year) {
            await this.loadHolidays(
                year,
                async year => axios.get(`/api/holiday_suggestions/${year}`),
                false
            )
        },
        async saveCurrentYear() {
            const year = this.selectedYear;
            const holidaysToSave = this.selectedYearHolidays
                .map(holiday => ({
                    date: holiday.date.format('YYYY-MM-DD'),
                    type: holiday.type,
                    special: holiday.special,
                    name: holiday.name,
                    states: holiday.states,
                }));

            await this.loadHolidays(
                year,
                async year => axios.put(`/api/holidays/${year}`, { holidays: holidaysToSave }),
                true
            )
        },
    },
    async mounted() {
        await this.loadHolidaysFromDatabase(this.selectedYear);
    },
    watch: {
        /** Loads the data for the selected year when the calendar UI is navigated */
        selectedYear: async function(current, prev) {
            await this.loadHolidaysFromDatabase(current);
        },
    },
};
</script>


<style>
    .overflow-visible, /** To allow the vc-date-picker of holiday-row to be completely visible. Must not be scoped! */
    .overflow-visible .v-data-table__wrapper  /** To allow the state-multi-select dropdown to be visible. */
    {
        overflow: visible !important;
    }
</style>
<style scoped>
    .bold {
        font-weight: bold;
    }
    .red {
        color: red;
    }
</style>
