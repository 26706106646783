<template>
    <div id="inquired-appointments-per-day" class="vuetify-wrapper overflow-visible">
        <h1>
            {{ this.selectedSearchParam.text }} pro Tag
        </h1>

        <v-select
            v-model="selectedSearchParam"
            :items="searchParams"
            label="Auswahl"
            return-object
        ></v-select>

        <v-card outlined>
            Hier können "{{ this.selectedSearchParam.text }}" pro Tag/Monat miteinander verglichen werden.
        </v-card>

        <div class="calendar-row">
            <InquiredAppointmentsPerDaySingle
                :selectedSearchParam="selectedSearchParam.value"
                :initialYear="initialDateLeft.year()"
                :initialMonth="initialDateLeft.month() + 1"
            />
            <InquiredAppointmentsPerDaySingle
                :selectedSearchParam="selectedSearchParam.value"
                :initialYear="initialDateMiddle.year()"
                :initialMonth="initialDateMiddle.month() + 1"
            />
            <InquiredAppointmentsPerDaySingle
                :selectedSearchParam="selectedSearchParam.value"
            />
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import InquiredAppointmentsPerDaySingle from './InquiredAppointmentsPerDaySingle.vue'

export default {
    name: 'InquiredAppointmentsPerDay',
    components: { InquiredAppointmentsPerDaySingle },
    props: {
    },
    data: function() {
        return {
            initialDateLeft: moment().subtract({ years: 2 }),
            initialDateMiddle: moment().subtract({ years: 1 }),
            //searchParam: "inquired",
            selectedSearchParam: {
                    text: 'Angefragte Termine',
                    value: 'inquired',
            },
            searchParams: [
                {
                    text: 'Angefragte Termine',
                    value: 'inquired',
                },
                {
                    text: 'Vermittelte Termine (alle)',
                    value: 'assigned',
                },
                {
                    text: 'Vermittelte Termine (selbst zugewiesen)',
                    value: 'selfassigned',
                },
                {
                    text: 'Vermittelte Termine (Vergleichsstatistik)',
                    value: 'history',
                },
                {
                    text: 'Erfüllte Termine',
                    value: 'fullfilled',
                },
            ],
        };
    },
    computed: {
    },
    methods: {
    },
    async mounted() {
    },
    watch: {
    },
};
</script>


<style scoped>
    .calendar-row {
        display: flex;
        justify-content: left;
        margin-top: 1em;
    }
    .calendar-row > div {
        margin-right: 1em;
    }

    .calendar-row > div:last-child {
        margin-right: 0;
    }
</style>