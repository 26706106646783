<template>
    <tr :class="{ 'vuetify-wrapper': true, modified: highlightModified && wasModified}">
        <td>
            <vc-date-picker
                v-model="dateAsString"
                :min-date="selectableYear ? `${selectableYear}-01-01` : dateAsString"
                :max-date="selectableYear ? `${selectableYear}-12-31` : dateAsString"
                class="inline-block h-full date-picker"
            >
                <template v-slot="{ togglePopover }">
                    <div class="flex items-center">
                        <v-btn
                            @click="togglePopover()"
                        >
                            <v-icon left>
                                mdi-calendar
                            </v-icon>
                            {{ dateAsString }}
                        </v-btn>
                    </div>
                </template>
            </vc-date-picker>
        </td>
        <td>
            <v-checkbox
                class="select-public-holiday"
                v-model="isPublic"
                dense
                :disabled="!editableType"
            />
        </td>
        <td>
            <v-checkbox
                class="select-special-holiday"
                v-if="isPublic"
                v-model="holiday.special"
                dense
            />
        </td>
        <td>
            <v-text-field
                v-if="isPublic"
                v-model="holiday.name"
                placeholder="Bezeichnung"
                dense
                :disabled="!editableName"
            />
            <v-text-field
                v-else
                value="Schulferien"
                disabled
                dense
            />
        </td>
        <td :class="['state-select', dateAsString, editableType ? 'create-mode' : 'table-view']">
            <v-select
                v-model="holiday.states"
                :items="stateAbbreviations"
                dense
                attach
                chips
                small-chips
                multiple
            >
                <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        dense
                        @mousedown.prevent
                        @click="toggleStatesSelection"
                    >
                        <v-list-item-action>
                            <v-icon>
                                {{
                                    allStatesSelected
                                    ? 'mdi-close-box'
                                    : someStatesSelected ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline'
                                }}
                            </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="select-all">
                                {{ allStatesSelected ? 'Alle abwählen' : 'Alle auswählen' }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                    <span v-if="allStatesSelected && index==0">
                        Alle
                    </span>
                    <v-chip v-if="!allStatesSelected">
                        <span>{{ item.text }}</span>
                    </v-chip>
                </template>
            </v-select>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'HolidayRow',
    props: {
        holiday: {},
        states: [],
        /** If undefined, date won't be editable. Otherwise dates within this year are available for selection */
        selectableYear: Number,
        editableType: Boolean,
        editableName: Boolean,
        highlightModified: Boolean,  // row will be highlighted once edited
    },
    computed: {
        stateAbbreviations() {
            return this.states.map(([short, long]) => ({
                text: short.toUpperCase(),
                value: short,
            }));
        },
        dateAsString: {
            get: function() {
                return this.holiday.date.format('YYYY-MM-DD');
            },
            set: function(value) {
                if (value) {  // null means no change in date
                    this.holiday.date = moment(value);
                    this.holiday.rawDate = this.holiday.date.format('YYYY-MM-DD')
                }
            }
        },
        isPublic: {
            get: function() {
                return this.holiday.type?.toLowerCase() == 'public';
            },
            set: function(value) {
                if (value) {
                    this.holiday.type = 'public';
                } else {
                    this.holiday.type = 'school';
                    this.holiday.name = null;
                    this.holiday.special = false;
                }
            }
        },
        allStatesSelected() {
            return this.holiday.states.length == 16;
        },
        someStatesSelected() {
            return this.holiday.states.length > 0;
        },
        noStatesSelected() {
            return this.holiday.states.length == 0;
        },
        wasModified() {
            const holiday = this.holiday;
            const original = holiday.original;
            const modified = !original
                || holiday.name != original.name
                || holiday.type != original.type
                // cast to boolean so that false=undefined
                || Boolean(holiday.special) != Boolean(original.special)
                || !holiday.date.isSame(original.date)
                || holiday.states.length != original.states.length
                || holiday.states.some(s => !original.states.includes(s));
            this.$emit("modified", this, modified);
            return modified;
        }
    },
    methods: {
        toggleStatesSelection() {
            if (this.allStatesSelected) {
                this.holiday.states = [];
            }
            else {
                this.holiday.states = this.states.map(([short, ]) => short);
            }
        },
    },
};
</script>

<style scoped>
.modified {
    background-color: rgba(195, 0, 0, 0.2);
}
.modified:hover {
    background-color: rgba(195,155,155,1) !important;
}
</style>
