var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointment-filter-container" },
    [
      _c("h2", { staticStyle: { "text-align": "left" } }, [_vm._v("Termine")]),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            left: "",
            bottom: "",
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "min-width": "445px",
            "max-width": "65%",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "toolbar-title",
                        attrs: { color: "primary" },
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-filter-cog-outline")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.filterMenu,
            callback: function ($$v) {
              _vm.filterMenu = $$v
            },
            expression: "filterMenu",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "filter-menu" },
            [
              _c(
                "v-row",
                { staticStyle: { "align-items": "center" } },
                [
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("h4", [_vm._v("Sortieren")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.isResetDisabled, rounded: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("reset")
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { left: "", size: "20" },
                            },
                            [_vm._v("mdi-cancel")]
                          ),
                          _vm._v(
                            "\n                        Filter zurücksetzen\n                    "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.sortBy,
                      options: _vm.sortOptions,
                      placeholder: "Sortieren nach",
                      className: "pt-0 mt-0",
                    },
                    on: {
                      select: (value) => _vm.$emit("update:sortBy", value),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "v-application", attrs: { cols: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.sortBy,
                            fab: "",
                            text: "",
                            "max-width": "37",
                            "max-height": "37",
                            elevation: "3",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "update:sortAsc",
                                _vm.sortAsc * -1
                              )
                            },
                          },
                        },
                        [
                          _vm.sortAsc == 1
                            ? _c("v-icon", [_vm._v("mdi-arrow-up")])
                            : _c("v-icon", [_vm._v("mdi-arrow-down")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", [_vm._v("Terminfilter")]),
                  ]),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.service,
                      options: _vm.servicesTypes,
                      placeholder: "Apotheker / PTA / PKA",
                      className: "service-filter",
                    },
                    on: { select: (value) => _vm.onChange("service", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.commited,
                      options: _vm.committedStates,
                      placeholder: "zugesagte / nicht-zugesagte",
                      className: "committed-filter",
                    },
                    on: { select: (value) => _vm.onChange("commited", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.hidden,
                      options: _vm.isVisibleOptions,
                      placeholder: "sichtbar / nicht-sichtbar",
                      className: "visible-filter",
                    },
                    on: { select: (value) => _vm.onChange("hidden", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.proposed_pharmacists,
                      options: _vm.isProposedOptions,
                      placeholder: "wurde vorgeschlagen / nicht vorgeschlagen",
                      className: "proposal-filter",
                    },
                    on: {
                      select: (value) =>
                        _vm.onChange("proposed_pharmacists", value),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.no_approval_required,
                      options: _vm.isDirectPharmacyOptions,
                      placeholder: "direkt Apotheke / keine direkt Apotheke",
                      className: "direct-pharmacy-filter",
                    },
                    on: {
                      select: (value) =>
                        _vm.onChange("no_approval_required", value),
                    },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.nightshift,
                      options: _vm.isNightShiftOptions,
                      placeholder: "Nachtdienst / keine Nachtdienst",
                      className: "nightshift-filter",
                    },
                    on: {
                      select: (value) => _vm.onChange("nightshift", value),
                    },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      value: _vm.value.hba,
                      options: _vm.isHBAOptions,
                      placeholder: "HBA / kein HBA",
                      className: "hba-filter",
                    },
                    on: { select: (value) => _vm.onChange("hba", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      options: _vm.wwsOptions,
                      value: _vm.value.erp,
                      placeholder: "WWS",
                      className: "erp-filter",
                    },
                    on: { select: (value) => _vm.onChange("erp", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      options: _vm.isRequestedOptions,
                      value: _vm.value.pharmacist_requests,
                      placeholder: "von FB angefragt / nicht-angefragt",
                      className: "pharmacist-requests-filter",
                    },
                    on: {
                      select: (value) =>
                        _vm.onChange("pharmacist_requests", value),
                    },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      options: _vm.isSelfAssignableOptions,
                      value: _vm.value.can_self_assign,
                      placeholder: "Selbstzuweisung an / Selbstzuweisung aus",
                      className: "self-assign-filter",
                    },
                    on: {
                      select: (value) => _vm.onChange("can_self_assign", value),
                    },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      options: _vm.adminUsersOptions,
                      value: _vm.value.admin_id,
                      placeholder: "Admin zuweisen",
                      className: "self-assign-filter",
                    },
                    on: { select: (value) => _vm.onChange("admin_id", value) },
                  }),
                  _vm._v(" "),
                  _c("SelectBox", {
                    attrs: {
                      options: _vm.directProposalOptions,
                      value: _vm.value.allow_direct_proposal,
                      placeholder: "Direkter Vorschlag an / aus",
                      className: "direct-proposal-filter",
                    },
                    on: {
                      select: (value) =>
                        _vm.onChange("allow_direct_proposal", value),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "v-application--is-ltr" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "internalcomment-filter",
                        attrs: {
                          value: _vm.value.internal_comment,
                          label: "mit internem Kommentar",
                        },
                        on: {
                          change: (value) =>
                            _vm.onChange("internal_comment", value),
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.value,
                              "internal_comment",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "fpp-comment-filter",
                        attrs: {
                          value: _vm.value.comment,
                          label: "mit Kommentar für FPP",
                        },
                        on: {
                          change: (value) => _vm.onChange("comment", value),
                          "update:value": function ($event) {
                            return _vm.$set(_vm.value, "comment", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "freelancer-comment-filter",
                        attrs: {
                          value: _vm.value.comment_to_fb,
                          label: "mit Kommentar für FB",
                        },
                        on: {
                          change: (value) =>
                            _vm.onChange("comment_to_fb", value),
                          "update:value": function ($event) {
                            return _vm.$set(_vm.value, "comment_to_fb", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass:
                    "v-application v-application--is-ltr vuetify-wrapper",
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", [
                      _vm._v(
                        "Entfernungssuche (entweder über den FB oder über die PLZ)"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "compact", attrs: { cols: "4" } },
                    [
                      _c("PharmacistSearchDropdown", {
                        attrs: { placeholder: "Suche Freiberufler" },
                        model: {
                          value: _vm.selectedPharmacist,
                          callback: function ($$v) {
                            _vm.selectedPharmacist = $$v
                          },
                          expression: "selectedPharmacist",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "compact", attrs: { cols: "4" } },
                    [
                      _c("v-select", {
                        staticClass: "distance-filter",
                        attrs: {
                          value: _vm.value.radius,
                          items: _vm.distance,
                          placeholder: "Distanz",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "hide-details": "",
                        },
                        on: {
                          input: (value) => _vm.onChange("radius", value),
                          "update:value": function ($event) {
                            return _vm.$set(_vm.value, "radius", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.value.zip,
                          placeholder: "PLZ",
                          type: "number",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "hide-details": "",
                        },
                        on: {
                          input: _vm.onZipUpdate,
                          "update:value": function ($event) {
                            return _vm.$set(_vm.value, "zip", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }