var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "vuetify-wrapper overflow-visible",
      attrs: { id: "holidays" },
    },
    [
      _c("v-card", { attrs: { outlined: "" } }, [
        _vm._v(
          "\n        Hier können alle Ferien und Feiertage für das Jahr " +
            _vm._s(_vm.selectedYear) +
            " eingesehen und bearbeitet werden.\n        "
        ),
        _c("br"),
        _vm._v("\n        Spezielle Feiertage sind etwas teurer.\n        "),
        _c("div", [
          _vm._v(
            '\n            Erst durch "Alle Änderungen für ' +
              _vm._s(_vm.selectedYear) +
              ' speichern" werden die Änderungen ins System übernommen.\n        '
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bold red" }, [
          _vm._v(
            "\n            Durch das Laden von Vorschlägen werden alle vorherigen manuellen Änderungen für das Jahr verworfen (wenn gespeichert wird).\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          on: {
            click: function ($event) {
              _vm.selectedYear -= 1
            },
          },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-arrow-left")]),
          _vm._v("\n        " + _vm._s(_vm.selectedYear - 1) + "\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { primary: "", loading: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.loadHolidaySuggestions(_vm.selectedYear)
            },
          },
        },
        [
          _vm._v(
            "\n        Vorschläge für " +
              _vm._s(_vm.selectedYear) +
              " laden\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          on: {
            click: function ($event) {
              _vm.selectedYear += 1
            },
          },
        },
        [
          _vm._v("\n        " + _vm._s(_vm.selectedYear + 1) + "\n        "),
          _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-arrow-right")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: {
            disabled: !_vm.wasModified,
            loading: _vm.loading,
            primary: "",
            dense: "",
          },
          on: { click: _vm.saveCurrentYear },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("\n            mdi-content-save-all\n        "),
          ]),
          _vm._v(
            "\n        Alle Änderungen für " +
              _vm._s(_vm.selectedYear) +
              " speichern\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-holiday-dialog", {
        attrs: {
          loading: _vm.loading,
          states: _vm.states,
          year: _vm.selectedYear,
        },
        on: { save: _vm.onHolidayAdded },
      }),
      _vm._v(" "),
      _c("v-simple-table", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("th", [_vm._v("Datum")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Öffentlich")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Spezial")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Bundesländer")]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.selectedYearHolidays, function (holiday) {
                    return _c("holiday-row", {
                      key: holiday.date,
                      attrs: {
                        holiday: holiday,
                        states: _vm.states,
                        highlightModified: "",
                      },
                      on: { modified: _vm.onHolidayModified },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("tfoot", [
                  _c("tr", [
                    _c(
                      "th",
                      { attrs: { colspan: "99" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled: !_vm.wasModified,
                              loading: _vm.loading,
                              primary: "",
                              dense: "",
                            },
                            on: { click: _vm.saveCurrentYear },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(
                                "\n                                mdi-content-save-all\n                            "
                              ),
                            ]),
                            _vm._v(
                              "\n                            Alle Änderungen für " +
                                _vm._s(_vm.selectedYear) +
                                " speichern\n                        "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }