<template>
    <v-snackbar v-model="alert.active" top left :color="alert.type" multi-line>
        {{ alert.msg }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="clearAlert">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations({
            clearAlert: 'alert/clear',
        })
    },
    computed: {
        ...mapState({
            alert: (state) => state.alert,
        }),
    },
}
</script>
<style></style>