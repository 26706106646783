<template>
    <tr class="v-application vuetify-wrapper">
        <td class="date">
            <vc-date-picker
                v-model="date"
            >
                <template v-slot="{ togglePopover }">
                    <div class="flex items-center">
                        <v-btn
                            @click="togglePopover()"
                            :disabled="isNewAppointment"
                            class="date"
                        >
                            <v-icon left>
                                mdi-calendar
                            </v-icon>
                            {{ value.date }}
                        </v-btn>
                    </div>
                </template>
            </vc-date-picker>
        </td>
        <td class="time">
            <vc-date-picker
                v-model="beginTime"
                mode="time"
                is-required
                is24hr
                class="mb-3 begin"
            />
            <vc-date-picker
                v-model="endTime"
                mode="time"
                is-required
                is24hr
                class="end"
            />
        </td>
        <td>
            <v-textarea
                outlined
                v-model="value.time"
                rows="2"
                dense
                row-height="16"
                :rules="requiredRules"
            />
        </td>
        <td>
            <v-checkbox
                v-for="service in services"
                :value="service[0]"
                :label="service[1]"
                :data-service="service[0]"
                v-model="value.available_services"
                dense
                hide-details
            >
            </v-checkbox>
        </td>
        <td class="billing-type">
            <v-select
                :items="billingOptions"
                :value="value.billing"
                @change="changeBilling"
                dense
                flat
                outlined
            />
            <DynamicPriceSuggestion
                :date="dateMoment"
                :begin="beginMoment"
                :end="endMoment"
                :isNightshift="value.nightshift"
                :quantity="value.quantity"
                :pharmacyId="pharmacy?._id"
                :services="services"
                :selectedServices="value.available_services"
            />
        </td>
        <td class="quantity">
            <v-text-field
                v-model.number="value.quantity"
                type="number"
                v-if="value.billing === 'hours'"
                :rules="hoursRules"
                min="0"
                outlined
                dense
                hide-spin-buttons
            />
        </td>
        <td>
            <v-textarea
                v-model="value.comment"
                placeholder="Abweichung von bisherigen Arbeitszeiten? Sonderregelungen, Wünsche, Anregungen?"
                outlined
                value=""
                rows="2"
                row-height="16"
                dense
            />
            <v-textarea
                v-model="value.internal_comment"
                placeholder="Interner Kommentar"
                outlined
                value=""
                rows="2"
                row-height="16"
                dense
            />
            <v-textarea
                v-model="value.comment_to_fb"
                placeholder="Freiberufler Kommentar"
                outlined
                value=""
                rows="2"
                row-height="16"
                dense
            />
        </td>
        <td>
            <v-checkbox
                v-model="setHidden"
                dense
                hide-details
            />
        </td>
        <td>
            <v-checkbox
                v-model="setHBA"
                :disabled="!isOnlySubstitution"
                dense
                hide-details
            />
        </td>
        <td class="nd-checkbox">
            <v-checkbox :input-value="value.nightshift" @change="changeNightshift" dense hide-details/>
        </td>
        <td>
            <v-checkbox v-model="value.commited" dense hide-details/>
        </td>
        <v-btn
            v-if="!isNewAppointment && !value.nightshift"
            @click="addNightshiftAppointment"
            class="mx-2 clone-row-as-nightshift"
            color="primary"
            small
        >
            <v-icon dark size="19">
                mdi-plus
            </v-icon>
            ND
        </v-btn>
        <v-btn
            v-if="!isNewAppointment"
            @click="$emit('delete-row', key)"
            class="mx-2 delete-row"
            color="error"
            small
        >
            <v-icon dark size="19">
                mdi-delete
            </v-icon>
        </v-btn>

    </tr>
</template>

<script>
import moment from 'moment';
import DynamicPriceSuggestion from './DynamicPriceSuggestion.vue';

function timeStringToMoment(timeString) {
    return moment(`1900-01-01T${timeString}`);
}

function formatTimeString(hours, minutes) {
    function pad2(n) {
        return n.toString().padStart(2, '0');
    }
    return `${pad2(hours)}:${pad2(minutes)}`;
}

export default {
    name: 'BatchAppointmentRow',
    components: { DynamicPriceSuggestion },
    props: {
        value: Object, // appointment
        services: Array,
        pharmacy: Object,
    },
    data: () => {
        return {
            billingOptions: [{ text: 'Stunden', value: 'hours' } , { text: 'Pauschal', value: 'flatrate' }],
            requiredRules: [(v) => !!v || 'Dieses Feld ist ein Pflichtfeld'],
            hoursRules: [(v) => (!!v && v > 0) || 'Bitte die Stunden angeben'],
        }
    },
    watch: {
        isOnlySubstitution(isSub) {
            if (!isSub) this.setHBA = isSub
        }
    },
    computed: {
        key() {
            return this.$vnode.key;
        },
        /** True if the Appointment has been created in the UI,
         * false if it existed before and is now being edited. */
        isNewAppointment() {
            return !!this.value.created;
        },
        beginMoment() {
            return timeStringToMoment(this.value.begin);
        },
        endMoment() {
            return timeStringToMoment(this.value.end);
        },
        dateMoment() {
            return moment.utc(this.value.date);
        },
        date: {
            get() {
                return this.value.date;
            },
            set(value) {
                this.$emit('change-date', this.key, value);
            },
        },
        beginTime: {
            get() {
                return this.beginMoment.toISOString();
            },
            set(time) {
                const beginTime = moment(time).format('HH:mm');
                this.$emit('update-appointment', {
                    key: this.key,
                    property: 'begin',
                    value: beginTime
                });
            },
        },
        endTime: {
            get() {
                return this.endMoment.toISOString();
            },
            set(time) {
                const endTime = moment(time).format('HH:mm');
                this.$emit('update-appointment', {
                    key: this.key,
                    property: 'end',
                    value: endTime
                });
            },
        },
        setHBA: {
            get() {
                return this.value.hba;
            },
            set(value) {
                this.$emit('update-appointment', {
                    key: this.key,
                    property: 'hba',
                    value
                });
            }
        },
        setHidden: {
            get() {
                return this.value.hidden;
            },
            set(value) {
                this.$emit('change-hidden', this.key, Boolean(value));
            }
        },

        isOnlySubstitution() {
            return this.value.available_services.every(service => service === 'substitution');
        }
    },
    methods: {
        changeIsHidden(value) {
            this.$emit('change-hidden', this.key, Boolean(value));
        },
        changeNightshift(value) {
            this.$emit('change-nightshift', this.key, Boolean(value));
        },
        changeBilling(value) {
            this.$emit('change-billing', this.key, value);
        },
        addNightshiftAppointment() {
            this.$emit('add-nightshift-appointment', this.value.date);
        },
    },
};
</script>

<style scoped>
    .v-application {
        display: revert;
    }
    td {
        vertical-align: top;
        padding: 0 10px !important;
    }
    .col:not(:first-child){
        padding-inline-start: 0;
    }
    .vuetify-wrapper .v-textarea .v-text-field__details {
        /* display: none; */
        background-color: blueviolet;
    }
    .vuetify-wrapper .v-input--selection-controls.v-input--checkbox {
        display: inline-block;
        margin-left: 5px;
    }
    tr:hover {
        background-color: transparent !important;
    }
    td {
        border-bottom: none !important;
    }
    .v-btn:not(:first-child) {
        margin-top: 8px;
        margin-left: 4px;
    }
</style>

<style>
.time .vc-container .vc-time-picker .vc-date {
    display: none !important;
}
</style>
