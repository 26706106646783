<template>
    <v-row class="v-application vuetify-wrapper">
        <v-col v-if="isBatchAdd">
            <h3>neue Termine anlegen</h3>
            <p class="text-info">
                Bitte markieren Sie die gewünschten Tage im Kalender und klicken Sie auf "Markierte Termine übernehmen".
            </p>
        </v-col>
        <v-col v-if="isBatchAdd">
            <vc-date-picker
                :columns="$screens({ default: 1, lg: 4 })"
                :attributes="attributes"
                @dayclick="onDayClick"
            />
        </v-col>
        <v-col v-if="isBatchAdd">
            <v-btn @click="addAppointments" class="add-calendar-appointments">
                <v-icon dark>
                    mdi-plus
                </v-icon>
                Markierte Termine übernehmen
            </v-btn>
        </v-col>
        <v-col v-if="isBatchAdd">
            <p class="text-info">
                Achtung: Für zusätzliche Nachtdienste zu einem Tagesdienst legen Sie bitte zwei Termine an und markieren einen als Nachtdienst. Das Datum markiert immer den Anfang des Dienstes.
            </p>
        </v-col>
        <v-row>
            <v-col class="col-sm-12 col-md-5">
                <CopyFirstAppointmentDialog
                    :disabled="!onlyDayshifts || this.appointments.length < 2"
                    @copy-first-appointment-data="copyTime"
                    dialogBtnLabel="Zeiten vom ersten Termin auf alle anwenden"
                    dialogAlert="Startzeit, Endzeit, Zeitbeschreibung, Stunden und Abrechnungsart des ersten Termins auf alle anderen anwenden"
                />
            </v-col>
            <v-col class="col-sm-12 col-md-5">
                <CopyFirstAppointmentDialog
                     :disabled="disableCopyComments"
                    @copy-first-appointment-data="copyComment"
                    dialogBtnLabel="Kommentare des ersten Termins auf alle anwenden"
                    dialogAlert="Kommentare des ersten Termins auf alle anwenden"
                />
            </v-col>
        </v-row>
        <v-col class="vuetify-wrapper overflow-visible">
            <v-form ref="form" v-model="valid">
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <AppointmentHeaderRow
                                :services="services"
                                :allNightshiftTristate="allNightshiftTristate"
                                :allCommittedTristate="allCommittedTristate"
                                :allHBATristate="allHBATristate"
                                :allHidden="allHidden"
                                :shouldEnableHBACheckbox="shouldEnableHBACheckbox"
                                @toggle-services="toggleServices"
                                @toggle-nightshift="toggleNightshift"
                                @toggle-committed="toggleCommitted"
                                @toggle-hba="toggleHBA"
                                @toggle-hidden="toggleHidden"
                            />
                        </thead>

                        <tbody>
                            <AppointmentRow
                                v-for="(appointment, index) in appointmentsOrderedByDate"
                                :key="appointment._id"
                                v-model="appointmentsOrderedByDate[index]"
                                :services="services"
                                :pharmacy="pharmacy"
                                @delete-row="deleteRow"
                                @add-nightshift-appointment="addNightShiftAppointment"
                                @change-nightshift="onNightshiftChanged"
                                @change-billing="onBillingChanged"
                                @change-date="onDateChanged"
                                @change-hidden="onIsHiddenChanged"
                                @update-appointment="onAppointmentUpdated"
                            />
                            <AppointmentsClashesDialog
                                :clashes="clashes"
                                :appointments="appointmentsOrderedByDate"
                                @confirm="submitData"
                                @abort="clashes=[]"
                            />
                        </tbody>
                    </template>
                </v-simple-table>
            </v-form>
        </v-col>
        <v-col v-if="isBatchAdd">
            <v-btn
                @click="insertNewRow"
                class="add-row"
                color="primary"
                small
            >
                <v-icon dark size="19">
                    mdi-plus
                </v-icon>
                Termin
            </v-btn>
        </v-col>
        <v-col>
            <v-btn
                @click="onSaveButtonClicked"
                :disabled="!appointments.length || !valid"
                class="save-appointments"
                color="primary"
            >
                Speichern
            </v-btn>
            <v-btn :href="pharmacyURL">Abbrechen</v-btn>
        </v-col>
    </v-row>
</template>

<script>

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import AppointmentRow from './AppointmentRow.vue';
import AppointmentHeaderRow from './AppointmentHeaderRow.vue';
import CopyFirstAppointmentDialog from './CopyFirstAppointmentDialog.vue';
import AppointmentsClashesDialog from './AppointmentsClashesDialog.vue';
import { mapMutations } from 'vuex';


const TIME_FORMAT = 'HH:mm';

function momentToDateString(m) {
    return m.format('YYYY-MM-DD');
}

export default {
    name: 'CreateAppointments',
    components: { AppointmentRow, AppointmentHeaderRow, CopyFirstAppointmentDialog, AppointmentsClashesDialog },
    props: {
        batchadd: String,
        /** only applicable if in batch-edit mode */
        appointments_json: String,
        services_json: String,
        pharmacy_json: String
    },
    data: () => ({
        days: [],
        appointments: [],
        defaultAppointmentData: {
            'begin': '08:00',
            'end': '18:30',
            'time': '08.00 - 18.30 Uhr',
            'available_services': ['substitution'],
            'billing': 'hours',
            'quantity': 9,
            'comment': '',
            'internal_comment': '',
            'comment_to_fb': '',
            'nightshift': false,
            'commited': false,
            'hba': false,
            'hidden': false
        },
        firstAppointment: {},
        valid: true,
        headerSelectedServices: ['substitution'],
        clashes: [],
        allAppointmentsNightshift: false,
        allAppointmentsCommitted: false,
    }),
    mounted() {
        if (!this.isBatchAdd) {
            this.appointments = JSON.parse(this.appointments_json)
                .map(a => ({
                    ...a,
                    // replace iso-format-datetime strings with time-strings only
                    begin: moment.utc(a.begin).format(TIME_FORMAT),
                    end: moment.utc(a.end).format(TIME_FORMAT),
                })
            );
        }
    },
    computed: {
        allNightshiftTristate() {
            if (this.appointments.every(a => !a.nightshift)) {
                return false;
            }
            if (this.appointments.every(a => a.nightshift)) {
                return true;
            }
            return null;
        },
        allCommittedTristate() {
            if (this.appointments.every(a => !a.commited)) {
                return false;
            }
            if (this.appointments.every(a => a.commited)) {
                return true;
            }
            return null;
        },
        allHBATristate() {
            if (this.appointments.every(a => !a.hba)) {
                return false
            }
            if (this.appointments.every(a => a.hba)) {
                return true
            }
            return null
        },
        allHidden() {
            if (this.appointments.every(a => !a.hidden)) {
                return false
            }
            if (this.appointments.every(a => a.hidden)) {
                return true
            }
            return null
        },
        onlyDayshifts() {
            return !this.appointments.some(a => a.nightshift);
        },
        disableCopyComments() {
            return this.appointments.length < 2 || (!this.appointments[0].comment && !this.appointments[0].internal_comment && !this.appointments[0].comment_to_fb);
        },
        isBatchAdd() {
            return this.batchadd?.toLowerCase() === "true";
        },
        attributes() {
            return this.days.map(({ id, date }) => ({
                highlight: true,
                dates: id,
            }));
        },
        appointmentsOrderedByDate() {
            const ordered = _.orderBy(this.appointments, "date", "nightshift", "begin")
            return ordered;
        },
        /**
         * Appointments without `_id` property if this is `Batch-Add` mode
         * Changes nothing if it is `Batch-Edit` mode.
        */
        cleanedUpAppointments() {
            return this.appointments.map(appointment => {
                const properties = [
                    "date",
                    "begin",
                    "end",
                    "time",
                    "available_services",
                    "billing",
                    "quantity",
                    "nightshift",
                    "commited",
                    "hba",
                    "comment",
                    "internal_comment",
                    "comment_to_fb",
                    "hidden"
                ]
                if (this.isBatchAdd) {
                    // don't include _id and leave it up to the backend to generate one
                    return _.pick(appointment, properties);
                }
                return _.pick(appointment, ["_id", ...properties]);
            });
        },
        pharmacy() {
            return JSON.parse(this.pharmacy_json)
        },
        pharmacyURL() {
            return `/pharmacies/${this.pharmacy._id}`
        },
        services() {
            return JSON.parse(this.services_json)
        },
        shouldEnableHBACheckbox() {
            return this.appointments.every(({ available_services }) => {
                return available_services.every(service => service === 'substitution');
            })
        },
    },
    methods: {
        onDayClick(day) {
            const idx = this.days.findIndex(d => d.id === day.id);
            if (idx >= 0) {
                this.days.splice(idx, 1);
            } else {
                this.days = [
                    ...this.days,
                    {
                        id: day.id,
                        date: day.date
                    }
                ]
            }
        },
        addAppointments() {
            this.days.forEach(day => {
                const appointment = this.createAppointment(day.date);
                const dateAlreadyInUse = this.appointments.some(a => a.date == appointment.date);
                if (!dateAlreadyInUse) {
                    this.appointments.push(appointment);
                }
            })
        },
        insertNewRow() {
            const lastIndex = this.appointmentsOrderedByDate.length - 1;
            const lastDate = this.appointmentsOrderedByDate[lastIndex]?.date ?? moment();
            const nextDate = moment(lastDate).add({ days: 1});
            this.appointments.push(this.createAppointment(nextDate));
        },
        getWeekDay(date) {
            return moment(date).day() || 7; // Map Sunday from 0 to 7 so that pharmacy['begin7']/end7 etc can be accessed
        },
        getPharmacyWeekdayDefaults(selector, date) {
            const weekday = this.getWeekDay(date);
            return this.pharmacy[`${selector}${weekday}`];
        },
        deriveDateRelatedAppointmentData(date, nightshift=false) {
            const formattedDate = momentToDateString(moment(date));

            if (nightshift) {
                return {
                    date: formattedDate,
                    begin: '20:00',
                    end: '07:00',
                    quantity: 0,
                    time: 'ND 20.00 - 07.00 Uhr',
                    billing: 'flatrate',
                    nightshift: true,
                };
            }

            const pharmacyBegin = this.getPharmacyWeekdayDefaults('begin', date);
            const pharmacyEnd = this.getPharmacyWeekdayDefaults('end', date);
            const begin = pharmacyBegin && moment.utc(pharmacyBegin).format(TIME_FORMAT);
            const end = pharmacyEnd && moment.utc(pharmacyEnd).format(TIME_FORMAT);
            const quantity = this.getPharmacyWeekdayDefaults('hours', date) || 0;
            const workingHours = this.getPharmacyWeekdayDefaults('snippet', date) || '';
            const beginTime = begin || '08:00';
            const endTime = end || '18:30';

            return {
                date: formattedDate,
                begin: beginTime,
                end: endTime,
                quantity,
                time: workingHours,
                billing: 'hours',
                nightshift: false,
            };
        },
        createAppointment(date, nightshift=false) {
            return {
                ...{
                    ...this.defaultAppointmentData,
                    available_services: [...this.defaultAppointmentData.available_services],
                },
                '_id': uuidv4(),
                ...this.deriveDateRelatedAppointmentData(date, nightshift),
            };
        },
        addNightShiftAppointment(date) {
            this.appointments.push(this.createAppointment(date, true));
        },
        findAppointmentByKey(key) {
            const index = this.appointments.findIndex(({ _id }) => _id === key);
            return {
                index,
                appointment: this.appointments[index]
            };
        },
        deleteRow(key) {
            this.appointments = this.appointments.filter(({ _id }) => _id != key);
        },
        onNightshiftChanged(key, isNightshift) {
            const { appointment: oldAppointment, index } = this.findAppointmentByKey(key);
            this.$set(this.appointments, index, {
                ...oldAppointment,
                ...this.deriveDateRelatedAppointmentData(oldAppointment.date, isNightshift),
            });
        },
        onIsHiddenChanged(key, isHidden) {
            const { index } = this.findAppointmentByKey(key);
            this.$set(
                this.appointments,
                index,
                {
                    ...this.appointments[index],
                    hidden: isHidden,
                },
            );
        },
        onDateChanged(key, newDate) {
            const { appointment: oldAppointment, index } = this.findAppointmentByKey(key);
            this.$set(
                this.appointments,
                index,
                {
                    ...oldAppointment,
                    ...this.deriveDateRelatedAppointmentData(newDate, oldAppointment.nightshift),
                },
            );
        },
        onBillingChanged(key, value) {
            const { index } = this.findAppointmentByKey(key);
            this.$set(
                this.appointments,
                index,
                {
                    ...this.appointments[index],
                    billing: value,
                },
            );
        },
        onAppointmentUpdated({ key, property, value }) {
            const { index } = this.findAppointmentByKey(key);
            this.$set(
                this.appointments,
                index,
                {
                    ...this.appointments[index],
                    [property]: value,
                },
            );
        },
        /** Only applicable to batch-add, not to batch-edit */
        async getDateClashesWithExistingAppointments(appointments) {
            const dates = appointments.map(({ date }) => moment(date).format('DD.MM.YYYY'));
            const clashesResponse = await axios.post(`${this.pharmacyURL}/check_batch_dates`, { dates, ids: [] })
            if (clashesResponse.data.status == 'wait') {
                return clashesResponse.data.clashes;
            }
            return null;
        },
        async onSaveButtonClicked() {
            if (!this.$refs.form.validate()) {
                return;
            }
            if (this.isBatchAdd) {
                this.clashes = await this.getDateClashesWithExistingAppointments(this.cleanedUpAppointments)
                if (this.clashes) {
                    return;
                }
            }
            this.submitData();
        },
        async submitData() {
            if (!this.$refs.form.validate()) {
                return;
            }

            try {
                const action = this.isBatchAdd ? 'add_appointments_save' : 'edit_appointments_save'
                await axios.post(`${this.pharmacyURL}/${action}`, { appointments: this.cleanedUpAppointments });
                window.location.pathname = this.pharmacyURL;
            }
            catch(error) {
                this.alertError(`Termine konnten nicht gespeichert werden. Versuche es erneut oder kontaktiere einen Admin: ${error}`)
            }
        },
        copyTime() {
            // copy time from first appointment onto all others
            const [firstAppointment, ...otherAppointments] = this.appointmentsOrderedByDate;
            otherAppointments.forEach(a => {
                a.begin = firstAppointment.begin;
                a.end = firstAppointment.end;
                a.time = firstAppointment.time;
                a.quantity = firstAppointment.quantity;
                a.billing = firstAppointment.billing;
            })
        },
        copyComment() {
            // copy comment from first appointment onto all others
            const [firstAppointment, ...otherAppointments] = this.appointmentsOrderedByDate;
            otherAppointments.forEach(a => {
                a.comment = firstAppointment.comment;
                a.internal_comment = firstAppointment.internal_comment;
                a.comment_to_fb = firstAppointment.comment_to_fb;
            })
        },
        toggleServices(service) {
            const serviceTristate =
                this.appointments.every(a => a.available_services.includes(service))
                ? true
                : this.appointments.every(a => !a.available_services.includes(service))
                ? false
                : null;
            const shouldExist = !serviceTristate;
            if (shouldExist) {
                this.appointments
                    .filter(a => !a.available_services.includes(service))
                    .forEach(a => a.available_services.push(service));
            } else {
                this.appointments
                    .filter(a => a.available_services.includes(service))
                    .forEach(a => a.available_services = a.available_services.filter(s => s !== service));
            }
        },
        toggleNightshift(value) {
            const target = !this.allNightshiftTristate;
            this.appointments
                .filter(a => a.nightshift != target)
                .forEach(a => this.onNightshiftChanged(a._id, target));
        },
        toggleCommitted(value) {
            const target = !this.allCommittedTristate;
            this.appointments.forEach(a => a.commited = target);
        },
        toggleHBA(value) {
            const target = !this.allHBATristate;
            this.appointments.forEach(a => a.hba = target);
        },
        toggleHidden(value) {
            const target = !this.allHidden;
            this.appointments.forEach(a => a.hidden = target);
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    watch: {
    }
};
</script>

<style>
    .overflow-visible, /** To allow the vc-date-picker of holiday-row to be completely visible. Must not be scoped! */
    .overflow-visible .v-data-table__wrapper  /** To allow the state-multi-select dropdown to be visible. */
    {
        overflow: visible !important;
    }
</style>
<style scoped>
    .first-row-time {
        margin: 20px 5px;
    }
    p.text-info:last-child {
        margin-top: 20px;
    }
    .vuetify-wrapper .theme--light.v-data-table {
        background-color: transparent;
    }
    .vuetify-wrapper .save-appointments{
        margin-right: 10px;
    }
    .vuetify-wrapper .add-row {
        height: 32px !important;
        width: 88px;
        margin-top: 28px;
        float: right;
    }
</style>
