var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: {
        "vuetify-wrapper": true,
        modified: _vm.highlightModified && _vm.wasModified,
      },
    },
    [
      _c(
        "td",
        [
          _c("vc-date-picker", {
            staticClass: "inline-block h-full date-picker",
            attrs: {
              "min-date": _vm.selectableYear
                ? `${_vm.selectableYear}-01-01`
                : _vm.dateAsString,
              "max-date": _vm.selectableYear
                ? `${_vm.selectableYear}-12-31`
                : _vm.dateAsString,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ togglePopover }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function ($event) {
                                return togglePopover()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(
                                "\n                            mdi-calendar\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.dateAsString) +
                                "\n                    "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.dateAsString,
              callback: function ($$v) {
                _vm.dateAsString = $$v
              },
              expression: "dateAsString",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("v-checkbox", {
            staticClass: "select-public-holiday",
            attrs: { dense: "", disabled: !_vm.editableType },
            model: {
              value: _vm.isPublic,
              callback: function ($$v) {
                _vm.isPublic = $$v
              },
              expression: "isPublic",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _vm.isPublic
            ? _c("v-checkbox", {
                staticClass: "select-special-holiday",
                attrs: { dense: "" },
                model: {
                  value: _vm.holiday.special,
                  callback: function ($$v) {
                    _vm.$set(_vm.holiday, "special", $$v)
                  },
                  expression: "holiday.special",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        [
          _vm.isPublic
            ? _c("v-text-field", {
                attrs: {
                  placeholder: "Bezeichnung",
                  dense: "",
                  disabled: !_vm.editableName,
                },
                model: {
                  value: _vm.holiday.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.holiday, "name", $$v)
                  },
                  expression: "holiday.name",
                },
              })
            : _c("v-text-field", {
                attrs: { value: "Schulferien", disabled: "", dense: "" },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          class: [
            "state-select",
            _vm.dateAsString,
            _vm.editableType ? "create-mode" : "table-view",
          ],
        },
        [
          _c("v-select", {
            attrs: {
              items: _vm.stateAbbreviations,
              dense: "",
              attach: "",
              chips: "",
              "small-chips": "",
              multiple: "",
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-item",
                fn: function () {
                  return [
                    _c(
                      "v-list-item",
                      {
                        attrs: { ripple: "", dense: "" },
                        on: {
                          mousedown: function ($event) {
                            $event.preventDefault()
                          },
                          click: _vm.toggleStatesSelection,
                        },
                      },
                      [
                        _c(
                          "v-list-item-action",
                          [
                            _c("v-icon", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.allStatesSelected
                                      ? "mdi-close-box"
                                      : _vm.someStatesSelected
                                      ? "mdi-minus-box"
                                      : "mdi-checkbox-blank-outline"
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "select-all" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.allStatesSelected
                                        ? "Alle abwählen"
                                        : "Alle auswählen"
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-divider"),
                  ]
                },
                proxy: true,
              },
              {
                key: "selection",
                fn: function ({ item, index }) {
                  return [
                    _vm.allStatesSelected && index == 0
                      ? _c("span", [
                          _vm._v(
                            "\n                    Alle\n                "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.allStatesSelected
                      ? _c("v-chip", [_c("span", [_vm._v(_vm._s(item.text))])])
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.holiday.states,
              callback: function ($$v) {
                _vm.$set(_vm.holiday, "states", $$v)
              },
              expression: "holiday.states",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }