import utils from './utils';

// fade in/out grouping
$(document).on('click', '.grouped .toggle-group', function(e) {
    e.stopPropagation();
    utils.toggle_group($(this));
});

// toggle all grouping checkboxes
$(document).on('click', '.toggle-group-all', function() {
    if ($(this).hasClass('glyphicon-check')) {
        // check all
        // $(this).parents('.tab-pane').find('.checkbox[name=markgroup]').prop('checked', true)
        $(this)
            .parents('.tab-pane')
            .find('.checkbox[name=markgroup], .select-row')
            .click();
        $(this).removeClass('glyphicon-check');
        return $(this).addClass('glyphicon-unchecked');
    } else {
        // uncheck all
        // $(this).parents('.tab-pane').find('.checkbox[name=markgroup]').prop('checked', false)
        $(this)
            .parents('.tab-pane')
            .find('.checkbox[name=markgroup], .select-row')
            .click();
        $(this).addClass('glyphicon-check');
        return $(this).removeClass('glyphicon-unchecked');
    }
});

// toggle all invoice checkboxes
$(document).on('click', '.toggle-invoice-all', function() {
    if ($(this).hasClass('glyphicon-check')) {
        // check all
        $(this)
            .parents('.tab-pane')
            .find('.checkbox[name=markinvoice], .select-row')
            .click();
        $(this).removeClass('glyphicon-check');
        return $(this).addClass('glyphicon-unchecked');
    } else {
        // uncheck all
        $(this)
            .parents('.tab-pane')
            .find('.checkbox[name=markinvoice], .select-row')
            .click();
        $(this).addClass('glyphicon-check');
        return $(this).removeClass('glyphicon-unchecked');
    }
});
