var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "contractor_blacklist_modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "form",
            {
              staticClass: "form-horizontal validate",
              staticStyle: { margin: "0 !important" },
              attrs: {
                name: "blacklist-contractor-form",
                id: "blacklist_contractor_form",
                action: this.action,
                method: "POST",
                role: "form",
              },
              on: { submit: _vm.submit },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body clearfix" }, [
                _c("div", [
                  _vm.selected.length > 0
                    ? _c(
                        "ul",
                        { staticClass: "list-group" },
                        _vm._l(_vm.selectedContractors, function (c) {
                          return _c(
                            "li",
                            { key: c, staticClass: "list-group-item" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: `/pharmacists/${c._id}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(c.lastname) +
                                      ", " +
                                      _vm._s(c.firstname) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: {
                                    type: "button",
                                    "data-dismiss": "modal",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.remove(c._id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("Close"),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "control-label col-md-2" }, [
                      _vm._v("Benutzer*"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("input", {
                        attrs: { type: "hidden", name: "contractor_ids" },
                        domProps: { value: _vm.selectedJSON },
                      }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          ref: "select",
                          staticClass: "form-control",
                          attrs: { id: "blacklist_contractor_select" },
                          on: { input: _vm.onSelect },
                        },
                        [
                          _c("option", { attrs: { value: "" } }),
                          _vm._v(" "),
                          _vm._l(_vm.contractors, function (c) {
                            return _c(
                              "option",
                              { key: c._id, domProps: { value: c._id } },
                              [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(c.lastname) +
                                    ", " +
                                    _vm._s(c.firstname) +
                                    "\n                                    "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")]),
          _vm._v(" "),
          _c("span", { staticClass: "sr-only" }, [_vm._v("Close")]),
        ]
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title", attrs: { id: "myModalLabel" } }, [
        _vm._v("Freiberufler Negativ-Liste bearbeiten"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal-footer",
        staticStyle: { "margin-top": "0px !important" },
      },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary", attrs: { type: "submit" } },
          [_vm._v("Speichern")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            attrs: { type: "button", "data-dismiss": "modal" },
          },
          [_vm._v("Abbrechen")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }