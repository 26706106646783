/* ========================================================================
    * DOM-based Routing
    * Based on http://goo.gl/EUTi53 by Paul Irish
    *
    * Only fires on body classes that match. If a body class contains a dash,
    * replace the dash with an underscore when adding it to the object below.
    *
    * .noConflict()
    * The routing is enclosed within an anonymous function so that you can
    * always reference jQuery with $, even when in .noConflict() mode.
    * ======================================================================== */

import Dropzone from 'dropzone';


(function($) {


    Dropzone.autoDiscover = false;

    // Load Events
    $(document).ready(function() {

        if ($('#fp-upload').length) {

            // show the right block
            var loaded = $('#fp-upload').data("loaded");
            var filename = $('#fp-upload').data("filename");

            if (loaded) {
                $("#fp-upload .filename-span").text(filename);
                $("#fp-upload .dropzone-progress").hide();
                $("#fp-upload .dropzone-upload").hide();
                $("#fp-upload .dropzone-finished").show();
            }

            var uploadDropzone = new Dropzone("#fp-upload",
                {
                    url: "/upload/",
                    maxFiles: 1,
                    acceptedFiles: "application/pdf,image/png,image/jpeg",
                    previewsContainer: '#hidden',
                    clickable: "#fp-upload .dropzone-upload",
                    dictInvalidFileType: 'Dieser Dateityp ist nicht erlaubt. Bitte laden Sie nur folgende Dateitypen hoch: .pdf, .png, .jpeg, .jpg'
                }
            );

            uploadDropzone.on("error", function(file, errorMsg, xhr) {
                $("#fp-upload .dropzone-error-message").text(errorMsg);
                $("#fp-upload .dropzone-progress").hide();
                $("#fp-upload .dropzone-upload").hide();
                $("#fp-upload .dropzone-finished").hide();
                $("#fp-upload .dropzone-error").show();
                });


            uploadDropzone.on("totaluploadprogress", function(progress) {
                $("#fp-upload .progress-bar").css('width', progress + "%");
                $("#information-panel").removeClass("inactive");
                $("#address-panel").removeClass("inactive");
                });

            uploadDropzone.on("sending", function(file) {
                $("#fp-upload .dropzone-error").hide();
                $("#fp-upload .dropzone-progress").show();
                $("#fp-upload .dropzone-upload").hide();
                $("#fp-upload .dropzone-finished").hide();
                });

            uploadDropzone.on("success", function(file, response) {
                $("#fp-upload .filename-span").text(file.name);
                $("#fp-upload .dropzone-progress").hide();
                $("#fp-upload .dropzone-upload").hide();
                $("#fp-upload .dropzone-error").hide();
                if (response.status == "error") {
                    $("#fp-upload .dropzone-error-message").text(response.msg);
                    $("#fp-upload .dropzone-progress").hide();
                    $("#fp-upload .dropzone-error").show();
                    $("#information-panel").addClass("inactive");
                } else {
                    $("input[name=asset]").val(response.asset_id);
                    $("#fp-upload .dropzone-finished").show();
                    $("#fp-upload .download-button").attr('href', response.url);
                }
            });

            $("#fp-upload .new-upload-button").on('click', function(e) {
                e.preventDefault();
                uploadDropzone.removeAllFiles();
                $("#fp-upload .dropzone-progress").hide();
                $("#fp-upload .dropzone-upload").show();
                $("#fp-upload .dropzone-finished").hide();
                $("#fp-upload .dropzone-error").hide();
                $("#information-panel").addClass("inactive");
                $("input[name=asset]").val('');
            });
        }
        if ($('#fp-upload-vac').length) {

            // show the right block
            var loaded = $('#fp-upload-vac').data("loaded");
            var filename = $('#fp-upload-vac').data("filename");

            if (loaded) {
                $("#fp-upload-vac .filename-span").text(filename);
                $("#fp-upload-vac .dropzone-progress").hide();
                $("#fp-upload-vac .dropzone-upload").hide();
                $("#fp-upload-vac .dropzone-finished").show();
            }

            var uploadDropzone = new Dropzone("#fp-upload-vac",
                {
                    url: "/upload/",
                    maxFiles: 1,
                    acceptedFiles: "application/pdf,image/png,image/jpeg",
                    previewsContainer: '#hidden',
                    clickable: "#fp-upload-vac .dropzone-upload",
                    dictInvalidFileType: 'Dieser Dateityp ist nicht erlaubt. Bitte laden Sie nur folgende Dateitypen hoch: .pdf, .png, .jpeg, .jpg'
                }
            );

            uploadDropzone.on("error", function(file, errorMsg, xhr) {
                $("#fp-upload-vac .dropzone-error-message").text(errorMsg);
                $("#fp-upload-vac .dropzone-progress").hide();
                $("#fp-upload-vac .dropzone-upload").hide();
                $("#fp-upload-vac .dropzone-finished").hide();
                $("#fp-upload-vac .dropzone-error").show();
                });


            uploadDropzone.on("totaluploadprogress", function(progress) {
                $("#fp-upload-vac .progress-bar").css('width', progress + "%");
                $("#information-panel").removeClass("inactive");
                $("#address-panel").removeClass("inactive");
                });

            uploadDropzone.on("sending", function(file) {
                $("#fp-upload-vac .dropzone-error").hide();
                $("#fp-upload-vac .dropzone-progress").show();
                $("#fp-upload-vac .dropzone-upload").hide();
                $("#fp-upload-vac .dropzone-finished").hide();
                });

            uploadDropzone.on("success", function(file, response) {
                $("#fp-upload-vac .filename-span").text(file.name);
                $("#fp-upload-vac .dropzone-progress").hide();
                $("#fp-upload-vac .dropzone-upload").hide();
                $("#fp-upload-vac .dropzone-error").hide();
                if (response.status == "error") {
                    $("#fp-upload-vac .dropzone-error-message").text(response.msg);
                    $("#fp-upload-vac .dropzone-progress").hide();
                    $("#fp-upload-vac .dropzone-error").show();
                    $("#information-panel").addClass("inactive");
                    } else {
                        $("input[name=vaccination_asset]").val(response.asset_id);
                        $("#fp-upload-vac .dropzone-finished").show();
                        $("#fp-upload-vac .download-button").attr('href', response.url);
                    }
                });

            $("#fp-upload-vac .new-upload-button").on('click', function(e) {
                e.preventDefault();
                uploadDropzone.removeAllFiles();
                $("#fp-upload-vac .dropzone-progress").hide();
                $("#fp-upload-vac .dropzone-upload").show();
                $("#fp-upload-vac .dropzone-finished").hide();
                $("#fp-upload-vac .dropzone-error").hide();
                $("#information-panel").addClass("inactive");
                $("input[name=vaccination_asset]").val('');
            });
        }
    });

})(jQuery); // Fully reference jQuery after this point.

