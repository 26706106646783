var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _vm._v(
        "\n        Zahl der Nutzer, die sich durch die App eingeloggt haben.\n    "
      ),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table", attrs: { id: "appUsageStatistics" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.parsedStatistics, function (stat) {
          return _c("tr", { key: stat, attrs: { value: stat } }, [
            _c("td", { staticClass: "bold" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(stat.description) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                    " +
                  _vm._s(stat.contractors) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                    " +
                  _vm._s(stat.pharmacies) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                    " +
                  _vm._s(stat.pharmacies + stat.contractors) +
                  "\n                "
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n                    -\n                ")]),
        _vm._v(" "),
        _c("th", [
          _vm._v("\n                    Freiberufler\n                "),
        ]),
        _vm._v(" "),
        _c("th", [_vm._v("\n                    Apotheker\n                ")]),
        _vm._v(" "),
        _c("th", [_vm._v("\n                    Gesamt\n                ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }