'use strict';

import 'datatables.net';
import dt from 'datatables.net-bs';
// dt(window, $);

import 'datatables.net-bs/css/dataTables.bootstrap.css';

import Spinner from 'spin';

$.extend(true, $.fn.dataTable.defaults, {
    //dom: 'lrtip',
    columnDefs: [{ targets: 'no-sort', orderable: false }],
    dom:
        "<'row'<'col-sm-12 text-muted small'i>>" +
        "<'row'<'col-sm-3'l><'col-sm-3'f><'col-sm-6'p>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 text-muted small'i>>" +
        "<'row'<'col-sm-3'l><'col-sm-3'f><'col-sm-6'p>>",
    lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'Alle']
    ],
    language: {
        sEmptyTable: 'Keine Daten in der Tabelle vorhanden',
        sInfo: '_START_ bis _END_ von _TOTAL_ Einträgen',
        sInfoEmpty: '0 bis 0 von 0 Einträgen',
        sInfoFiltered: '(gefiltert von _MAX_ Einträgen)',
        sInfoPostFix: '',
        sInfoThousands: '.',
        sLengthMenu: '_MENU_ Einträge anzeigen',
        sLoadingRecords: '', // was 'Wird geladen...'
        sProcessing: 'Bitte warten...',
        sSearch: 'Filter',
        sZeroRecords: 'Keine Einträge vorhanden.',
        oPaginate: {
            sFirst: 'Erste',
            sPrevious: 'Zurück',
            sNext: 'Nächste',
            sLast: 'Letzte'
        },
        oAria: {
            sSortAscending: ': aktivieren, um Spalte aufsteigend zu sortieren',
            sSortDescending: ': aktivieren, um Spalte absteigend zu sortieren'
        },
        select: {
            rows: {
                _: '%d Zeilen ausgewählt',
                0: 'Zum Auswählen auf eine Zeile klicken',
                1: '1 Zeile ausgewählt'
            }
        }
    }
});

$(document).ready(function() {
    var spinner = new Spinner();
    var dTable = $('.datatable')
        .on('draw.dt', function(e) {
            // show spinner
            spinner.spin(
                $(e.currentTarget)
                    .find('.dataTables_empty')
                    .get(0)
            );
            $('.dt-hidden-while-drawing').show();
        })
        .on('init.dt', function() {
            // hide spinner
            spinner.stop();
        })
        .on('preXhr.dt', function(e, settings, data) {
            // update data sent to server
            $.extend(data, $(this).data('data'));
        })

    dTable.DataTable();

    // filter
    $('body').on('keyup', '#filter-search-dt', function() {
        dTable.search($(this).val()).draw();
    });

    // filter reset
    $('body').on('click', '#filtersearchdiv #inputreset', function() {
        $('#filter-search-dt')
            .val('')
            .trigger('keyup');
    });
});
