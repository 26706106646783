var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      staticClass: "checkbox select-row",
      attrs: { type: "checkbox" },
      on: { change: _vm.onChange },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }