<template>
    <div>
        <v-progress-circular
            v-if="isLoading"
            indeterminate
        />
        <div v-else-if="!applicablePricingSuggestions?.length">
            n/a
        </div>
        <v-tooltip
            v-else
            v-for="suggestion in applicablePricingSuggestions"
            right
        >
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    {{ suggestion.total.pharmacy }}€
                    {{ services.find(s => s[0] === suggestion.service)[1] }}
                </div>
            </template>
            <span>
                {{ suggestion.explanation }}
            </span>
        </v-tooltip>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import moment from 'moment';
import { mapMutations } from 'vuex';

export default {
    name: 'DynamicPriceSuggestion',
    props: {
        date: moment,
        begin: moment,
        end: moment,
        isNightshift: Boolean,
        quantity: Number,
        pharmacyId: String,
        services: Array,
        selectedServices: Array,
    },
    data: () => {
        return {
            pricingSuggestions: null
        }
    },
    watch: {
        date() { this.onInputChange() },
        begin() { this.onInputChange() },
        end() { this.onInputChange() },
        quantity() { this.onInputChange() },
        isNightshift() { this.onInputChange() },
        pharmacyId() { this.onInputChange() },
    },
    async mounted() {
        await this.loadPricingSuggestions();
    },
    created() {
        // create a separate debounced function per class-instance to avoid interference
        this.loadPricingSuggestions = debounce(this.loadPricingSuggestions, 1500);
    },
    computed: {
        isLoading() {
            return this.pricingSuggestions === null;
        },
        applicablePricingSuggestions() {
            if (!this.pricingSuggestions?.configs) {
                return [];
            }
            return this.pricingSuggestions.configs.filter(({ service }) => this.selectedServices.includes(service));
        },
    },
    methods: {
        onInputChange() {
            this.pricingSuggestions = null;
            this.loadPricingSuggestions();
        },
        async loadPricingSuggestions() {
            if (this.quantity === undefined || !this.date || !this.pharmacyId)  {
                return
            }
            try {
                const request = await fetch(`/api/pricing/${this.pharmacyId}/any`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        date: this.date.toISOString(),
                        begin: this.begin.toISOString(),
                        end: this.end.toISOString(),
                        quantity: this.quantity,
                        nightshift: this.isNightshift,
                    }),
                });
                this.pricingSuggestions = await request.json();
            } catch (error) {
                this.alertError()
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
};
</script>

<style scoped>
</style>
