var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      placeholder: _vm.placeholder,
      items: _vm.dropdownOptions,
      loading: _vm.isLoadingPharmacists,
      "no-filter": "",
      "no-data-text": "Keine Suchergebnisse",
      "return-object": "",
      "hide-details": "",
      outlined: "",
      dense: "",
      "auto-select-first": "",
    },
    on: { "update:search-input": _vm.searchActivePharmacists },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              {
                staticClass: "v-application",
                staticStyle: { display: "block" },
                attrs: {
                  "data-role": "pharmacist-option",
                  "data-pharmacist-id": item.pharmacist._id,
                },
              },
              [
                _c(
                  "p",
                  { staticClass: "font-weight-medium mt-2 no-margin" },
                  [
                    _vm.isBlacklisted(item.pharmacist._id)
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g({ attrs: { color: "red" } }, on),
                                        [_vm._v("mdi-account-off")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(
                              "\n                    Dieser Nutzer ist auf der Negativ-Liste der Apotheke.\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n                " +
                        _vm._s(item.pharmacist.firstname) +
                        " " +
                        _vm._s(item.pharmacist.lastname) +
                        "\n                "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          href: `/pharmacists/${item.pharmacist._id}`,
                          target: "_blank",
                        },
                      },
                      [_c("u", [_vm._v(_vm._s(item.pharmacist.customer_id))])]
                    ),
                    _vm._v(" "),
                    item.pharmacist.proposal_preference === "no_auto_proposals"
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g({ attrs: { color: "red" } }, on),
                                        [
                                          _vm._v(
                                            "\n                            mdi-alert-octagon\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(
                              "\n                    Der Freiberufler möchte keine Terminvorschläge erhalten!\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.pharmacist.proposal_preference ===
                    "available_dates_only"
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          { attrs: { color: "orange" } },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                            mdi-calendar-alert\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(
                              "\n                    Der Freiberufler möchte nur Terminvorschläge an verfügbaren erhalten!\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(item.pharmacist.services, function (service) {
                  return _c(
                    "v-chip",
                    {
                      staticClass: "mb-2 px-2",
                      attrs: {
                        color: _vm.getServiceColor(service),
                        dark: "",
                        "x-small": "",
                        label: "",
                      },
                    },
                    [
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            service == "substitution"
                              ? "Apotheker/in"
                              : service.toUpperCase()
                          )
                        ),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                item.pharmacist.has_hba
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "mb-2 px-2",
                        attrs: {
                          color: "purple",
                          tag: "b",
                          dark: "",
                          "x-small": "",
                          label: "",
                          outlined: "",
                        },
                      },
                      [_vm._v("\n                HBA\n            ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedPharmacist,
      callback: function ($$v) {
        _vm.selectedPharmacist = $$v
      },
      expression: "selectedPharmacist",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }