<template>
  <v-dialog
    v-model="isDialogOpen"
    content-class="v-application v-application--is-ltr"
    max-width="580px"
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-alert dense outlined color="error" icon="mdi-alert-circle"> Rabatt Löschen? </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn outlined color="secondary" @click="isDialogOpen = false">Abbrechen</v-btn>
        <v-btn depressed color="error" @click="submitForm" class="confirm-delete-btn">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isDeleteDiscountDialogOpen: Boolean
  },
  data: () => ({}),
  methods: {
    submitForm() {
        this.$emit('submit', this.value);
      },
  },
  computed: {
    isDialogOpen: {
        get() {
          return this.isDeleteDiscountDialogOpen;
        },
        set(state) {
          this.$emit("update:isDeleteDiscountDialogOpen", state);
        },
    },
  }
};
</script>

<style scoped></style>
