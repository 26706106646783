<template>
  <v-dialog v-model="isOpen" content-class="v-application v-application--is-ltr" max-width="580px" :fullscreen="!$vuetify.breakpoint.mdAndUp">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-alert dense outlined color="error" icon="mdi-alert-circle"> Rabatt Löschen? </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn outlined color="secondary" @click="isOpen = false">Abbrechen</v-btn>
        <v-btn depressed color="error" @click="unlinkDiscount" class="confirm-delete-btn">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: {
    value: Boolean,
    discount: Object,
    pharmacyId: String,
  },
  data: () => ({}),
  methods: {
    async unlinkDiscount() {
      try {
        const discountId = this?.discount?._id;
        const pharmacyId = this.pharmacyId;
        if (discountId && pharmacyId) {
          const response = await axios.get(`/api/discounts/${discountId}/unassign_discount_for/${pharmacyId}`);
          if (response.data.status >= 400) throw new Error()
          this.isOpen = false;
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
        this.alertError();
      }
    },
    ...mapMutations({
      alertError: "alert/error",
    }),
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(state) {
        this.$emit("input", state);
      },
    },
  },
};
</script>

<style scoped></style>
