var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
        : !_vm.applicablePricingSuggestions?.length
        ? _c("div", [_vm._v("\n        n/a\n    ")])
        : _vm._l(_vm.applicablePricingSuggestions, function (suggestion) {
            return _c(
              "v-tooltip",
              {
                attrs: { right: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c("div", _vm._g({}, on), [
                            _vm._v(
                              "\n                " +
                                _vm._s(suggestion.total.pharmacy) +
                                "€\n                " +
                                _vm._s(
                                  _vm.services.find(
                                    (s) => s[0] === suggestion.service
                                  )[1]
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(suggestion.explanation) +
                      "\n        "
                  ),
                ]),
              ]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }