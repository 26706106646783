var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contractorsWithMailSubscription" }, [
    _c(
      "table",
      { staticClass: "table", attrs: { id: "subscribedDaysStatistics" } },
      [
        _c("tbody", [
          _c(
            "tr",
            [
              _vm._l(_vm.subscribedDaysStatistics, function ([day]) {
                return _c("td", { key: day, attrs: { value: day } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(day) +
                      "\n                "
                  ),
                ])
              }),
              _vm._v(" "),
              _c("td", { staticClass: "bold" }, [
                _vm._v("\n                    Gesamt\n                "),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "tr",
            [
              _vm._l(_vm.subscribedDaysStatistics, function ([day, count]) {
                return _c("td", { key: day, attrs: { value: day } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(count) +
                      "\n                "
                  ),
                ])
              }),
              _vm._v(" "),
              _c("td", { staticClass: "bold" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.processedContractors.length) +
                    "\n                "
                ),
              ]),
            ],
            2
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass: "table table-striped table-hover",
        attrs: { id: "contractorsWithSubscription" },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.processedContractors, function (c) {
            return _c("tr", { key: c._id, attrs: { value: c._id } }, [
              _c("td", [
                _c("a", { attrs: { href: `/pharmacists/${c.id}` } }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(c.firstname) +
                      " " +
                      _vm._s(c.lastname) +
                      "\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                    " +
                    _vm._s(c.customerId) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                    " +
                    _vm._s(c.subscribedDays.join(", ")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  "\n                    " +
                    _vm._s(c.active ? "Aktiv" : "Inaktiv") +
                    "\n                "
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Angebot E-Mail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Aktiv")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }