var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { top: "", left: "", color: _vm.alert.type, "multi-line": "" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.clearAlert } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.alert.active,
        callback: function ($$v) {
          _vm.$set(_vm.alert, "active", $$v)
        },
        expression: "alert.active",
      },
    },
    [_vm._v("\n    " + _vm._s(_vm.alert.msg) + "\n    ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }