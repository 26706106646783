<template>
    <v-col cols="3" class="vuetify-wrapper">
        <v-select
            @change="value => $emit('select', value)"
            :items="options"
            :value.sync="value"
            :placeholder="placeholder"
            :class="className"
            dense
            outlined
            clearable
            hide-details
        ></v-select>

    </v-col>
</template>
<script>
export default {
    props: ['options', 'value', 'placeholder', 'className']
}
</script>
<style></style>