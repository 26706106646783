var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sum > 0
    ? _c("div", { attrs: { id: "stickyinfo" } }, [
        _c("h3", { staticClass: "popover-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "popover-content" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(_vm._s(_vm.formatted(_vm.total))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(_vm._s(_vm.formatted(_vm.travelcosts))),
            ]),
          ]),
          _vm._v(" "),
          _vm.mediation_charge > 0
            ? _c("div", { staticClass: "row" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _vm._v(_vm._s(_vm.formatted(_vm.mediation_charge))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.other_costs > 0
            ? _c("div", { staticClass: "row" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6" }, [
                  _vm._v(_vm._s(_vm.formatted(_vm.other_costs))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("hr", { staticClass: "mini" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 sum" }, [
              _c("strong", [_vm._v(_vm._s(_vm.formatted(_vm.sum)))]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("strong", { staticClass: "pull-right" }, [_vm._v("Betrag")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("strong", { staticClass: "pull-right" }, [_vm._v("Fahrtkosten")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("strong", { staticClass: "pull-right" }, [
        _vm._v("Vermittlung (Apotheke)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("strong", { staticClass: "pull-right" }, [
        _vm._v("Sonstiges (Freiberufler)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-6" }, [
      _c("strong", { staticClass: "pull-right" }, [_vm._v("Summe")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }