<template>
    <div class="vuetify-wrapper v-application">

        <appointments-filters
            v-model="filters"
            :sortBy.sync="sort.sortBy"
            :sortAsc.sync="sort.sortAsc"
            @reset="resetFilters"
        />

        <v-tabs v-model="tabNumber" v-if="!loading" grow slider-color="primary">
            <v-tab
                v-for="tab in tabs"
                :id="`${tab.value}-tab`"
                :key="tab.value"
            >
                {{ tab.text }}
                <v-chip small class="chip">{{ counts[`${tab.value}_total`] || 0 }}</v-chip>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabNumber">
            <v-tab-item
                v-for="tab in tabs"
                :key="tab.value"
            >
                <appointments-overview-table
                    :tab="tab.value"
                    :count="counts[tab.value] || 0"
                    :sort="sort"
                    :filters="filters"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import axios from 'axios';
    import AppointmentsOverviewTable from './AppointmentsOverviewTable.vue'
    import AppointmentFilters from './AppointmentFilters.vue';
    import { mapMutations } from 'vuex'

    export default {
        components: {
            'appointments-overview-table': AppointmentsOverviewTable,
            'appointments-filters': AppointmentFilters
        },
        props: {},
        data: () => ({
            tabNumber: 1,
            tabs: [
                {
                    text: 'angefragt',
                    value: 'inquired',
                },
                {
                    text: 'in Bearbeitung',
                    value: 'open',
                },
                {
                    text: 'vergeben',
                    value: 'allocated',
                },
                {
                    text: 'abgeleistet ohne Ausgangsrechnung',
                    value: 'completed_no_apo_invoice',
                },
                {
                    text: 'gelöscht',
                    value: 'deleted',
                },
            ],
            counts: {},
            filters: {
                service: null,
                commited: null,
                hidden: null,
                proposed_pharmacists: null,
                no_approval_required: null,
                nightshift: null,
                hba: null,
                erp: null,
                internal_comment: null,
                comment: null,
                comment_to_fb: null,
                pharmacist_id: null,
                radius: null,
                zip: null,
                pharmacist_requests: null,
                can_self_assign: null,
                admin_id: null,
                allow_direct_proposal: null
            },
            sort: {
                sortBy: '',
                sortAsc: 1
            }
        }),
        methods: {
            async fetchAppointmentsCounts() {
                try {
                    const response = await axios.get('/api/appointments/appointment_counts');
                    this.counts = response.data.counts;
                } catch (error) {
                    this.alertError()
                }
            },
            resetFilters() {
                for (const key in this.filters) {
                   this.$set(this.filters, key, null);
                }
                this.$set(this.sort, 'sortBy', null);
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        created() {
            this.fetchAppointmentsCounts()
        },
    }
</script>

<style scoped>
    .title {
        margin: 20px 0;
    }
    .chip{
        margin-left: 10px;
    }
    .vuetify-wrapper .v-window {
        overflow: visible;
    }
</style>
